import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Attribute, AttributeGroup } from "../cofounderMatching/CFMAttributes";
import { ADMIN_UNRATED_USERS_admin_unratedUsers_adminUserStub } from "./__generated__/ADMIN_UNRATED_USERS";
import { ADMIN_USER_admin_user_adminUserStub } from "./__generated__/ADMIN_USER";

const EnrichedData = styled.div`
  padding: 20px;
  background-color: #fff3e7;
  border-radius: 5px;

  h4 {
    font-size: 18px;
  }
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: gray;
  font-style: italic;
  font-weight: 300;
`;

export type EnrichedUserDataType =
  | ADMIN_UNRATED_USERS_admin_unratedUsers_adminUserStub
  | ADMIN_USER_admin_user_adminUserStub;

type Props = {
  enrichedUserData: EnrichedUserDataType | null | undefined;
  monoidRating: string | null;
};

export default ({ enrichedUserData, monoidRating }: Props) => {
  if (!enrichedUserData) {
    return null;
  }

  return (
    <EnrichedData>
      <AttributeGroup>
        <tr>
          <td colSpan={2} css={css({ display: "flex", paddingBottom: "0 !important" })}>
            <h4 css={css({ display: "flex", width: "100%" })}>
              <span>Enriched Data</span>
              <span css={css({ marginLeft: "auto" })}>
                auto rated {enrichedUserData.automatedRating ?? "nil"}
              </span>
              <span css={css({ marginLeft: "auto" })}>monoid rated {monoidRating ?? "nil"}</span>
            </h4>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <Subtitle>pulled from LinkedIn, not provided by the user</Subtitle>
          </td>
        </tr>
        <Attribute
          title="Education"
          block
          value={
            <ul>
              {enrichedUserData.enrichedEducation &&
                enrichedUserData.enrichedEducation
                  .split("\n")
                  .map((e) => e.length > 0 && <li key={e}>{e}</li>)}
            </ul>
          }
        />
        <Attribute
          title="Employment"
          block
          value={
            <ul>
              {enrichedUserData.enrichedEmployment &&
                enrichedUserData.enrichedEmployment
                  .split("\n")
                  .map((e) => e.length > 0 && <li key={e}>{e}</li>)}
            </ul>
          }
        />
      </AttributeGroup>
    </EnrichedData>
  );
};
