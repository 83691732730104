import styled from "@emotion/styled";
import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { mobileStyleCss } from "../../styles";

const buttonStyle = `
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background-color: none;
  background: none;
  color: gray;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

const BackButton = styled.button`
  ${buttonStyle}
`;

const MobileOnlyBackButton = styled.button`
  display: none;
  ${mobileStyleCss(buttonStyle)}
`;

type Props = {
  onClick: (e: React.MouseEvent) => void;
  mobileOnly?: boolean;
};

export default ({ onClick, mobileOnly }: Props) => {
  const ButtonComponent = mobileOnly ? MobileOnlyBackButton : BackButton;

  return (
    <ButtonComponent onClick={onClick}>
      <ChevronLeft />
      <div>Back</div>
    </ButtonComponent>
  );
};
