import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { omit } from "lodash";
import Field, { CustomFieldProps } from "./Field";
import { Input as BaseInput, InputProps } from "../statelessForms/Input";
import { splitProps } from "./util";
import WithCharCount from "../statelessForms/WithCharCount";

type CustomProps = {
  showCharCount?: boolean;
};

const Input = (props: CustomFieldProps<InputProps & CustomProps>) => {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);
  const inputProps = omit(otherProps, "showCharCount");

  return (
    <Field
      {...fieldProps}
      input={
        <Controller
          name={props.fieldName}
          control={control}
          render={({ field: formProps }) =>
            props.showCharCount ? (
              <WithCharCount
                min={fieldProps.minLength}
                max={fieldProps.maxLength}
                currentLength={formProps.value?.length || 0}
              >
                <BaseInput
                  {...inputProps}
                  {...formProps}
                  ref={null}
                  innerRef={null}
                  size="small"
                  outlined
                />
              </WithCharCount>
            ) : (
              <BaseInput
                {...inputProps}
                {...formProps}
                ref={null}
                innerRef={null}
                size="small"
                outlined
              />
            )
          }
        />
      }
    />
  );
};

export default Input;
