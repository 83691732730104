import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Textarea } from "./index";
import { EDIT_USER_PAGE_currentUser } from "../../pages/myProfile/__generated__/EDIT_USER_PAGE";
import { CFM_EDIT_PROFILE_currentUser } from "../../pages/cofounderMatching/__generated__/CFM_EDIT_PROFILE";

type Props = {
  fieldPrefix?: string;
  required: boolean;
  currentUser: EDIT_USER_PAGE_currentUser | CFM_EDIT_PROFILE_currentUser | null | undefined;
  enrichedFields?: { education: string | null; employment: string | null };
};

export default ({ fieldPrefix = "", required, currentUser, enrichedFields }: Props) => {
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const { watch, setValue } = useFormContext();

  const educationFormField = watch(`${fieldPrefix}education`);
  useEffect(() => {
    if (educationFormField !== undefined) {
      setFormLoaded(true);
    }
  }, [educationFormField]);

  useEffect(() => {
    if (initialDataLoaded || !formLoaded || !currentUser) {
      return;
    }

    const { education, employment, enrichedUserData } = currentUser;
    if (!education && (enrichedFields?.education || enrichedUserData.education)) {
      setValue(`${fieldPrefix}education`, enrichedFields?.education || enrichedUserData.education);
    }
    if (!employment && (enrichedFields?.employment || enrichedUserData.employment)) {
      setValue(
        `${fieldPrefix}employment`,
        enrichedFields?.employment || enrichedUserData.employment
      );
    }

    setInitialDataLoaded(true);
  }, [currentUser, enrichedFields, formLoaded]);

  return (
    <>
      <Textarea
        fieldName={`${fieldPrefix}education`}
        label="Education: schools, degrees (including field of study), and years of graduation.
                  Use a separate line for each school."
        required={required}
        minRows={4}
        placeholder={"Stanford, MS in Computer Science, 2017\nNYU, BS in Physics, 2015"}
        anchor="education"
        maxLength={800}
        showCharCount
      />
      <Textarea
        fieldName={`${fieldPrefix}employment`}
        label="Employment: employers, position / titles, and dates. Use a separate line for each
                  job, most recent first."
        required={required}
        minRows={4}
        placeholder={
          "Stripe, Senior Software Engineer, 2019-present\nGoogle, Software Engineer, 2016-2019"
        }
        anchor="employment"
        maxLength={800}
        showCharCount
      />
    </>
  );
};
