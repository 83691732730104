import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { gql, useMutation, useQuery } from "@apollo/client";
import useRealtime from "../../hooks/useRealtime";
import { ACTIVE_IN_SPEED_DATING } from "./__generated__/ACTIVE_IN_SPEED_DATING";
import LoadingDots from "../../components/statelessForms/LoadingDots";
import {
  SPEED_DATING_MEETING,
  SPEED_DATING_MEETING_speedDatingEvent_currentMeeting,
} from "./__generated__/SPEED_DATING_MEETING";
import SpeedDatingMeeting from "./SpeedDatingMeeting";
import NotificationSound from "../../../../assets/audio/notification-sound.wav";
import {
  CFMProfileFragment,
  CFMViewerProfileFragment,
  BasicUserFragment,
} from "../cofounderMatching/fragments";
import {
  UPDATE_READY_FOR_MATCH_EVENT,
  UPDATE_READY_FOR_MATCH_EVENTVariables,
} from "./__generated__/UPDATE_READY_FOR_MATCH_EVENT";
import { profileCofounderMatchingPath } from "../../__generated__/routes";
import { LIVE_EVENT_PROFILE } from "./__generated__/LIVE_EVENT_PROFILE";
import useRealtimeData from "../../hooks/useRealtimeData";
import { SPEED_DATING_ELIGIBLE_PROFILES } from "./__generated__/SPEED_DATING_ELIGIBLE_PROFILES";
import { YC_ORANGE } from "../../components/forms/util";

type Props = {
  attendeeKey: string;
  dailyUserToken: string;
  eventSlug: string;
  clockOffset: number;
  setAttendance: React.Dispatch<React.SetStateAction<number[]>>;
  setAnnouncement?: React.Dispatch<React.SetStateAction<string | undefined | null>>;
  setInMeeting: React.Dispatch<React.SetStateAction<boolean>>;
};

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SearchingForMatchContainer = styled(StatusContainer)`
  span {
    color: ${YC_ORANGE};
    font-size: 20px;
    font-weight: bold;
    margin: 15px 0 40px 0;
  }
`;

const NoEligibleCandidatesContainer = styled(StatusContainer)`
  span {
    font-weight: bold;
    margin: 15px 0;
    font-size: 18px;
  }
`;

const LoosenFiltersSuggestion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  i {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
  }
`;

const LiveEvent = ({
  attendeeKey,
  dailyUserToken,
  eventSlug,
  clockOffset,
  setAttendance,
  setAnnouncement,
  setInMeeting,
}: Props) => {
  if (!attendeeKey) {
    return null;
  }

  const [inputSettings, setInputSettings] = useState<any | undefined>();

  const [currentMeeting, setCurrentMeeting] = useState<
    SPEED_DATING_MEETING_speedDatingEvent_currentMeeting | null | undefined
  >();

  const [showFindingMatch, setShowFindingMatch] = useState(true);

  const [updateReadyForMatch] = useMutation<
    UPDATE_READY_FOR_MATCH_EVENT,
    UPDATE_READY_FOR_MATCH_EVENTVariables
  >(gql`
    mutation UPDATE_READY_FOR_MATCH_EVENT($slug: ID!, $isReadyForMatch: Boolean!) {
      updateSpeedDatingReadyForMatch(slug: $slug, isReadyForMatch: $isReadyForMatch) {
        slug
      }
    }
  `);

  useQuery<ACTIVE_IN_SPEED_DATING>(
    gql`
      query ACTIVE_IN_SPEED_DATING($slug: ID!, $inMeeting: Boolean!) {
        pingActiveInSpeedDatingEvent(slug: $slug, inMeeting: $inMeeting)
      }
    `,
    {
      pollInterval: 10_000,
      variables: {
        slug: attendeeKey,
        inMeeting: !!currentMeeting,
      },
    }
  );

  const { data: profileData } = useQuery<LIVE_EVENT_PROFILE>(
    gql`
      query LIVE_EVENT_PROFILE {
        cofounderMatching {
          profile {
            ...CFMViewerProfileFragment
          }
        }
      }
      ${CFMViewerProfileFragment}
    `
  );

  const [meetingData, setMeetingData] = useState<SPEED_DATING_MEETING | undefined>();

  const { data: maybeMeetingData, refetch } = useQuery<SPEED_DATING_MEETING>(
    gql`
      query SPEED_DATING_MEETING($slug: ID!) {
        speedDatingEvent(slug: $slug) {
          isReadyForMatch
          eventType
          currentMeeting {
            startsAt
            endsAt
            otherUserJoined
            tookPlace
            dailyCoRoom
            candidate {
              ...CFMProfileFragment
              request {
                slug
                status
                outbound
                message
              }
            }
            otherUser {
              ...BasicUserFragment
              firstName
            }
          }
          lastMeetingEndedBySlug
        }
      }
      ${CFMProfileFragment}
      ${BasicUserFragment}
    `,
    {
      variables: { slug: eventSlug },
    }
  );

  const { data: eligibleProfileData } = useQuery<SPEED_DATING_ELIGIBLE_PROFILES>(
    gql`
      query SPEED_DATING_ELIGIBLE_PROFILES($slug: ID!) {
        speedDatingEvent(slug: $slug) {
          eligibleProfilesPresent
        }
      }
    `,
    {
      variables: { slug: eventSlug },
      pollInterval: 60_000,
    }
  );

  const reloadEvent = async () =>
    refetch({
      variables: {
        slug: eventSlug,
      },
    });

  const setReadyForMatch = (isReadyForMatch: boolean) => {
    updateReadyForMatch({
      variables: {
        slug: eventSlug,
        isReadyForMatch,
      },
    });
  };

  // Pageload initialization
  useEffect(() => {
    // On close, set isReadyForMatch to false
    window.addEventListener("beforeunload", () => {
      setReadyForMatch(false);
    });

    // On pageload, if you're not currently in a meeting, set isReadyToMatch to true
    const setReadyIfNoCurrentMeeting = async () => {
      const { data } = await reloadEvent();
      if (!data?.speedDatingEvent.currentMeeting) {
        setReadyForMatch(true);
        setTimeout(() => setShowFindingMatch(false), 10000);
      }
    };
    setReadyIfNoCurrentMeeting();
  }, []);

  // On a new currentMeeting value:
  useEffect(() => {
    // If you have a current meeting, store it in state and play a notification sound
    const newMeetingData = meetingData?.speedDatingEvent.currentMeeting;
    if (newMeetingData) {
      setCurrentMeeting(newMeetingData);
      setInMeeting(true);
      if (!currentMeeting) {
        new Audio(NotificationSound).play();
      }
    }
  }, [meetingData?.speedDatingEvent.currentMeeting]);

  // On a new isReadyForMatch value:
  useEffect(() => {
    // If you're ready for matching and you have a currentMeeting stored in state, clear it out
    if (currentMeeting && meetingData?.speedDatingEvent.isReadyForMatch) {
      setCurrentMeeting(null);
      setInMeeting(false);
    }
  }, [meetingData?.speedDatingEvent.isReadyForMatch]);

  useEffect(() => {
    if (maybeMeetingData) {
      setMeetingData(maybeMeetingData);
    }
  }, [maybeMeetingData]);

  // Watch for prompts to reload from the backend over websocket through Firebase
  const latestMeetingPushedDatetime = useRealtimeData<string | null>(
    `speed_dating/${attendeeKey}`,
    null,
    async () => {
      const meetingActive = !!currentMeeting;
      const { data } = await reloadEvent();
      const meetingFound = !!data?.speedDatingEvent.currentMeeting;
      return meetingActive !== meetingFound;
    }
  );

  // Watch for updates to the number of participants in the event
  const attendance: string | null = useRealtime<string | null>(`speed_dating/${eventSlug}`, null);
  const announcement: string | null = useRealtime<string | null>(
    `speed_dating_announcement/${eventSlug}`,
    null
  );

  useEffect(() => {
    if (attendance && attendance[0] === "[") {
      setAttendance(JSON.parse(attendance));
    }
  }, [attendance]);

  useEffect(() => {
    if (setAnnouncement) {
      setAnnouncement(announcement);
    }
  }, [announcement]);

  useEffect(() => {
    reloadEvent();
  }, [latestMeetingPushedDatetime]);

  const nobodyToMeet = eligibleProfileData?.speedDatingEvent.eligibleProfilesPresent === "none";
  const fewToMeet = eligibleProfileData?.speedDatingEvent.eligibleProfilesPresent === "few";

  const endedByOtherUser =
    !!maybeMeetingData?.speedDatingEvent.lastMeetingEndedBySlug &&
    maybeMeetingData.speedDatingEvent.lastMeetingEndedBySlug == currentMeeting?.otherUser.slug;

  const hardFilterMessage = meetingData?.speedDatingEvent.eventType === "cfm" && (
    <LoosenFiltersSuggestion>
      <i>You will only meet candidates who meet all your hard filters.</i>
      <i>
        Taking too long to find a meeting? Consider{" "}
        <a href={profileCofounderMatchingPath({ page: 3 })}>loosening your filters.</a>
      </i>
    </LoosenFiltersSuggestion>
  );

  const lookingForMatch = (
    <SearchingForMatchContainer>
      <LoadingDots />
      <span>Finding a meeting...</span>
    </SearchingForMatchContainer>
  );

  const noEligibleCandidates = (
    <NoEligibleCandidatesContainer>
      <LoadingDots interval={5} />
      <span>There are no founders here right now who you're eligible to meet.</span>
      <p>
        People come and go throughout the event! If you keep this page open and an eligible
        candidate joins, we'll play a notification sound.
      </p>
      {hardFilterMessage}
    </NoEligibleCandidatesContainer>
  );

  const fewEligibleCandidates = (
    <NoEligibleCandidatesContainer>
      <LoadingDots interval={5} />
      <span>There are only a few founders here right now who you're eligible to meet.</span>
      <p>
        People come and go throughout the event! If you keep this page open and an eligible
        candidate joins, we'll play a notification sound.
      </p>
      <p>
        In the meantime, feel free to grab a coffee or use other tabs. Just make sure to keep your
        volume up!
      </p>
      {hardFilterMessage}
    </NoEligibleCandidatesContainer>
  );

  const eligibleCandidatesInMeetings = (
    <NoEligibleCandidatesContainer>
      <LoadingDots interval={5} />
      <span>All eligible candidates for you are in other meetings.</span>
      <p>
        Keep this page open, and we'll play a notification sound when someone becomes available.
      </p>
      {hardFilterMessage}
    </NoEligibleCandidatesContainer>
  );

  const onReturnToLobby = () => {
    setCurrentMeeting(null);
    setInMeeting(false);
    setReadyForMatch(true);
    setShowFindingMatch(true);
    setTimeout(() => setShowFindingMatch(false), 10000);
    reloadEvent();
  };

  const pageStatus = () => {
    if (nobodyToMeet) {
      return noEligibleCandidates;
    }

    if (fewToMeet) {
      return fewEligibleCandidates;
    }

    if (showFindingMatch) {
      return lookingForMatch;
    }

    return eligibleCandidatesInMeetings;
  };

  return (
    <div>
      {currentMeeting && meetingData ? (
        <SpeedDatingMeeting
          eventType={meetingData?.speedDatingEvent.eventType}
          clockOffset={clockOffset}
          meeting={currentMeeting}
          meetingHasEnded={currentMeeting && !meetingData?.speedDatingEvent.currentMeeting}
          endedByOtherUser={endedByOtherUser}
          viewer={profileData?.cofounderMatching.profile || null}
          dailyUserToken={dailyUserToken}
          eventSlug={eventSlug}
          checkIfMeetingEnded={reloadEvent}
          returnToLobby={onReturnToLobby}
          inputSettings={inputSettings}
          setInputSettings={setInputSettings}
        />
      ) : (
        pageStatus()
      )}
    </div>
  );
};

export default LiveEvent;
