import React from "react";
import styled from "@emotion/styled";
import { formatDistance } from "date-fns";
import { css } from "@emotion/core";
import ScrollableAnchor from "react-scrollable-anchor";
import { bySlugAdminUsersPath } from "../../__generated__/routes";
import { COFOUNDER_ADMIN_PROFILE_admin_cofounderMatching_adminProfile_recentMessagesSent } from "./__generated__/COFOUNDER_ADMIN_PROFILE";

type Props = {
  messages: COFOUNDER_ADMIN_PROFILE_admin_cofounderMatching_adminProfile_recentMessagesSent[];
};

const MessagesContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #eeeee5;
  max-height: 400px;
  overflow: scroll;
  margin-top: 20px;

  h4 {
    font-size: 18px;
  }

  ul {
    font-size: 14px;
    padding-left: 15px;
  }

  p {
    margin-bottom: 0;
  }
`;

export default ({ messages }: Props) => (
  <ScrollableAnchor id="messages-list">
    <MessagesContainer>
      <h4>
        Most recent {messages.length} messages sent (
        {messages.filter((i) => i.status === "accepted").length} to matches)
      </h4>
      <ul>
        {messages.map((message) => (
          <li key={message.createdAt}>
            <p>
              <a href={bySlugAdminUsersPath(message.adminReceiver.slug)}>
                {message.adminReceiver.user.name} ({message.adminReceiver.adminRating}) -{" "}
                <b>{message.status}</b>
              </a>
              <span css={css({ color: "gray" })}>
                <i>
                  {" "}
                  {formatDistance(new Date(message.createdAt), new Date(), { addSuffix: true })}
                </i>
              </span>
            </p>
            <p>{message.message}</p>
            <br />
          </li>
        ))}
      </ul>
    </MessagesContainer>
  </ScrollableAnchor>
);
