import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Pane } from "../../../components/Pane";
import {
  CONVERSATIONS_TYPE_CONVERSATION_PREVIEW,
  CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews,
} from "./__generated__/CONVERSATIONS_TYPE_CONVERSATION_PREVIEW";
import ConversationPreview, { isPreviewUnread } from "./ConversationPreview";
import ConversationBox from "./ConversationBox";
import { ProfilePreviewFields } from "../ProfilePreview";
import { COFOUNDER_MATCHING_VIEWER_cofounderMatching_profile } from "./__generated__/COFOUNDER_MATCHING_VIEWER";
import { MarkMatchMeetFields } from "../CFMMarkMatchMeet";
import { inboxCofounderMatchingPath } from "../../../__generated__/routes";
import useRealtime from "../../../hooks/useRealtime";
import { mobileStyleCss, mobileStyles } from "../../../styles";
import LoadingDots from "../../../components/statelessForms/LoadingDots";

const TabList = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  margin: "0 35px 15px 35px",
  paddingTop: 20,
  borderBottom: "1px solid #dddddd",
  ...mobileStyles({
    fontSize: 14,
    paddingTop: 8,
    marginBottom: 8,
  }),
});

type TabProps = {
  isSelected: boolean;
  disabled: boolean;
};

const Tab = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 24px;
  background-color: #fafafa;
  margin: 0 3px;
  border-radius: 4px;
  margin-bottom: -1px;
  border-left: 1px solid ${(props: TabProps) => (props.isSelected ? "#dddddd" : "transparent")};
  border-top: 1px solid ${(props: TabProps) => (props.isSelected ? "#dddddd" : "transparent")};
  border-right: 1px solid ${(props: TabProps) => (props.isSelected ? "#dddddd" : "transparent")};
  border-bottom: 1px solid ${(props: TabProps) => (props.isSelected ? "transparent" : "#dddddd")};
  color: ${(props: TabProps) => (props.isSelected ? "black" : "#8b8b8b")};
  &:first-of-type {
    margin-left: 0;
  }

  &:hover:enabled {
    cursor: pointer;
    color: #303030;
  }

  span {
    padding: 3px 8px;
    background-color: ${(props: TabProps) => (props.disabled ? "lightgray" : "gray")};
    color: white;
    border-radius: 10px;
    margin-left: 10px;
    font-size: 12px;
  }

  ${mobileStyleCss(`
    padding: 6px 10px;
    flex-direction: column-reverse;

    span {
      margin-left: 0;
      margin-bottom: 3px;
    }
  `)}
`;

const UnreadIndicator = styled.div`
  position: absolute;
  left: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f06827;
`;

type TAB_TYPE = "active" | "invitations" | "archived";

const TAB_PRIORITY: TAB_TYPE[] = ["active", "invitations", "archived"];

const QUERY_PARAM = "conversation";
const CATEGORY_PARAM = "active";

export const getConversationUrl = (slug: string | null) =>
  `${inboxCofounderMatchingPath()}?${QUERY_PARAM}=${slug}`;

type Props = {
  currentProfile: COFOUNDER_MATCHING_VIEWER_cofounderMatching_profile | null;
  firebaseRealtimeKey: string;
};

export default ({ currentProfile, firebaseRealtimeKey }: Props) => {
  const [currentPreview, setCurrentPreview] =
    useState<CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews>();

  const [isCurrentPreviewActive, setIsCurrentPreviewActive] = useState(false);

  const [currentTab, setCurrentTab] = useState<TAB_TYPE>(
    (new URLSearchParams(window.location.search).get(CATEGORY_PARAM) || "active") as TAB_TYPE
  );

  const [selectedSlugFromPath, setSelectedSlugFromPath] = useState<string | null>(
    new URLSearchParams(window.location.search).get(QUERY_PARAM)
  );

  const [allPreviews, setAllPreviews] = useState<
    CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews[]
  >([]);
  const { data, refetch } = useQuery<CONVERSATIONS_TYPE_CONVERSATION_PREVIEW>(
    gql`
      query CONVERSATIONS_TYPE_CONVERSATION_PREVIEW {
        conversations {
          conversationPreviews {
            slug
            latestMessage {
              createdAt
              message
              outgoing
            }
            lastRead
            earliestMessageSentAt
            profile {
              intro
              fymkAffinity
              fymkOverlap
              ...ProfilePreviewFields
            }
            match {
              ...MarkMatchMeetFields
              status
              sentAt
              matchedAt
              archived
            }
          }
        }
      }
      ${ProfilePreviewFields}
      ${MarkMatchMeetFields}
    `
  );

  const latestMessageTime: string | null = useRealtime<string | null>(
    `inbox/${firebaseRealtimeKey}`,
    null
  );

  useEffect(() => {
    refetch();
  }, [latestMessageTime]);

  const sortPreviews = (
    previews:
      | CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews[]
      | undefined
  ) => {
    const active: CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews[] = [];
    const invitations: CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews[] =
      [];
    const archived: CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews[] =
      [];

    previews?.forEach((preview) => {
      if (preview.match?.status === "sent") {
        invitations.push(preview);
      } else if (preview.match?.archived) {
        archived.push(preview);
      } else {
        active.push(preview);
      }
    });

    // If the selected preview no longer belongs in the current tab, add it to the current tab's list
    if (currentPreview) {
      let list;
      if (currentTab === "invitations") {
        list = invitations;
      } else if (currentTab === "active") {
        list = active;
      } else {
        list = archived;
      }

      if (!list.map((p) => p.slug).includes(currentPreview.slug)) {
        list.push(currentPreview);
      }
    }

    [active, invitations, archived].forEach((list) => {
      list.sort((p1, p2) => (p1.latestMessage.createdAt < p2.latestMessage.createdAt ? 1 : -1));
    });

    return { active, invitations, archived };
  };

  const orderedPreviews = sortPreviews(allPreviews);
  const currentOrderedPreviews = orderedPreviews[currentTab];

  const selectPreview = (
    preview: CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews,
    setActive?: boolean
  ) => {
    if (!preview) {
      return;
    }

    setCurrentPreview(preview);
    if (setActive) {
      setIsCurrentPreviewActive(true);
    }

    let newTab = currentTab;
    if (preview.match?.status === "sent") {
      newTab = "invitations";
    } else if (preview.match?.archived) {
      newTab = "archived";
    } else if (!preview.match?.archived) {
      newTab = "active";
    }
    setCurrentTab(newTab);

    const url = new URL(window.location.toString());
    url.searchParams.set(QUERY_PARAM, preview.slug);
    url.searchParams.set(CATEGORY_PARAM, newTab);
    window.history.pushState({}, "", url.toString());
  };

  useEffect(() => {
    if (data?.conversations?.conversationPreviews) {
      setAllPreviews(data?.conversations?.conversationPreviews);
    }
  }, [data?.conversations?.conversationPreviews]);

  const isInvite = (
    preview: CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews
  ) => preview?.match?.status === "sent";
  const isActiveMatch = (
    preview: CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews
  ) => !isInvite(preview) && !preview?.match?.archived;
  const isArchivedMatch = (
    preview: CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews
  ) => !isInvite(preview) && !isActiveMatch(preview);

  useEffect(() => {
    // if tab has been changed and current preview isn't on new tab, update preview
    if (
      !currentPreview ||
      (currentTab === "invitations" && !isInvite(currentPreview)) ||
      (currentTab === "archived" && !isArchivedMatch(currentPreview)) ||
      (currentTab === "active" && !isActiveMatch(currentPreview))
    ) {
      selectPreview(sortPreviews(allPreviews)[currentTab][0]);
    }
  }, [currentTab, currentPreview]);

  useEffect(() => {
    if (!allPreviews || !allPreviews.length) {
      return;
    }

    const selectFirstPreview = () => {
      const sortedPreviews = sortPreviews(allPreviews);
      let isFinished = false;
      [currentTab, ...TAB_PRIORITY].forEach((tab) => {
        if (sortedPreviews[tab].length && !isFinished) {
          selectPreview(sortedPreviews[tab][0]);
          isFinished = true;
        }
      });
    };

    if (!currentPreview) {
      let shouldSelectFirst = true;
      if (selectedSlugFromPath) {
        const preview = allPreviews.find(({ slug }) => slug === selectedSlugFromPath);
        if (preview) {
          selectPreview(preview, true);
          setSelectedSlugFromPath(null);
          shouldSelectFirst = false;
        }
      }

      if (shouldSelectFirst) {
        selectFirstPreview();
      }
    } else {
      const preview = allPreviews?.find(({ slug }) => slug === currentPreview?.slug);
      if (!preview) {
        selectFirstPreview();
      }
    }
  }, [allPreviews]);

  const switchToTab = (newTab: TAB_TYPE) => {
    const url = new URL(window.location.toString());
    url.searchParams.set(CATEGORY_PARAM, newTab);
    url.searchParams.delete(QUERY_PARAM);
    window.history.pushState({}, "", url.toString());
    setCurrentPreview(undefined);
    setIsCurrentPreviewActive(false);
    setCurrentTab(newTab);
  };

  const renderTab = (tabType: TAB_TYPE, label: string) => {
    const previewList = orderedPreviews[tabType];
    return (
      <Tab
        isSelected={currentTab === tabType}
        disabled={!previewList?.length}
        onClick={() => switchToTab(tabType)}
      >
        {previewList.find(isPreviewUnread) && <UnreadIndicator />}
        {label}
        <span>{previewList?.length || 0}</span>
      </Tab>
    );
  };

  const getPreviewMovedMessage = (
    preview: CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews
  ) => {
    if (preview.slug !== currentPreview?.slug) {
      return;
    }

    const actualPreview = allPreviews.filter((p) => p.slug === preview.slug)[0];
    if (!actualPreview) {
      return;
    }

    if (isActiveMatch(actualPreview) && currentTab !== "active") {
      return "Conversation moved to Active matches";
    }
    if (isArchivedMatch(actualPreview) && currentTab !== "archived") {
      return "Conversation moved to Archived matches";
    }
  };
  const latestCurrentPreview = allPreviews.filter((p) => p.slug === currentPreview?.slug)[0];

  return (
    <>
      <TabList>
        {renderTab("active", "Active matches")}
        {renderTab("invitations", "Pending invitations")}
        {renderTab("archived", "Archived matches")}
      </TabList>
      <div css={css({ height: "max(80vh, 300px) !important", display: "flex", margin: "0 30px" })}>
        <Pane
          css={css({
            height: "100%",
            width: "calc(max(30%, 400px))",
            minWidth: 250,
            maxWidth: 500,
            margin: "0 5px",
            overflowY: "scroll",
            ...mobileStyles(
              isCurrentPreviewActive
                ? {
                    display: "none",
                  }
                : {
                    width: "100%",
                    maxWidth: "100%",
                    minWidth: "100%",
                  }
            ),
          })}
        >
          {currentOrderedPreviews.length ? (
            currentOrderedPreviews.map((preview) => (
              <ConversationPreview
                key={preview.slug}
                conversationPreview={preview}
                isSelected={preview.slug === currentPreview?.slug}
                onSelect={() => selectPreview(preview, true)}
                previewMovedMessage={getPreviewMovedMessage(preview)}
              />
            ))
          ) : (
            <div
              css={css({
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 25,
              })}
            >
              <LoadingDots />
            </div>
          )}
        </Pane>
        <Pane
          css={css({
            height: "100%",
            flexGrow: 1,
            margin: "0 5px",
            minWidth: 400,
            maxWidth: 800,
            ...mobileStyles(
              isCurrentPreviewActive
                ? {
                    width: "100%",
                    maxWidth: "100%",
                    minWidth: "100%",
                  }
                : {
                    display: "none",
                  }
            ),
          })}
        >
          <ConversationBox
            slug={latestCurrentPreview?.slug}
            earliestMessageSentAt={latestCurrentPreview?.earliestMessageSentAt}
            profile={latestCurrentPreview?.profile}
            match={latestCurrentPreview?.match}
            viewer={currentProfile}
            firebaseRealtimeKey={firebaseRealtimeKey}
            reloadPreviews={refetch}
            onDecline={() => setCurrentPreview(undefined)}
            deselectConversation={() => setIsCurrentPreviewActive(false)}
          />
        </Pane>
      </div>
    </>
  );
};
