import React from "react";
import styled from "@emotion/styled";
import { css, SerializedStyles } from "@emotion/core";

type TYPE = "default" | "warning" | "negative";

type Props = {
  header?: string;
  type?: TYPE;
  noTopMargin?: boolean;
  customCss?: SerializedStyles;
};

const Header = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

type CardProps = { type?: TYPE; noTopMargin?: boolean };
const Card = styled.div<CardProps>`
  margin: ${({ noTopMargin }) => (noTopMargin ? 0 : 1)}em 0 1em 0;
  padding: 1em 1.5em;
  border: 1px solid rgb(34 36 38 / 22%);
  border-radius: 4px;
  ${(props: CardProps) => {
    if (props.type === "warning") {
      return css`
        background-color: #fffaf3;
        color: #573a08;
        border-color: #c9ba9b;
        ${Header} {
          color: #794b02;
        }
      `;
    }
    if (props.type === "negative") {
      return css`
        background-color: #fff6f6;
        color: #9f3a38;
        border-color: #e0b4b4;
        ${Header} {
          color: #912d2b;
        }
      `;
    }
  }}
`;

const InfoCard: React.FC<Props> = ({ header, type, noTopMargin, children, customCss }) => (
  <Card type={type} noTopMargin={noTopMargin} {...(customCss ? { css: customCss } : {})}>
    {header && <Header>{header}</Header>}
    {children}
  </Card>
);

export default InfoCard;
