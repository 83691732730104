import { useEffect, useRef, useState } from "react";
import useRealtime from "./useRealtime";

export default function useRealtimeData<T>(
  realtimeKey: string,
  initialPayload: T,
  pollFn: () => Promise<boolean>
) {
  const [payload, setPayload] = useState(initialPayload);
  const pollTimerRef = useRef(-1);
  const realtimePayload = useRealtime(realtimeKey, initialPayload);

  const schedulePoll = (fastPoll: boolean) => {
    // Clear any outstanding poll
    clearTimeout(pollTimerRef.current);
    // Poll after 20 seconds of nothing from the websocket, or 5 seconds if websocket already timed out
    const pollDelay = fastPoll ? 5000 : 20_000;

    pollTimerRef.current = window.setTimeout(poll, pollDelay);
  };

  useEffect(() => {
    if (realtimePayload) {
      if (payload !== realtimePayload) {
        setPayload(realtimePayload);
      }
    }
  }, [realtimePayload]);

  // Poll as a fallback
  const poll = async (fastPoll: boolean) => {
    clearTimeout(pollTimerRef.current);
    pollTimerRef.current = -1;
    const hadUpdates = await pollFn();
    schedulePoll(fastPoll || hadUpdates);
  };

  useEffect(() => {
    schedulePoll(false);
    return () => clearTimeout(pollTimerRef.current);
  }, []);

  return payload;
}
