import { css } from "@emotion/core";
import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import Button from "../statelessForms/Button";

type Props = {
  children?: JSX.Element;
  hideStatusText?: boolean;
  centered?: boolean;
};

export default function Submit({ children, hideStatusText = false, centered }: Props) {
  const { control } = useFormContext();
  const { isDirty, isSubmitting, isSubmitSuccessful, errors } = useFormState({
    control,
  });

  let statusMessage = "";

  if (!isDirty) {
    statusMessage = isSubmitSuccessful ? "Changes saved" : "No unsaved changes";
  }
  if (Object.keys(errors).length > 0) statusMessage = "Please fix errors above";
  if (isSubmitting) statusMessage = "Submitting";

  const button = children || (
    <Button type="submit" color="orange" disabled={!isDirty || isSubmitting}>
      Save
    </Button>
  );

  return (
    <div
      css={css({
        display: "flex",
        alignItems: "center",
        ...(centered ? { justifyContent: "center" } : {}),
      })}
    >
      {button}
      {!hideStatusText && <div css={css({ paddingLeft: 14 })}>{statusMessage}</div>}
    </div>
  );
}
