import React from "react";
import { Grid, GridProps, makeStyles } from "@material-ui/core";
import { omit } from "lodash";

type Props = {
  marginBottom?: string;
};

const useStyles = makeStyles({
  root: {
    marginBottom: ({ marginBottom }: Props) => `${marginBottom} !important`,
  },
});

const SpacedGrid: React.FC<Props & GridProps> = (props: Props & GridProps) => {
  const classes = useStyles({ marginBottom: props.marginBottom || "2em" });
  const gridProps = omit(props, "marginBottom");

  return (
    <Grid className={classes.root} {...gridProps}>
      {props.children}
    </Grid>
  );
};

export default SpacedGrid;
