import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Chip, Tab, Tabs, withStyles } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { Checkbox, LocationPicker, MultiSelect } from "../../../components/forms";
import { COFOUNDER_MATCHING_DIRECTORY_cofounderMatching_profile } from "./__generated__/COFOUNDER_MATCHING_DIRECTORY";
import { reqPrefs } from "../profileFields";
import { StyledSlider } from "../../../components/forms/Slider";
import Button from "../../../components/statelessForms/Button";
import ConfirmNewHardFiltersModal from "./ConfirmNewHardFiltersModal";
import { DEFAULT_AGE_MAX, DEFAULT_AGE_MIN, formatList } from "../../../components/forms/util";

type Props = {
  profile: COFOUNDER_MATCHING_DIRECTORY_cofounderMatching_profile;
  onSearch: () => void;
  optionalFieldsToShow: any;
};

const FilterColumnWrapper = styled.div`
  margin-top: -30px;
  width: 100%;
`;

const FilterSection = styled.div`
  margin-top: 10px;
  padding-top: ${({ padded }: { padded?: boolean }) => (padded ? "15px" : 0)};
  &:not(:first-of-type) {
    border-top: 1px solid #e0e0e0;
  }

  h2 {
    font-size: 0.9375em;
    margin-bottom: 5px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const CustomTabs = withStyles({
  indicator: {
    width: 100,
    minWidth: 100,
  },
})(Tabs);

const CustomTab = withStyles({
  root: {
    width: 100,
    minWidth: 100,
  },
})(Tab);

const RadiusExplanationText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  margin-top: 10px;
  margin-bottom: -5px;
  color: gray;
  font-style: italic;
`;

export const BLANK_DIRECTORY_FILTERS = {
  isLoaded: false,
  hasIdea: [],
  technical: [],
  timing: [],
  locations: [],
  regions: [],
  interests: [],
  responsibilities: [],
  womenOnly: false,
  fellowAlumniOnly: false,
  ycAlumOnly: false,
  excludeSkipped: false,
  ageMin: DEFAULT_AGE_MIN,
  ageMax: DEFAULT_AGE_MAX,
  keyword: "",
  partialLocation: "",
  page: undefined,
  locationType: 0,
};

export const REGION_OPTIONS: [string, string][] = [
  ["Africa", "Africa"],
  ["East & Southeast Asia", "East & Southeast Asia"],
  ["Eastern Europe", "Eastern Europe"],
  ["Latin America", "Latin America"],
  ["Middle East and North Africa", "Middle East and North Africa"],
  ["North America", "North America"],
  ["Oceania", "Oceania"],
  ["South Asia", "South Asia"],
  ["Western Europe", "Western Europe"],
];

export const getFiltersFromProfile = (
  profile: COFOUNDER_MATCHING_DIRECTORY_cofounderMatching_profile,
  isAdmin: boolean
) => {
  const filterData: any = { ...BLANK_DIRECTORY_FILTERS };
  const optionalFieldsToShow = {
    womenOnly: false,
    fellowAlumniOnly: false,
    ycAlumOnly: false,
  };

  const enableOptionalField = (
    fieldName: "womenOnly" | "fellowAlumniOnly" | "ycAlumOnly",
    importanceFieldName: keyof COFOUNDER_MATCHING_DIRECTORY_cofounderMatching_profile
  ) => {
    optionalFieldsToShow[fieldName] = true;
    filterData[fieldName] =
      importanceFieldName in profile && profile[importanceFieldName] === "require";
  };

  if (profile.cfHasIdeaImportance === "require") {
    filterData.hasIdea = profile.cfHasIdea ? ["committed", "open"] : ["open", "none"];
  }

  if (profile.cfIsTechnicalImportance === "require") {
    filterData.technical = profile.cfIsTechnical ? [true] : [false];
  }

  if (profile.cfTimingImportance === "require") {
    filterData.timing = ["active", "ready"].includes(profile.timing)
      ? ["active", "ready"]
      : ["year", "passive"];
  }

  if (profile.cfLocationImportance === "require") {
    if (profile.cfLocation === "geographic") {
      filterData.locations = [profile.user.location];
    } else if (profile.cfLocation === "region") {
      filterData.regions = [profile.user.region];
    }
  }

  if (profile.cfInterestsImportance === "require") {
    filterData.interests = profile.interests;
  }

  if (profile.cfResponsibilitiesImportance === "require") {
    filterData.responsibilities = profile.cfResponsibilities;
  }

  if (profile.cfAgeImportance === "require") {
    filterData.ageMin = profile.cfAgeMin;
    filterData.ageMax = profile.cfAgeMax;
  }

  if (isAdmin || ("isWoman" in profile.user && profile.user.isWoman)) {
    enableOptionalField("womenOnly", "cfIsWomanImportance");
  }

  if (isAdmin || !!profile.user.schools.length) {
    enableOptionalField("fellowAlumniOnly", "cfIsFellowAlumniImportance");
  }

  if (isAdmin || ("showYcFounder" in profile.user && profile.user.showYcFounder)) {
    enableOptionalField("ycAlumOnly", "cfIsYcAlumImportance");
  }

  filterData.isLoaded = true;

  return [filterData, optionalFieldsToShow];
};

export const LOCATION_TYPES = ["Cities", "Regions"];

export default ({ profile, onSearch, optionalFieldsToShow }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const formMethods = useFormContext();
  const ageMin = formMethods.watch("ageMin");
  const ageMax = formMethods.watch("ageMax");
  const locations = formMethods.watch("locations");
  const locationType = formMethods.watch("locationType");

  const addValueToArray = (fieldName: string, value: string) => {
    const array = formMethods.getValues()[fieldName] || [];
    if (array.includes(value)) {
      return;
    }
    formMethods.setValue(fieldName, [...array, value]);
  };

  const renderChips = (fieldName: string, values: string[] | undefined) => {
    if (!values) {
      return null;
    }

    return (
      <div css={css({ marginTop: 10 })}>
        {values.map((value: string) => (
          <Chip
            size="small"
            key={value}
            label={value}
            onDelete={() =>
              formMethods.setValue(
                fieldName,
                values.filter((listVal: string) => listVal !== value)
              )
            }
          />
        ))}
      </div>
    );
  };

  const renderCheckboxIfRelevant = (fieldName: string, label: string) => {
    if (!optionalFieldsToShow[fieldName]) {
      return null;
    }

    return (
      <FilterSection padded>
        <h2>{label}</h2>
        <Checkbox fieldName={fieldName} label="Yes" noMargin />
      </FilterSection>
    );
  };

  return (
    <>
      <ConfirmNewHardFiltersModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        getCurrentValues={formMethods.getValues}
        profile={profile}
      />
      <FilterColumnWrapper>
        <FilterSection>
          <MultiSelect
            small
            fieldName="hasIdea"
            label="Idea"
            noneOption="All idea statuses"
            options={[
              ["committed", "Committed to an idea"],
              ["open", "Has some ideas, open to others"],
              ["none", "Open to ideas"],
            ]}
          />
        </FilterSection>
        <FilterSection>
          <MultiSelect
            small
            fieldName="technical"
            label="Skills"
            noneOption="All skills"
            options={[
              [true, "Technical"],
              [false, "Non-technical"],
            ]}
          />
        </FilterSection>
        <FilterSection>
          <MultiSelect
            small
            fieldName="timing"
            label="Timing"
            noneOption="All timings"
            options={[
              ["active", "Already full-time on their startup"],
              ["ready", "Ready after meeting the right person"],
              ["year", "Within a year"],
              ["passive", "No specific plans"],
            ]}
          />
        </FilterSection>
        <FilterSection>
          <MultiSelect
            small
            fieldName="responsibilities"
            label="Responsibilities"
            noneOption="All responsibilities"
            options={Array.from(reqPrefs.responsibilities)}
          />
        </FilterSection>
        <FilterSection padded>
          <h2>Location</h2>
          <TabContainer>
            <CustomTabs
              value={locationType || 0}
              onChange={(_, newType) => formMethods.setValue("locationType", newType)}
              aria-label="styled tabs example"
            >
              {LOCATION_TYPES.map((loc) => (
                <CustomTab label={loc} key={loc} />
              ))}
            </CustomTabs>
          </TabContainer>
          {LOCATION_TYPES[locationType] === "Cities" && (
            <>
              <RadiusExplanationText>
                Including founders within{" "}
                {profile.user.country === "United States" ? "30 miles" : "50km"}
              </RadiusExplanationText>
              {renderChips("locations", locations)}
              <LocationPicker
                fieldName="partialLocation"
                onSelect={(newLocation) => {
                  addValueToArray("locations", newLocation);
                  formMethods.setValue("partialLocation", "");
                }}
              />
            </>
          )}
          {LOCATION_TYPES[locationType] === "Regions" && (
            <>
              <MultiSelect
                small
                fieldName="regions"
                noneOption="All regions"
                options={REGION_OPTIONS}
              />
            </>
          )}
        </FilterSection>
        <FilterSection padded>
          <h2>Age</h2>
          <div css={css({ marginTop: 20, padding: "0 10px" })}>
            <StyledSlider
              min={DEFAULT_AGE_MIN}
              max={DEFAULT_AGE_MAX}
              value={[ageMin || DEFAULT_AGE_MIN, ageMax || DEFAULT_AGE_MAX]}
              onChange={(_, newValues) => {
                if (typeof newValues === "number") {
                  return;
                }
                if (newValues[0] !== ageMin) {
                  formMethods.setValue("ageMin", newValues[0]);
                }
                if (newValues[1] !== ageMax) {
                  formMethods.setValue("ageMax", newValues[1]);
                }
              }}
              onMouseUp={() => onSearch()}
              valueLabelDisplay="on"
            />
          </div>
        </FilterSection>
        {renderCheckboxIfRelevant("womenOnly", "Women only?")}
        {renderCheckboxIfRelevant(
          "fellowAlumniOnly",
          `${formatList(profile.user.schools.map((s) => s.title))} alumni only?`
        )}
        {renderCheckboxIfRelevant("ycAlumOnly", "YC founders only?")}
        <FilterSection>
          <MultiSelect
            small
            fieldName="interests"
            label="Interests"
            noneOption="All interests"
            options={Array.from(reqPrefs.interests)}
          />
        </FilterSection>
        <FilterSection padded>
          <h2>Exclude profiles you've skipped?</h2>
          <Checkbox fieldName="excludeSkipped" label="Yes" noMargin />
        </FilterSection>
        <FilterSection>
          <div css={css({ marginTop: 15, textAlign: "center" })}>
            <Button onClick={() => setModalOpen(true)}>Save these filters to your profile</Button>
          </div>
        </FilterSection>
      </FilterColumnWrapper>
    </>
  );
};
