import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import { useFormContext } from "react-hook-form";
import { MultiSelect } from "./index";
import EmailSuppressionAlert from "../../pages/myProfile/EmailSuppressionAlert";

type Props = {
  email: string;
  fieldName: string;
  relevantOptions: string[];
  required?: boolean;
  disabled?: boolean;
  suppression: { reason: string; type: string | null } | null | undefined;
};

const ALL_OPTIONS: [string, string][] = [
  ["invites_matches", "New invites and matches"],
  ["messages", "New message from a match"],
  ["reminders", "Reminders to respond to new invites and matches (up to once a week)"],
  ["digest", "Weekly announcements, profile suggestions, and founders you may know"],
  ["alerts", "Alert when a new profile that matches all your preferences joins"],
  ["announcements", "New feature announcements"],
  ["events", "Invitations to in-person events in your area"],
  ["weekly_update_reminders", "Reminders to submit a weekly update"],
];

export default ({ fieldName, relevantOptions, required, disabled, email, suppression }: Props) => {
  const { watch, setValue } = useFormContext();

  const settings = watch(fieldName);

  const noEmailOption = (
    <FormControlLabel
      control={<Checkbox color="default" />}
      label="No lifecycle emails, ever"
      value={null}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
          setValue(fieldName, []);
        }
      }}
      checked={!settings?.length}
    />
  );

  if (suppression) {
    return <EmailSuppressionAlert email={email} suppression={suppression} />;
  }

  return (
    <MultiSelect
      fieldName={fieldName}
      required={required}
      anchor="email_settings"
      label="Which emails would you like to receive?"
      options={ALL_OPTIONS.filter(([value, _]) => relevantOptions.includes(value))}
      customOption={noEmailOption}
      disabled={disabled}
    />
  );
};
