import { gql, useMutation } from "@apollo/client";
import React from "react";
import { BasicButton } from "../../components/statelessForms/Button";
import { ARCHIVE_MATCH, ARCHIVE_MATCHVariables } from "./__generated__/ARCHIVE_MATCH";
import {
  UNDO_ARCHIVE_MATCH,
  UNDO_ARCHIVE_MATCHVariables,
} from "./__generated__/UNDO_ARCHIVE_MATCH";
import { CFM_PROFILE_MODAL_cofounderMatching_candidate_request } from "./__generated__/CFM_PROFILE_MODAL";

type Props = {
  match: CFM_PROFILE_MODAL_cofounderMatching_candidate_request;
  onToggle?: () => void;
};

export default ({ match, onToggle }: Props) => {
  const [archiveMatch] = useMutation<ARCHIVE_MATCH, ARCHIVE_MATCHVariables>(gql`
    mutation ARCHIVE_MATCH($matchId: ID!) {
      archiveCofounderMatch(matchId: $matchId) {
        id
        archived
      }
    }
  `);

  const [undoArchiveMatch] = useMutation<UNDO_ARCHIVE_MATCH, UNDO_ARCHIVE_MATCHVariables>(gql`
    mutation UNDO_ARCHIVE_MATCH($matchId: ID!) {
      undoArchiveCofounderMatch(matchId: $matchId) {
        id
        archived
      }
    }
  `);

  const toggleArchiveMatch = () => {
    if (match.archived) {
      undoArchiveMatch({ variables: { matchId: match.id } });
    } else {
      archiveMatch({ variables: { matchId: match.id } });
    }
    if (onToggle) onToggle();
  };

  return (
    <BasicButton fullWidth onClick={toggleArchiveMatch}>
      {match.archived ? "Undo archive" : "Archive"}
    </BasicButton>
  );
};
