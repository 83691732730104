import React, { useState } from "react";
import styled from "@emotion/styled";
import { gql, useMutation } from "@apollo/client";
import { Dialog } from "@material-ui/core";
import Button from "../../../components/statelessForms/Button";
import {
  CFM_INBOX_ACCEPT_REQUEST,
  CFM_INBOX_ACCEPT_REQUESTVariables,
} from "./__generated__/CFM_INBOX_ACCEPT_REQUEST";
import {
  CFM_INBOX_DECLINE_REQUEST,
  CFM_INBOX_DECLINE_REQUESTVariables,
} from "./__generated__/CFM_INBOX_DECLINE_REQUEST";
import { mobileStyleCss } from "../../../styles";
import CFMBlockProfileForm, { ExpandFormButton } from "../CFMBlockProfileForm";

const RespondToInviteBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 130px;
  border-top: 1px solid #e1e1e1;
  span {
    font-weight: bold;
  }

  ${mobileStyleCss(`
    height: 150px;
  `)}
`;

const ReportBox = styled.div`
  padding: 40px;

  ${mobileStyleCss(`
    padding: 20px;
  `)}
`;

const ActionText = styled.span`
  ${mobileStyleCss(`
    font-size: 15px;
  `)}
`;

const ButtonRow = styled.div`
  ${mobileStyleCss(`
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
  `)}
  button {
    width: 200px;
    margin: 5px;
    ${mobileStyleCss(`
      flex-grow: 1;
      max-width: 48%;
    `)}
  }
`;

const InfoText = styled.div`
  ${mobileStyleCss(`
    line-height: 16px;
  `)}
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
`;

type Props = {
  profileSlug: string;
  reloadPreviews: () => any;
  onDecline?: () => any;
};

export default ({ profileSlug, reloadPreviews, onDecline }: Props) => {
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const [cfmAcceptRequest] = useMutation<
    CFM_INBOX_ACCEPT_REQUEST,
    CFM_INBOX_ACCEPT_REQUESTVariables
  >(gql`
    mutation CFM_INBOX_ACCEPT_REQUEST($slug: ID!) {
      cfmAcceptRequest(slug: $slug, currentContext: "inbox") {
        slug
      }
    }
  `);

  const [cfmDeclineRequest] = useMutation<
    CFM_INBOX_DECLINE_REQUEST,
    CFM_INBOX_DECLINE_REQUESTVariables
  >(gql`
    mutation CFM_INBOX_DECLINE_REQUEST($slug: ID!) {
      cfmDeclineRequest(slug: $slug, currentContext: "inbox") {
        slug
      }
    }
  `);

  const onAcceptInvite = async () => {
    await cfmAcceptRequest({ variables: { slug: profileSlug } });
    reloadPreviews();
  };

  const onDeclineInvite = async () => {
    await cfmDeclineRequest({ variables: { slug: profileSlug } });
    await reloadPreviews();
    if (onDecline) {
      onDecline();
    }
  };

  return (
    <RespondToInviteBox>
      <ActionText>Would you like to accept this invitation?</ActionText>
      <ButtonRow>
        <Button color="green" onClick={onAcceptInvite}>
          Accept
        </Button>
        <Button color="gray" onClick={onDeclineInvite}>
          Ignore
        </Button>
      </ButtonRow>
      <InfoText>
        If they're hiring, selling, or bothering you on a different platform,{" "}
        <ExpandFormButton onClick={() => setBlockModalOpen(true)}>
          please report them.
        </ExpandFormButton>
      </InfoText>
      <Dialog
        open={blockModalOpen}
        onClose={() => setBlockModalOpen(false)}
        scroll="body"
        maxWidth="md"
      >
        <ReportBox>
          <CFMBlockProfileForm
            slug={profileSlug}
            hasMatched={false}
            onBlock={() => {
              onDeclineInvite();
              setBlockModalOpen(false);
            }}
            defaultOpen
          />
        </ReportBox>
      </Dialog>
    </RespondToInviteBox>
  );
};
