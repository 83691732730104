import { gql, useMutation } from "@apollo/client";
import React from "react";
import { css } from "@emotion/core";
import { toast } from "react-toastify";
import {
  MARK_MATCH_COMMITTED,
  MARK_MATCH_COMMITTEDVariables,
} from "./__generated__/MARK_MATCH_COMMITTED";
import { MarkMatchCommittedFields as MarkMatchCommittedFieldsType } from "./__generated__/MarkMatchCommittedFields";
import CheckmarkButton from "../../components/Button";

type Props = {
  match: MarkMatchCommittedFieldsType;
  onClick?: () => void;
  disabled?: boolean;
};

export const MarkMatchCommittedFields = gql`
  fragment MarkMatchCommittedFields on CofounderRequest {
    id
    committedAt
  }
`;

export default ({ match, onClick, disabled }: Props) => {
  const [markMatchCommitted] = useMutation<MARK_MATCH_COMMITTED, MARK_MATCH_COMMITTEDVariables>(gql`
    mutation MARK_MATCH_COMMITTED($matchId: ID!) {
      cfmMarkMatchCommitted(matchId: $matchId) {
        id
        committedAt
      }
    }
  `);

  const confirmAndCommit = () => {
    if (
      confirm(
        "Have you both agreed to committing as co-founders? This action will send an email to both you and your match."
      )
    ) {
      const variables = { matchId: match.id };
      markMatchCommitted({ variables });
      if (onClick) onClick();
      toast.success("Congratulations on committing as co-founders!");
    }
  };

  return (
    <CheckmarkButton
      minimal
      disabled={disabled || match.committedAt}
      leftAlignCheckbox
      size="small"
      css={css({ width: "100%" })}
      type={match.committedAt ? "minimalSuccess" : "minimalInactiveSuccess"}
      onClick={() => {
        if (!match.committedAt) {
          confirmAndCommit();
        }
      }}
      text="Commit as co-founders"
    />
  );
};
