import React from "react";
import { useController, useFormContext } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import Field, { FieldProps } from "./Field";
import { splitProps } from "./util";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../statelessForms/Input";

type Props = {
  allowEmpty?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
};

export default function DatePicker(props: FieldProps & Props) {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);
  const { field } = useController({
    control,
    name: props.fieldName,
    rules: { required: fieldProps.required },
  });

  let selectedValue = field.value && new Date(field.value);
  if (!props.allowEmpty && !selectedValue) {
    selectedValue = new Date();
  }

  const dateFormat = props.dateFormat ?? (props.showTimeSelect ? "PP h:mm aa" : "PP");

  return (
    <Field {...fieldProps}>
      <ReactDatePicker
        {...otherProps}
        selected={selectedValue}
        dateFormat={dateFormat}
        showTimeSelect={props.showTimeSelect}
        onChange={(date: Date) => field.onChange(date)}
        customInput={<Input size="small" />}
      />
    </Field>
  );
}
