import { gql, useMutation } from "@apollo/client";
import { css } from "@emotion/core";
import { format, formatDistance } from "date-fns";
import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CFM_RATE_PROFILE, CFM_RATE_PROFILEVariables } from "./__generated__/CFM_RATE_PROFILE";
import {
  CFM_SUSPEND_PROFILE,
  CFM_SUSPEND_PROFILEVariables,
} from "./__generated__/CFM_SUSPEND_PROFILE";
import { UserAdminActionFragment } from "../cofounderMatching/fragments";

import {
  ADMIN_USERS_TO_REVIEW_admin_usersToReview,
  ADMIN_USERS_TO_REVIEW_admin_usersToReview_adminCfmProfile_votes,
} from "./__generated__/ADMIN_USERS_TO_REVIEW";
import AdminRateUserInf from "./AdminRateUserInf";
import { ADMIN_UNRATED_USERS_admin_unratedUsers } from "./__generated__/ADMIN_UNRATED_USERS";
import { ADMIN_USER_admin_user } from "./__generated__/ADMIN_USER";
import { RATE_USER, RATE_USERVariables } from "./__generated__/RATE_USER";
import { BasicButton } from "../../components/statelessForms/Button";
import {
  RECORD_PROFILE_ADMIN_ACTION,
  RECORD_PROFILE_ADMIN_ACTIONVariables,
} from "./__generated__/RECORD_PROFILE_ADMIN_ACTION";
import {
  RECORD_USER_ADMIN_ACTION,
  RECORD_USER_ADMIN_ACTIONVariables,
} from "./__generated__/RECORD_USER_ADMIN_ACTION";
import SnoozeForLaterAdminReview from "./SnoozeForLaterAdminReview";
import { AnyAdminProfile } from "../cofounderMatching/profileFields";

type ActionType =
  | "rate"
  | "inf"
  | "uninf"
  | "suspend"
  | "unsuspend"
  | "comment"
  | "review"
  | "shadowban"
  | "unshadowban"
  | "snooze";
type VoteType = "inf" | "Y" | "y" | "m" | "n" | "N" | "suspended";

const voteType = new Map<VoteType, string>([
  ["Y", "#21BA45"],
  ["y", "#B5CC18"],
  ["m", "#FBBD08"],
  ["n", "#fb651e"],
  ["N", "#DB2828"],
]);

const actionTypeColor = new Map<ActionType, string>([
  ["inf", "#21BA45"],
  ["uninf", "#63CE7C"],
  ["suspend", "#A333C8"],
  ["unsuspend", "#DAADE9"],
  ["review", "#989898"],
  ["comment", "#484848"],
  ["shadowban", "#ff0000"],
  ["unshadowban", "#ff9999"],
  ["snooze", "gray"],
]);

type VoteProps = {
  isSelected?: boolean;
  color: string;
};
export const VoteButton = styled.button<VoteProps>`
  background-color: ${(props) => (props.isSelected ? props.color : "white")};
  color: ${(props) => (props.isSelected ? "white" : props.color)};
  font-weight: ${(props) => (props.isSelected ? "bold" : "regular")};
  border: 1px solid ${(props) => props.color};
  border-radius: 4px;
  padding: 8px 15px;
  margin: 2px;
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  candidate?: AnyAdminProfile;
  adminUser?:
    | ADMIN_UNRATED_USERS_admin_unratedUsers
    | ADMIN_USER_admin_user
    | ADMIN_USERS_TO_REVIEW_admin_usersToReview
    | undefined;
  superAdmin?: boolean;
  allVotes?: ADMIN_USERS_TO_REVIEW_admin_usersToReview_adminCfmProfile_votes[];
  toastOnVote?: boolean | undefined;
  refetch?: () => void;
};

export const VoteIndicator = styled.div<VoteProps>`
  background-color: ${(props) => props.color};
  color: white;
  margin-right: 5px;
  padding: 0.5em 0.8em;
  border-radius: 4px;
  width: fit-content;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
`;

export default ({ candidate, adminUser, superAdmin, allVotes, toastOnVote, refetch }: Props) => {
  const [rateProfile] = useMutation<CFM_RATE_PROFILE, CFM_RATE_PROFILEVariables>(gql`
    mutation CFM_RATE_PROFILE($slug: ID!, $rating: String!, $reason: String!) {
      cfmRateProfile(slug: $slug, rating: $rating, reason: $reason) {
        slug
        adminRating
        adminApproved
        needsAdminReview
        suspended
        votes {
          ...UserAdminActionFragment
        }
      }
    }
    ${UserAdminActionFragment}
  `);

  const [rateUser] = useMutation<RATE_USER, RATE_USERVariables>(gql`
    mutation RATE_USER($slug: ID!, $rating: String!, $reason: String!) {
      adminRateUser(slug: $slug, rating: $rating, reason: $reason) {
        slug
        adminRating
        needsAdminReview
        votes {
          ...UserAdminActionFragment
        }
      }
    }
    ${UserAdminActionFragment}
  `);

  const [recordUserAdminAction] = useMutation<
    RECORD_USER_ADMIN_ACTION,
    RECORD_USER_ADMIN_ACTIONVariables
  >(gql`
    mutation RECORD_USER_ADMIN_ACTION($slug: ID!, $actionType: String!, $reason: String!) {
      recordUserAdminAction(slug: $slug, actionType: $actionType, reason: $reason) {
        slug
        shadowbanned
        votes {
          ...UserAdminActionFragment
        }
      }
    }
    ${UserAdminActionFragment}
  `);

  const [recordProfileAdminAction] = useMutation<
    RECORD_PROFILE_ADMIN_ACTION,
    RECORD_PROFILE_ADMIN_ACTIONVariables
  >(gql`
    mutation RECORD_PROFILE_ADMIN_ACTION($slug: ID!, $actionType: String!, $reason: String!) {
      recordProfileAdminAction(slug: $slug, actionType: $actionType, reason: $reason) {
        slug
        adminUser {
          slug
          shadowbanned
        }
        votes {
          ...UserAdminActionFragment
        }
      }
    }
    ${UserAdminActionFragment}
  `);

  const [suspendProfile] = useMutation<CFM_SUSPEND_PROFILE, CFM_SUSPEND_PROFILEVariables>(gql`
    mutation CFM_SUSPEND_PROFILE($slug: ID!, $reason: String!, $unsuspend: Boolean) {
      cfmSuspendProfile(slug: $slug, reason: $reason, unsuspend: $unsuspend) {
        slug
        adminRating
        adminApproved
        needsAdminReview
        suspended
        votes {
          ...UserAdminActionFragment
        }
      }
    }
    ${UserAdminActionFragment}
  `);

  const [reason, setReason] = useState("");

  if (!candidate && !adminUser) {
    return <div />;
  }

  const user = adminUser?.user;
  const suspended = candidate?.suspended;
  const adminApproved = candidate?.adminApproved;
  const needsAdminReview = candidate?.needsAdminReview || adminUser?.needsAdminReview;
  const vote = candidate?.adminRating || adminUser?.adminRating;
  const realUser = user || candidate?.profile.user;
  const realAdminUser = adminUser || candidate?.adminUser;

  // This should never happen, but it makes typescript happy
  if (!realUser) {
    return <div />;
  }

  const votesToShow = allVotes || candidate?.votes || adminUser?.votes || [];

  const renderAdminActionTag = (actionType: string, maybeVote: string | null) => {
    if (actionType === "rate") {
      return (
        <VoteIndicator color={voteType.get(maybeVote as VoteType) as string}>
          {maybeVote}
        </VoteIndicator>
      );
    }

    return (
      <VoteIndicator color={actionTypeColor.get(actionType as ActionType) as string}>
        {actionType}
      </VoteIndicator>
    );
  };

  const onRate = async (rating: string) => {
    if (candidate) {
      await rateProfile({ variables: { slug: candidate.profile.slug, rating, reason } });
    } else if (user) {
      await rateUser({ variables: { slug: user.slug, rating, reason } });
    }

    setReason("");
    if (refetch) refetch();

    if (toastOnVote) {
      toast.success(`Rating (${rating}) given to ${realUser.name}`);
    }
  };

  const onAdminAction = async (actionType: ActionType) => {
    if (candidate) {
      await recordProfileAdminAction({
        variables: { slug: candidate.profile.slug, actionType, reason },
      });
    } else if (user) {
      await recordUserAdminAction({ variables: { slug: user.slug, actionType, reason } });
    }

    setReason("");
    if (refetch) refetch();
  };

  const renderDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const relativeDate = formatDistance(date, new Date(), { addSuffix: true });
    const actualDate = format(date, "P");

    return `${relativeDate} (${actualDate})`;
  };

  return (
    <div>
      {candidate && needsAdminReview && (
        <SnoozeForLaterAdminReview
          slug={candidate.profile.slug}
          reason={reason}
          setReason={setReason}
          refetch={refetch}
        />
      )}

      {superAdmin && realAdminUser?.shadowbanned && (
        <VoteButton
          color="#ff9999"
          onClick={async () => {
            await onAdminAction("unshadowban");
          }}
        >
          Un-shadowban
        </VoteButton>
      )}

      {superAdmin && realAdminUser && (
        <AdminRateUserInf
          userId={realAdminUser.id}
          inf={realAdminUser.adminRatingInf}
          reason={reason}
          onRatingInf={() => {
            setReason("");
            if (refetch) refetch();
          }}
        />
      )}
      {Array.from(voteType).map(([val, color]) => (
        <VoteButton
          key={val}
          isSelected={vote === val}
          color={color}
          onClick={async () => {
            await onRate(val);
          }}
        >
          {val}
        </VoteButton>
      ))}
      {superAdmin && candidate && (
        <VoteButton
          key="suspend"
          isSelected={suspended}
          color="#A333C8"
          onClick={() => {
            suspendProfile({
              variables: { slug: candidate.profile.slug, reason, unsuspend: suspended },
            });
            setReason("");
          }}
        >
          {suspended ? "unsuspend" : "suspend"}
        </VoteButton>
      )}

      <div css={css({ marginTop: 5 })}>Needs admin review: {needsAdminReview ? "yes" : "no"}</div>
      <div css={css({ marginTop: 5 })}>
        Admin approved: {adminApproved?.toString()}
        {superAdmin && suspended && (
          <div>
            <b css={css({ color: "#A333C8" })}>SUSPENDED</b>
          </div>
        )}
      </div>
      <div>
        <TextareaAutosize
          css={css({ width: "100%", marginTop: 15 })}
          minRows={3}
          placeholder="Vote reason (optional)"
          value={reason}
          onChange={({ target }) => setReason(target.value)}
        />
      </div>
      {superAdmin && (
        <div
          css={css({
            padding: "10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            button: { width: "48%" },
          })}
        >
          <BasicButton onClick={() => onAdminAction("comment")}>Comment</BasicButton>
          <BasicButton onClick={() => onAdminAction("review")}>Mark as reviewed</BasicButton>
        </div>
      )}
      {votesToShow && (
        <div css={css({ marginBottom: 14 })}>
          {votesToShow.map((v) => (
            <div key={v.id} css={css({ padding: "5px 0", borderBottom: "1px solid #e0e0e0" })}>
              <p css={css({ fontStyle: "italic", color: "gray", fontSize: 12, marginBottom: 2 })}>
                {renderDate(v.createdAt)}
              </p>
              {renderAdminActionTag(v.actionType, v.vote)}
              {v.rater.name}
              {v.reason && <p css={css({ fontSize: 14 })}>{v.reason}</p>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
