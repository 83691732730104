import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { COFOUNDER_MATCHING_TOP_INVITERS_admin_cofounderMatching_topInvitersThisWeek_invitesSentThisWeek } from "./__generated__/COFOUNDER_MATCHING_TOP_INVITERS";
import { bySlugAdminCofounderMatchingPath } from "../../__generated__/routes";

type Props = {
  invites: COFOUNDER_MATCHING_TOP_INVITERS_admin_cofounderMatching_topInvitersThisWeek_invitesSentThisWeek[];
};

const InvitesContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #eeeee5;
  max-height: 500px;
  overflow: scroll;
  margin-top: 30px;
  font-size: 14px;
`;

export default ({ invites }: Props) => (
  <InvitesContainer>
    <h4>
      {invites.length} invites sent in the past week (
      {invites.filter((i) => i.request.status === "accepted").length} accepted)
    </h4>
    <ul>
      {invites.map((invite) => (
        <li css={css({ marginBottom: 15 })}>
          <p css={css({ marginBottom: 0 })}>
            <a href={bySlugAdminCofounderMatchingPath(invite.adminReceiver.slug)}>
              {invite.adminReceiver.adminUser.name} ({invite.adminReceiver.adminUser.adminRating}) -{" "}
              <b>{invite.request.status}</b>
            </a>
          </p>
          <p>{invite.request.message}</p>
        </li>
      ))}
    </ul>
  </InvitesContainer>
);
