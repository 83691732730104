import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import styled from "@emotion/styled";
import { ActionButton, CurrentContextType } from "./CFMCandidateActionBox";
import { CFM_SAVE_PROFILE, CFM_SAVE_PROFILEVariables } from "./__generated__/CFM_SAVE_PROFILE";
import {
  CFM_UNSAVE_PROFILE,
  CFM_UNSAVE_PROFILEVariables,
} from "./__generated__/CFM_UNSAVE_PROFILE";
import { ProfilePreviewFields } from "./__generated__/ProfilePreviewFields";
import { AnyCFMCandidateProfile } from "./profileFields";

type Props = {
  profile: AnyCFMCandidateProfile | ProfilePreviewFields;
  loading?: boolean;
  onChange?: () => void;
  trackSavedStatus?: boolean;
  icon?: boolean;
  showIconText?: boolean;
  currentContext: CurrentContextType;
};

const StarContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconText = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #fbbd08;
`;

export default ({
  profile,
  loading,
  onChange,
  trackSavedStatus,
  icon,
  showIconText,
  currentContext,
}: Props) => {
  const [hasSaved, setHasSaved] = useState(profile.request?.status === "saved");
  const [isPerformingAction, setIsPerformingAction] = useState(false);

  const [cfmSaveProfile] = useMutation<CFM_SAVE_PROFILE, CFM_SAVE_PROFILEVariables>(gql`
    mutation CFM_SAVE_PROFILE($slug: ID!, $currentContext: String!) {
      cfmSaveProfile(slug: $slug, currentContext: $currentContext) {
        slug
        request {
          status
        }
      }
    }
  `);

  const [cfmUnsaveProfile] = useMutation<CFM_UNSAVE_PROFILE, CFM_UNSAVE_PROFILEVariables>(gql`
    mutation CFM_UNSAVE_PROFILE($slug: ID!) {
      cfmUnsaveProfile(slug: $slug) {
        slug
        request {
          status
        }
      }
    }
  `);

  const saveProfile = async () => {
    setIsPerformingAction(true);
    await cfmSaveProfile({ variables: { slug: profile.slug, currentContext } });
    setHasSaved(true);
    if (onChange) onChange();
    setIsPerformingAction(false);
  };

  const unsaveProfile = async () => {
    setIsPerformingAction(true);
    await cfmUnsaveProfile({ variables: { slug: profile.slug } });
    setHasSaved(false);
    if (onChange) onChange();
    setIsPerformingAction(false);
  };

  const isSaved = trackSavedStatus ? hasSaved : profile.request?.status === "saved";
  const saveDisabled = loading || isPerformingAction;
  const iconTextSection = showIconText && (
    <IconText>{isSaved ? "Saved!" : "Save to favorites"}</IconText>
  );

  if (icon) {
    return isSaved ? (
      <StarContainer onClick={unsaveProfile}>
        {iconTextSection}
        <StarIcon htmlColor="#fbbd08" fontSize="large" />
      </StarContainer>
    ) : (
      <StarContainer onClick={saveProfile}>
        {iconTextSection}
        <StarOutlineIcon htmlColor="#fbbd08" fontSize="large" />
      </StarContainer>
    );
  }
  return isSaved ? (
    <ActionButton color="yellow" disabled={saveDisabled} onClick={unsaveProfile}>
      Un-save
    </ActionButton>
  ) : (
    <ActionButton color="yellow" disabled={saveDisabled} onClick={saveProfile}>
      Save to favorites
    </ActionButton>
  );
};
