import styled from "@emotion/styled";

export default styled.div`
  background: none #ffffff;
  border: 1px solid #db2828;
  color: #db2828;
  padding: 0.5833em 0.833em;
  margin-top: 1em;
  position: relative;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: bold;
  border-radius: 4px;
  transition: background 0.1s ease;
  ::before {
    margin-top: -1px;
    border-style: solid;
    border-color: inherit;
    border-width: 1px 0 0 1px;
    background-color: inherit;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 0;
    left: 50%;
    position: absolute;
    content: "";
    width: 0.6666em;
    height: 0.6666em;
  }
`;
