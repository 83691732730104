import { css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import TextareaAutosize from "react-textarea-autosize";
import styled from "@emotion/styled";
import { UPDATE_NOTES, UPDATE_NOTESVariables } from "./__generated__/UPDATE_NOTES";
import { paneBorder } from "../../styles";

const SavedNotes = styled.p`
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  padding: 10px 15px;
  white-space: pre-wrap;
`;

const TextOnlyButton = styled.button`
  background-color: transparent;
  border: none;
  color: #268bd2;
  font-weight: bold;
  &:hover:enabled {
    cursor: pointer;
  }
  &:disabled {
    color: #000000;
    opacity: 0.5;
  }
  &:last-child {
    margin-left: 5px;
  }
`;

export default (props: { slug: string }) => {
  const { data, loading, refetch } = useQuery(
    gql`
      query COFOUNDER_NOTES($slug: ID!) {
        cofounderMatching {
          notes(slug: $slug)
        }
      }
    `,
    { variables: { slug: props.slug } }
  );

  const [updateNotes] = useMutation<UPDATE_NOTES, UPDATE_NOTESVariables>(gql`
    mutation UPDATE_NOTES($slug: String!, $notes: String!) {
      updateCofounderNotes(slug: $slug, notes: $notes)
    }
  `);

  const [isEditing, setIsEditing] = useState(true);
  const [updatedNotes, setUpdatedNotes] = useState<string>("");
  const [lastSavedNotes, setLastSavedNotes] = useState<string>("");

  useEffect(() => {
    if (!loading && data) {
      const { notes } = data.cofounderMatching;
      setUpdatedNotes(notes);
      setLastSavedNotes(notes);

      if (notes) {
        setIsEditing(false);
      }
    }
  }, [loading, data]);

  if (!data) {
    return null;
  }

  const onSave = async () => {
    await updateNotes({ variables: { slug: props.slug, notes: updatedNotes } });
    setLastSavedNotes(updatedNotes);
    refetch();
  };

  return (
    <>
      <p css={css({ marginBottom: 10, fontWeight: "bold" })}>Private Notes</p>
      {isEditing ? (
        <>
          <TextareaAutosize
            css={css({
              marginBottom: 10,
              border: paneBorder,
              borderRadius: 4,
              padding: 7,
              width: "100%",
            })}
            minRows={7}
            value={updatedNotes}
            onChange={(e) => setUpdatedNotes(e.currentTarget.value)}
          />
          <div css={css({ textAlign: "right" })}>
            <TextOnlyButton disabled={updatedNotes == lastSavedNotes} onClick={onSave}>
              Save
            </TextOnlyButton>
            {!!lastSavedNotes && (
              <TextOnlyButton disabled={!updatedNotes} onClick={() => setIsEditing(false)}>
                Cancel
              </TextOnlyButton>
            )}
          </div>
        </>
      ) : (
        <>
          <SavedNotes>{lastSavedNotes}</SavedNotes>
          <div css={css({ textAlign: "right" })}>
            <TextOnlyButton onClick={() => setIsEditing(true)}>Edit</TextOnlyButton>
          </div>
        </>
      )}
    </>
  );
};
