import React, { useState } from "react";
import styled, { CSSObject } from "@emotion/styled";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { css } from "@emotion/core";
import { mobileStyleCss } from "../styles";

const PADDING = 30;
const BORDER_COLOR = "#c0c0c0";

const Wrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid ${BORDER_COLOR};
  border-radius: 8px;
  width: 100%;
  margin-bottom: 23px;
  overflow: hidden;
`;

type HeaderProps = {
  expandable?: boolean;
  isExpanded?: boolean;
  compact?: boolean;
};
const Header = styled.div<HeaderProps>`
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  padding: ${PADDING}px ${PADDING}px 24px ${PADDING}px;
  border-bottom: 1px solid ${BORDER_COLOR};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ expandable, isExpanded }) =>
    expandable &&
    css`
      ${!isExpanded && "border-bottom: none;"}
      &:hover {
        cursor: pointer;
      }
    `}

  ${({ compact }) =>
    compact &&
    css`
      font-size: 16px;
      border-bottom: none;
      padding: 15px 20px 0 20px;
    `}
`;

const ItemPane = styled.div<ItemProps>`
  ${({ subSection, padding, position }) =>
    css`
      padding-top: ${padding}px;
      padding-bottom: ${padding}px;
      ${subSection ? "margin-left" : "padding-left"}: ${padding}px;
      ${subSection ? "margin-right" : "padding-right"}: ${padding}px;
      ${mobileStyleCss(`
        padding-top: ${(padding || 0) / 2}px;
        padding-bottom: ${(padding || 0) / 2}px;
      `)}
      position: ${position || "default"};
    `};
  &:not(:last-child) {
    border-bottom: 1px solid ${BORDER_COLOR};
  }
  ${({ onClick }) =>
    onClick
      ? css`
          &:hover {
            cursor: pointer;
            box-shadow: 0 0px 10px 5px rgb(34 36 38 / 5%) inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
            background-color: #fbfbfb;
          }
        `
      : ""}
`;

type Props = {
  header?: string | JSX.Element;
  expandable?: boolean;
  defaultHidden?: boolean;
  compact?: boolean;
  wrapperStyles?: CSSObject;
};

const Section: React.FC<Props> = (props) => {
  const { expandable, defaultHidden, header, compact, wrapperStyles, children } = props;
  const [isExpanded, setIsExpanded] = useState(!defaultHidden);

  return (
    <Wrapper css={css(wrapperStyles || {})}>
      {header && (
        <Header
          expandable={expandable}
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          compact={compact}
        >
          {header}
          {expandable && (isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
        </Header>
      )}
      {(!expandable || !header || isExpanded) && children}
    </Wrapper>
  );
};

type ItemProps = {
  subSection?: boolean;
  onClick?: () => void;
  padding?: number;
  position?: string;
  fullHeight?: boolean;
};
export const Item: React.FC<ItemProps> = ({
  children,
  subSection,
  onClick,
  padding,
  position,
  fullHeight,
}) => (
  <ItemPane
    subSection={subSection}
    padding={padding ?? PADDING}
    position={position}
    {...(onClick ? { onClick } : {})}
    css={css({
      ...(fullHeight ? { height: "100%" } : {}),
    })}
  >
    {children}
  </ItemPane>
);

export default Section;
