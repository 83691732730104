import React, { useState } from "react";
import { css } from "@emotion/core";
import CheckmarkButton from "../../components/Button";
import RecommendationModal from "./RecommendationModal";

type Props = {
  slug: string;
  isCreated: boolean;
  onSubmit: () => Promise<any>;
};

export default ({ slug, onSubmit, isCreated }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onCloseModal = async (shouldReload: boolean) => {
    if (shouldReload) {
      await onSubmit();
    }
    setModalOpen(false);
  };

  const onOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  return (
    <>
      <CheckmarkButton
        minimal
        type={isCreated ? "minimalSuccess" : "minimalInactiveSuccess"}
        size="small"
        onClick={onOpen}
        text={isCreated ? "Endorsed" : "Endorse"}
        disabled={isCreated}
        css={css({ width: "100%" })}
      />
      <RecommendationModal isOpen={modalOpen} onClose={onCloseModal} slug={slug} />
    </>
  );
};
