import React from "react";
import styled from "@emotion/styled";
import { COFOUNDER_MATCHING_UNRATED_CANDIDATES_cfmProfileReview_unratedCandidates_profile_recommendations } from "../admin/__generated__/COFOUNDER_MATCHING_UNRATED_CANDIDATES";
import {
  CFM_RECOMMENDATIONS_cofounderMatching_recommendationsGiven,
  CFM_RECOMMENDATIONS_cofounderMatching_recommendationsGiven_receiverProfile,
  CFM_RECOMMENDATIONS_cofounderMatching_recommendationsReceived,
} from "./__generated__/CFM_RECOMMENDATIONS";
import { candidateCofounderMatchingPath } from "../../__generated__/routes";
import { mobileStyleCss } from "../../styles";

type Props = {
  rec:
    | COFOUNDER_MATCHING_UNRATED_CANDIDATES_cfmProfileReview_unratedCandidates_profile_recommendations
    | CFM_RECOMMENDATIONS_cofounderMatching_recommendationsReceived
    | CFM_RECOMMENDATIONS_cofounderMatching_recommendationsGiven;
  profile?: CFM_RECOMMENDATIONS_cofounderMatching_recommendationsGiven_receiverProfile;
  hideAttribution?: boolean;
};

const RecommendationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${mobileStyleCss(`
    flex-direction: column;
  `)}
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  width: 120px;
  max-width: 120px;

  img {
    margin-bottom: 15px;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    object-fit: cover;
  }

  a {
    text-align: center;
  }

  ${mobileStyleCss(`
    margin-bottom: 15px;
    img {
      height: 60px;
      width: 60px;
    }
  `)}
`;

const Quote = styled.div`
  ${mobileStyleCss(`
    margin-bottom: 5px;
  `)}
`;

const QuoteAttribution = styled.div`
  width: fit-content;
  margin-left: auto;
`;

const EndorsementText = styled.div`
  font-size: 14px;
  flex-grow: 1;
  margin-bottom: 15px;
`;

export default ({ rec, profile, hideAttribution }: Props) => (
  <RecommendationContainer>
    {profile && (
      <Profile>
        <img src={profile.user.avatarUrl} alt="Avatar" />
        <a href={candidateCofounderMatchingPath(profile.slug)}>{profile.user.name}</a>
      </Profile>
    )}
    <EndorsementText>
      <Quote>"{rec.text}"</Quote>
      {!hideAttribution && (
        <QuoteAttribution>
          <span>— {rec.senderName}</span>
          {rec.senderCompany && rec.senderCompanyUrl && (
            <span>
              , <a href={rec.senderCompanyUrl || ""}>{rec.senderCompany}</a>
            </span>
          )}
          {rec.senderProfileUrl && (
            <span>
              , <a href={rec.senderProfileUrl}>co-founder profile</a>
            </span>
          )}
        </QuoteAttribution>
      )}
    </EndorsementText>
  </RecommendationContainer>
);
