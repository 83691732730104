import { gql } from "@apollo/client";

export const CofounderPreferenceFragment = gql`
  fragment CofounderPreferenceFragment on CofounderProfile {
    cfHasIdea
    cfHasIdeaImportance
    cfIsTechnical
    cfIsTechnicalImportance
    cfResponsibilities
    cfResponsibilitiesImportance
    cfLocation
    cfLocationImportance
    cfLocationKmRange
    cfAgeMin
    cfAgeMax
    cfAgeImportance
    cfTimingImportance
    cfInterestsImportance
    cfIsFellowAlumniImportance
    cfIsWomanImportance
    cfIsYcAlumImportance
  }
`;

export const RecommendationFragment = gql`
  fragment RecommendationFragment on Recommendation {
    slug
    senderName
    senderCompany
    senderCompanyUrl
    senderProfileUrl
    text
  }
`;

const BasicProfileFragment = gql`
  fragment BasicProfileFragment on CofounderProfile {
    active
    approvalStatus
    timing
    emailSettings
    hasIdea
    ideas
    hasCf
    currentCfLinkedin
    currentCfTechnical
    whyLookingForThirdCf
    interests
    intro
    lastSeenAt
    equity
    freeTime
    lifeStory
    other
    reqFreeText
    responsibilities
    slug
    videoLink
    calendlyLink
    fymkAffinity
    fymkOverlap
    howHeard
    ...CofounderPreferenceFragment
    recommendations {
      ...RecommendationFragment
    }
  }
  ${CofounderPreferenceFragment}
  ${RecommendationFragment}
`;

const AdminProfileFragment = gql`
  fragment AdminProfileFragment on CofounderProfile {
    createdAt
    lastSeenAt
    cfIsWomanImportance
    cfIsYcAlumImportance
    cfIsFellowAlumniImportance
  }
`;

export const SchoolFragment = gql`
  fragment SchoolFragment on School {
    name
    title
    domain
    colorPrimary
    colorSecondary
  }
`;

export const BasicUserFragment = gql`
  fragment BasicUserFragment on User {
    avatarUrl
    impressiveThing
    education
    employment
    isTechnical
    isWoman
    linkedin
    location
    name
    slug
    showYcFounder
    schools {
      ...SchoolFragment
    }
    ycFounderLabel
    age
    twitterLink
    instagramLink
  }
  ${SchoolFragment}
`;

export const BasicCfmUserFragment = gql`
  fragment BasicCfmUserFragment on User {
    ...BasicUserFragment
    firstName
    country
    region
  }
  ${BasicUserFragment}
`;

const BasicCompanyFragment = gql`
  fragment BasicCompanyFragment on PublicCompany {
    description
    name
    progress
    fundingStatus
    slug
    url
  }
`;

export const CFMProfileFragment = gql`
  fragment CFMProfileFragment on CofounderProfile {
    ...BasicProfileFragment
    conversationSlug
    metAtSpeedDatingToday
    fymkAffinity
    fymkOverlap

    company {
      ...BasicCompanyFragment
    }

    user {
      ...BasicCfmUserFragment
    }

    request {
      slug
      status
      sender {
        slug
      }
    }
  }
  ${BasicProfileFragment}
  ${BasicCompanyFragment}
  ${BasicCfmUserFragment}
`;

export const AdminCfmReportFragment = gql`
  fragment AdminCfmReportFragment on CfmReport {
    id
    reason
    other
    userCfmId
    createdAt
    adminUser {
      slug
      name
      adminRating
    }
  }
`;

export const AdminUserStubFragment = gql`
  fragment AdminUserStubFragment on AdminUserStub {
    id
    automatedRating
    enrichedEducation
    enrichedEmployment
  }
`;

export const AdminBaseProfileFragment = gql`
  fragment AdminBaseProfileFragment on CofounderProfile {
    ...BasicProfileFragment
    ...AdminProfileFragment

    company {
      ...BasicCompanyFragment
    }

    user {
      slug
      ...BasicCfmUserFragment
    }
  }
  ${BasicProfileFragment}
  ${AdminProfileFragment}
  ${BasicCompanyFragment}
  ${BasicCfmUserFragment}
`;

export const BadActorFragment = gql`
  fragment BadActorFragment on BadActor {
    id
    reason
    comment
    createdAt
    external
    confirmed
    creatorName
  }
`;

export const UserAdminActionFragment = gql`
  fragment UserAdminActionFragment on UserAdminAction {
    id
    createdAt
    actionType
    vote
    reason
    rater {
      slug
      name
    }
  }
`;

export const RecentMessagesSentFragment = gql`
  fragment RecentMessagesSentFragment on AdminCfmMessage {
    message
    status
    createdAt
    adminReceiver {
      id
      slug
      adminRating
      user {
        slug
        name
      }
    }
  }
`;

export const BaseAdminUserFragment = gql`
  fragment BaseAdminUserFragment on AdminUser {
    id
    slug
    email
    adminPath
    adminRating
    adminRatingComplete
    adminRatingInf
    needsAdminReview
    ycFounder
    shadowbanned
    monoidRating
  }
`;

export const AdminCFMProfileFragment = gql`
  fragment AdminCFMProfileFragment on CofounderAdminProfile {
    id
    slug
    userId
    email
    adminRating
    adminRatingInf
    adminApproved
    needsAdminReview
    suspended
    canBeSuspended
    userPath
    partnerContact
    partnerContactedAt
    weeklyInviteLimit
    adminUser {
      ...BaseAdminUserFragment

      adminUserStub {
        ...AdminUserStubFragment
      }

      ycApps {
        id
        batch
        result
        url
      }
    }
    votes {
      ...UserAdminActionFragment
    }
  }
  ${UserAdminActionFragment}
  ${BaseAdminUserFragment}
  ${AdminUserStubFragment}
`;

export const CFMViewerProfileFragment = gql`
  fragment CFMViewerProfileFragment on CofounderProfile {
    slug
    intro
    hasIdea
    timing
    responsibilities
    interests
    cfIsWomanImportance
    cfIsYcAlumImportance
    cfIsFellowAlumniImportance
    numInvitesPerWeek
    ...CofounderPreferenceFragment
    user {
      ...BasicCfmUserFragment
    }
  }
  ${CofounderPreferenceFragment}
  ${BasicCfmUserFragment}
`;

export const AdminBaseUserFragment = gql`
  fragment AdminBaseUserFragment on User {
    slug
    name
    avatarUrl
    linkedin
    education
    employment
    isTechnical
    location
    impressiveThing
    createdAt
    lastSeenAt
    ycFounderLabel
    age
    track
    company {
      slug
      name
      description
    }
    hopeToLearn
    ideas
  }
`;

export const AdminUserFragment = gql`
  fragment AdminUserFragment on AdminUser {
    ...BaseAdminUserFragment

    badActorReports {
      ...BadActorFragment
    }

    adminUserStub {
      ...AdminUserStubFragment
    }

    user {
      ...AdminBaseUserFragment
    }

    votes {
      ...UserAdminActionFragment
    }

    ycApps {
      id
      batch
      result
      url
    }
  }
  ${BaseAdminUserFragment}
  ${AdminCFMProfileFragment}
  ${AdminBaseUserFragment}
  ${UserAdminActionFragment}
  ${BadActorFragment}
  ${AdminUserStubFragment}
`;

export const AdminUserWithCfmProfileFragment = gql`
  fragment AdminUserWithCfmProfileFragment on AdminUser {
    ...AdminUserFragment

    adminCfmProfile {
      ...AdminCFMProfileFragment
    }
  }
  ${AdminUserFragment}
`;

export const MeetupRsvpFragment = gql`
  fragment MeetupRsvpFragment on MeetupRsvp {
    id
    joinedWaitlistAt
    accepted
    status
    internalUrl
    ycAppHistory @skip(if: $bigEvent) {
      batch
      url
      result
      comments {
        voterName
        rating
        notes
        voteType
        updatedAt
      }
    }
    ycAppBestResult @skip(if: $bigEvent)
    otherRsvpStatuses @skip(if: $bigEvent)
    numWeeklyUpdatesDuringCourse @skip(if: $bigEvent)
    noShow
    checkedIn
    ideaOneLiner
    personalOneLiner
    qrCodeUrl
    numPriorNoShows @skip(if: $bigEvent)
    userHasNotes @skip(if: $bigEvent)
    customQuestions
    questionResponses {
      id
      meetupQuestionId
      response
    }
    referrer @skip(if: $bigEvent) {
      id
      name
      adminUser {
        id
        slug
        adminRating
      }
    }
    inviteToken @skip(if: $bigEvent)
    adminVotes {
      id
      voteType
      val
      comments
      voterName
      updatedAt
    }
    ssoUser {
      id
      uuid
      hnid
      name
      firstName
      lastName
      email
      linkedin
      ycFounder @skip(if: $bigEvent)
      enrichedEducation @skip(if: $bigEvent)
      enrichedEmployment @skip(if: $bigEvent)
      estimatedAge @skip(if: $bigEvent)
      automatedRating
      internalUrl
      monoidCategory @skip(if: $bigEvent)
      ycAlumActive @skip(if: $bigEvent)
      ycAlumCompanyFates @skip(if: $bigEvent)
      ycAlumCompanyScores @skip(if: $bigEvent)
      ycAlumCompanyRaised @skip(if: $bigEvent)
      ycAlumCompanyBatches @skip(if: $bigEvent)
      ycAlumCompanyInternalUrls @skip(if: $bigEvent)
      ycAlumCompanyNames @skip(if: $bigEvent)
      ycAlumPrimaryPartners @skip(if: $bigEvent)
      ycAlumGroupPartners @skip(if: $bigEvent)
      ycAlumCompanyPinnedNotes @skip(if: $bigEvent) {
        category
        url
      }
      pinnedNote @skip(if: $bigEvent) {
        category
        url
      }
      investorFirmInternalUrls @skip(if: $bigEvent)
      investorFirmNames @skip(if: $bigEvent)
      investorTitles @skip(if: $bigEvent)
    }
    adminUser {
      id
      slug
      adminRating
      user {
        slug
        location
        currentCompanyId
        isWoman
        track
        isTechnical
        age @skip(if: $bigEvent)
        company @skip(if: $bigEvent) {
          slug
          name
          description
        }
      }
    }
    cfmProfile @skip(if: $bigEvent) {
      id
      slug
      adminPath
      profile {
        slug
        hasIdea
        responsibilities
        interests
        createdAt
      }
    }
    ohOwner @skip(if: $bigEvent) {
      id
      name
    }
    ohSlot @skip(if: $bigEvent) {
      id
      startsAt
      owner {
        id
        name
      }
      owner2 {
        id
        name
      }
    }
  }
`;

export const CurrentUserFragment = gql`
  fragment CurrentUserFragment on CurrentUser {
    slug
    name
    avatarUrl
    linkedin
    noLinkedin
    education
    employment
    isTechnical
    gender
    birthdate
    age
    location
    country
    region
    impressiveThing
    isWoman
    isYcFounder
    showYcFounder
    schools {
      ...SchoolFragment
    }
    ycFounderLabel
    track
    twitterLink
    instagramLink
    emailSettings
    enrichedUserData {
      education
      employment
    }
    errors {
      field
      error
    }
  }
  ${SchoolFragment}
`;

export const CfmDirectoryProfileFragment = gql`
  fragment CfmDirectoryProfileFragment on CofounderProfile {
    slug
    hasIdea
    ideas
    interests
    intro
    lastSeenAt
    equity
    freeTime
    lifeStory
    other
    reqFreeText
    responsibilities
    timing
    videoLink
    calendlyLink
    ...CofounderPreferenceFragment
    user {
      slug
      name
      firstName
      age
      avatarUrl
      impressiveThing
      education
      employment
      isTechnical
      isWoman
      location
      name
      showYcFounder
      schools {
        ...SchoolFragment
      }
      ycFounderLabel
      country
      region
    }
    company {
      name
      description
      progress
      fundingStatus
    }
  }
  ${CofounderPreferenceFragment}
  ${SchoolFragment}
`;
