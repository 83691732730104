import React, { ReactElement } from "react";
import { CFMProfileFragment } from "./__generated__/CFMProfileFragment";
import {
  COFOUNDER_ADMIN_PROFILE_admin_cofounderMatching_adminProfile,
  COFOUNDER_ADMIN_PROFILE_admin_cofounderMatching_adminProfile_topCandidates,
  COFOUNDER_ADMIN_PROFILE_admin_cofounderMatching_adminProfile_topSimilar_cofounderAdminProfile,
} from "../admin/__generated__/COFOUNDER_ADMIN_PROFILE";
import { COFOUNDER_MATCHING_CANDIDATE_cofounderMatching_profile } from "./__generated__/COFOUNDER_MATCHING_CANDIDATE";
import { CFMViewerProfileFragment } from "./__generated__/CFMViewerProfileFragment";
import { CFM_SPEED_DATING_EVENT_cofounderMatching_profile } from "./speedDating/__generated__/CFM_SPEED_DATING_EVENT";
import {
  CFM_EDIT_PROFILE_cofounderMatching_profile,
  CFM_EDIT_PROFILE_currentUser,
} from "./__generated__/CFM_EDIT_PROFILE";
import { CFM_DIRECTORY_SEARCH_cofounderMatching_directory_search_results_profile } from "./directory/__generated__/CFM_DIRECTORY_SEARCH";
import { COFOUNDER_MATCHING_UNRATED_CANDIDATES_cfmProfileReview_unratedCandidates } from "../admin/__generated__/COFOUNDER_MATCHING_UNRATED_CANDIDATES";
import { formatList } from "../../components/forms/util";
import { ADMIN_USERS_TO_REVIEW_admin_usersToReview_adminCfmProfile } from "../admin/__generated__/ADMIN_USERS_TO_REVIEW";
import { COFOUNDER_MATCHING_TOP_INVITERS_admin_cofounderMatching_topInvitersThisWeek } from "../admin/__generated__/COFOUNDER_MATCHING_TOP_INVITERS";

export type LOCATION_PREFS = "geographic" | "country" | "region";

export type RESPONSIBILITIES =
  | "product"
  | "engineering"
  | "design"
  | "sales_marketing"
  | "operations";

export type INTERESTS =
  | "agriculture"
  | "ai"
  | "arvr"
  | "b2b"
  | "biotech"
  | "blockchain"
  | "climate"
  | "consumer"
  | "devtools"
  | "ecommerce"
  | "edtech"
  | "energy"
  | "entertainment"
  | "fintech"
  | "food"
  | "gaming"
  | "government"
  | "hardware"
  | "hard_tech"
  | "health"
  | "healthcare"
  | "marketplace"
  | "nonprofit"
  | "proptech"
  | "robotics"
  | "security"
  | "travel";

export type TIMINGS = "active" | "ready" | "year" | "passive";

export type HAS_IDEA = "committed" | "open" | "none";

export type IMPORTANCE = "ignore" | "prefer" | "require";

export type ProfileType = {
  intro: string;
  video_link: string;
  has_idea: HAS_IDEA;
  timing: TIMINGS;
  education: string;
  employment: string;
  responsibilities: RESPONSIBILITIES[];
  other: string;
  slug: string;
  cf_is_technical: boolean;
  cf_has_idea: boolean;
  req_free_text: string;
  cf_age: string;
  cf_age_importance: IMPORTANCE;
  cf_location: LOCATION_PREFS;
  cf_responsibilities: RESPONSIBILITIES[];
  cf_timing_importance: IMPORTANCE;
  cf_interests_importance: IMPORTANCE;
  cf_is_woman_importance: IMPORTANCE;
  cf_is_yc_alum_importance: IMPORTANCE;
  cf_is_fellow_alumni_importance: IMPORTANCE;
  active: boolean;
  inactive_reason: string;
  email_pref: string;
  user: {
    name: string;
    avatar_url: string;
    linkedin: string;
    is_technical: boolean;
    location: string;
    is_woman: boolean;
    show_yc_founder: boolean;
    impressive_thing: string;
  };
  company: {
    name: string;
    url: string | null;
    description: string;
  } | null;
};

export type AnyAdminProfile =
  | COFOUNDER_ADMIN_PROFILE_admin_cofounderMatching_adminProfile
  | COFOUNDER_ADMIN_PROFILE_admin_cofounderMatching_adminProfile_topCandidates
  | ADMIN_USERS_TO_REVIEW_admin_usersToReview_adminCfmProfile
  | COFOUNDER_MATCHING_TOP_INVITERS_admin_cofounderMatching_topInvitersThisWeek
  | COFOUNDER_ADMIN_PROFILE_admin_cofounderMatching_adminProfile_topSimilar_cofounderAdminProfile
  | COFOUNDER_MATCHING_UNRATED_CANDIDATES_cfmProfileReview_unratedCandidates;

export type ViewerProfile =
  | COFOUNDER_MATCHING_CANDIDATE_cofounderMatching_profile
  | CFM_SPEED_DATING_EVENT_cofounderMatching_profile
  | CFMViewerProfileFragment;

export type FullCandidateProfile = CFMProfileFragment | CFM_EDIT_PROFILE_cofounderMatching_profile;

export type CompactCandidateProfile =
  CFM_DIRECTORY_SEARCH_cofounderMatching_directory_search_results_profile;

export type AnyCFMCandidateProfile = FullCandidateProfile | CompactCandidateProfile;

export type AnyCFMProfile =
  | ViewerProfile
  | FullCandidateProfile
  | CompactCandidateProfile
  | AnyAdminProfile["profile"];

const PREFER_SUFFIX = "but it's not required";

export const ImportanceText = new Map<IMPORTANCE, string>([
  ["ignore", "No preference"],
  ["prefer", "I prefer"],
  ["require", "I only want"],
]);

export const SpecificIdeaText = new Map<ProfileType["cf_has_idea"], string>([
  [true, "I want to see co-founders who have a specific idea"],
  [false, "I want to see co-founders who are not set on a specific idea"],
]);

export const getHasIdeaText = (profile: AnyCFMProfile): string =>
  SpecificIdeaText.get(profile.cfHasIdea as boolean)!;

export const TechnicalText = new Map<ProfileType["cf_is_technical"], string>([
  [true, "Technical"],
  [false, "Non-technical"],
]);

export const getIsTechnicalText = (profile: AnyCFMProfile): string =>
  TechnicalText.get(profile.cfIsTechnical as boolean)!;

export const LocationText = new Map<ProfileType["cf_location"], string>([
  ["geographic", "Within a certain distance of me"],
  ["country", "In my country"],
  ["region", "In my region"],
]);

export const getAgeOptions = () =>
  new Map<ProfileType["cf_age"], string>([["range", "Within a certain age range"]]);

export const getLocationOptions = (currentUser: CFM_EDIT_PROFILE_currentUser | null | undefined) =>
  new Map<ProfileType["cf_location"], string | React.ReactElement>([
    ["geographic", "Within a certain distance of me"],
    [
      "country",
      currentUser?.country ? (
        <p>
          In my country <i>({currentUser.country})</i>
        </p>
      ) : (
        "In my country"
      ),
    ],
    [
      "region",
      currentUser?.region ? (
        <p>
          In my region <i>({currentUser.region})</i>
        </p>
      ) : (
        "In my region"
      ),
    ],
  ]);

export const convertKmToMiles = (km: number): number => Math.round(km / 1.609);

export const getLocationText = (profile: AnyCFMProfile): string => {
  if (profile.cfLocation === "geographic") {
    if (profile.user.country === "United States") {
      return `Within ${convertKmToMiles(profile.cfLocationKmRange)}mi of me`;
    }
    return `Within ${profile.cfLocationKmRange}km of me`;
  }
  if (profile.cfLocation === "region" && profile.user.region) {
    return `In my region (${profile.user.region})`;
  }
  if (profile.cfLocation === "country" && profile.user.country) {
    return `In my country (${profile.user.country})`;
  }
  return LocationText.get(profile.cfLocation as LOCATION_PREFS)!;
};

export const getLocationProfileText = (profile: AnyCFMProfile): ReactElement => {
  if (profile.cfLocation === "geographic") {
    if (profile.user.country === "United States") {
      return (
        <span key="miRange">
          within <b>{convertKmToMiles(profile.cfLocationKmRange)}mi</b> of me
        </span>
      );
    }
    return (
      <span key={profile.cfLocation}>
        within <b>{profile.cfLocationKmRange}km</b> of me
      </span>
    );
  }
  if (profile.cfLocation === "region" && profile.user.region) {
    return (
      <span key={profile.cfLocation}>
        in my region (<b>{profile.user.region}</b>)
      </span>
    );
  }
  if (profile.cfLocation === "country" && profile.user.country) {
    return (
      <span key={profile.cfLocation}>
        in my country (<b>{profile.user.country}</b>)
      </span>
    );
  }
  return <span />;
};

export const TimingText = new Map<ProfileType["timing"], string>([
  ["active", "I'm already full-time on my startup"],
  ["ready", "I'm ready to go full-time as soon as I meet the right co-founder"],
  ["year", "I'm planning to go full-time in the next year"],
  ["passive", "I don't have any specific plans yet"],
]);

export const TimingShortText = new Map<ProfileType["timing"], string>([
  ["active", "already full-time on your startup"],
  ["ready", "ready within three months"],
  ["year", "ready within a year"],
  ["passive", "passively looking"],
]);

export const TimingProfileText = new Map<ProfileType["timing"], React.ReactElement>([
  [
    "active",
    <span key="timing-active">
      already <b>full-time</b> working on a startup
    </span>,
  ],
  [
    "ready",
    <span key="timing-ready">
      ready within <b>three months</b>
    </span>,
  ],
  [
    "year",
    <span key="timing-year">
      ready within a <b>year</b>
    </span>,
  ],
  ["passive", <b key="timing-passive">passively looking</b>],
]);

export const HasIdeaText = new Map<ProfileType["has_idea"], string>([
  ["committed", "Yes, I'm committed to an idea and I want a co-founder who can help me build it"],
  ["open", "I have some ideas, but I'm also open to exploring other ideas"],
  ["none", "No, I could help a co-founder with their existing idea or explore new ideas together"],
]);

export const HasIdeaShortText = new Map<ProfileType["has_idea"], string | React.ReactElement>([
  [
    "committed",
    <span>
      <b>committed</b> to an idea
    </span>,
  ],
  [
    "open",
    <span>
      <b>have some ideas</b>, but I'm also open to exploring other ideas
    </span>,
  ],
  [
    "none",
    <span>
      could help a co-founder with their existing idea or <b>explore new ideas together</b>
    </span>,
  ],
]);

export const getTimingText = (profile: AnyCFMProfile): string => {
  if (profile.timing === "active") {
    return "Co-founders who are already full-time on their startup";
  }
  return `Co-founders who are ${TimingShortText.get(profile.timing as TIMINGS)!}`;
};

export const getTimingImportanceOptions = (timing: TIMINGS | null) =>
  new Map<ProfileType["cf_timing_importance"], string>([
    ["require", "I only want to see co-founders who match my timing"],
    ["prefer", `I prefer to see co-founders who match my timing, ${PREFER_SUFFIX}`],
    ["ignore", "No preference"],
  ]);

export const InterestsImportanceText = new Map<ProfileType["cf_interests_importance"], string>([
  ["require", "I only want to match with co-founders who share my interests"],
  ["prefer", `I prefer to match with co-founders who share my interests, ${PREFER_SUFFIX}`],
  ["ignore", "No preference"],
]);

export const WomanImportanceText = new Map<ProfileType["cf_is_woman_importance"], string>([
  ["require", "I only want to match with other women"],
  ["prefer", `I prefer to match with other women, ${PREFER_SUFFIX}`],
  ["ignore", "No preference"],
]);

export const getWomenText = (profile: AnyCFMProfile): string => {
  if (!("cfIsWomanImportance" in profile)) {
    return "";
  }

  const importance = profile.cfIsWomanImportance as IMPORTANCE;
  const importanceText = ImportanceText.get(importance);
  if (importance === "ignore") {
    return importanceText!;
  }

  return `${importanceText} to be matched with other women`;
};

export const YcAlumImportanceText = new Map<ProfileType["cf_is_yc_alum_importance"], string>([
  ["prefer", "I prefer to match with other YC alums"],
  ["ignore", "No preference"],
]);

export const getYcAlumText = (profile: AnyCFMProfile): string => {
  if (!("cfIsYcAlumImportance" in profile)) {
    return "";
  }

  const importance = profile.cfIsYcAlumImportance as IMPORTANCE;
  const importanceText = ImportanceText.get(importance);
  if (importance === "ignore") {
    return importanceText!;
  }

  return `${importanceText} to be matched with other YC alums`;
};

export const FellowAlumniImportanceText = new Map<
  ProfileType["cf_is_fellow_alumni_importance"],
  string
>([
  ["require", "I only want to match with fellow students and alumni"],
  ["prefer", `I prefer to match with fellow students and alumni, ${PREFER_SUFFIX}`],
  ["ignore", "No preference"],
]);

export const getFellowAlumniText = (profile: AnyCFMProfile): string => {
  if (!("cfIsFellowAlumniImportance" in profile) || !profile.user.schools.length) {
    return "";
  }

  const importance = profile.cfIsFellowAlumniImportance as IMPORTANCE;
  const importanceText = ImportanceText.get(importance);
  if (importance === "ignore") {
    return importanceText!;
  }

  const schoolText = formatList(profile.user.schools.map(({ title }) => title));
  return `${importanceText} to be matched with other fellow students and alumni from ${schoolText}`;
};

export const ResponsibilitiesText = new Map<RESPONSIBILITIES, string>([
  ["product", "Product"],
  ["engineering", "Engineering"],
  ["design", "Design"],
  ["sales_marketing", "Sales and marketing"],
  ["operations", "Operations"],
]);

export const InterestsText = new Map<INTERESTS, string>([
  ["agriculture", "Agriculture / Agtech"],
  ["ai", "Artificial Intelligence"],
  ["arvr", "Augmented Reality / Virtual Reality"],
  ["b2b", "B2B / Enterprise"],
  ["biotech", "Biomedical / Biotech"],
  ["blockchain", "Blockchain"],
  ["climate", "Climate / Sustainability"],
  ["consumer", "Consumer"],
  ["ecommerce", "E-Commerce"],
  ["devtools", "Developer Tools"],
  ["edtech", "Education / Edtech"],
  ["energy", "Energy"],
  ["entertainment", "Entertainment"],
  ["fintech", "Financial / Fintech"],
  ["food", "Food / Beverage"],
  ["gaming", "Gaming"],
  ["government", "Government"],
  ["hardware", "Hardware"],
  ["hard_tech", "Hard Tech"],
  ["health", "Health / Wellness"],
  ["healthcare", "Healthcare"],
  ["marketplace", "Marketplace"],
  ["nonprofit", "Non-Profit"],
  ["proptech", "Real Estate / Proptech"],
  ["robotics", "Robotics"],
  ["security", "Security"],
  ["travel", "Travel / Tourism"],
]);

const BooleanTextMap = new Map<Boolean, string>([
  [true, "Yes"],
  [false, "No"],
]);

export const reqPrefs = {
  specificIdea: BooleanTextMap,
  techOnly: BooleanTextMap,
  hasCf: BooleanTextMap,
  responsibilities: ResponsibilitiesText,
  interests: InterestsText,
  timings: TimingText,
  hasIdea: HasIdeaText,
  interestsImportance: InterestsImportanceText,
  women: WomanImportanceText,
  ycAlum: YcAlumImportanceText,
  fellowAlumni: FellowAlumniImportanceText,
};

export const IMPORTANCE_PROMPT_TEXT = new Map<IMPORTANCE, string>([
  ["ignore", "Not important"],
  ["prefer", "Preferred but not required"],
  ["require", "Required"],
]);

export const importanceDropdownText = new Map<IMPORTANCE, string>([
  ["prefer", IMPORTANCE_PROMPT_TEXT.get("prefer") || ""],
  ["require", IMPORTANCE_PROMPT_TEXT.get("require") || ""],
]);

export const fullImportanceDropdownText = IMPORTANCE_PROMPT_TEXT;
