import React from "react";
import { gql, useMutation } from "@apollo/client";
import { css } from "@emotion/core";
import { AnyCFMProfile } from "./profileFields";
import {
  CFM_CLICK_LINKEDIN,
  CFM_CLICK_LINKEDINVariables,
} from "./__generated__/CFM_CLICK_LINKEDIN";
import LinkButton from "../../components/statelessForms/LinkButton";

type Props = {
  profile: AnyCFMProfile;
};

export default ({ profile }: Props) => {
  const [clickLinkedin] = useMutation<CFM_CLICK_LINKEDIN, CFM_CLICK_LINKEDINVariables>(gql`
    mutation CFM_CLICK_LINKEDIN($slug: ID!) {
      cfmClickLinkedin(slug: $slug) {
        success
      }
    }
  `);

  const onLinkedinClick = async () => {
    await clickLinkedin({
      variables: { slug: profile.user.slug },
    });
  };

  if (!("linkedin" in profile.user) || !profile.user.linkedin) {
    return null;
  }

  return (
    <div css={css({ marginTop: 20, fontStyle: "italic" })}>
      <LinkButton
        externalUrl
        href={profile.user.linkedin as string}
        onClick={onLinkedinClick}
        openInNew
      >
        <a>View on LinkedIn</a>
      </LinkButton>
    </div>
  );
};
