import { css } from "@emotion/core";
import { formatDistance, isToday } from "date-fns";
import React from "react";
import styled from "@emotion/styled";
import { CakeOutlined, Share, SchoolOutlined } from "@material-ui/icons";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { toast } from "react-toastify";
import { SocialIcon } from "react-social-icons";
import isValidUrl from "@yc/shared/utils/isValidUrl";
import { CFMProfileFragment } from "./__generated__/CFMProfileFragment";
import { INTERESTS, RESPONSIBILITIES, reqPrefs, CompactCandidateProfile } from "./profileFields";
import { CFM_DIRECTORY_SEARCH_cofounderMatching_directory_search_results_highlights } from "./directory/__generated__/CFM_DIRECTORY_SEARCH";
import { CFMViewerProfileFragment } from "./__generated__/CFMViewerProfileFragment";
import { Attribute, AttributeGroup, ItemList } from "./CFMAttributes";
import { DesktopOnly, MobileOnly, mobileStyles } from "../../styles";
import { candidateCofounderMatchingPath } from "../../__generated__/routes";
import { YC_ORANGE, linkified } from "../../components/forms/util";
import {
  HeaderDiv,
  fymkBanner,
  generateHeaderListCSS,
  generateSummaryParagraph,
  prefilledCalendarLink,
  ProfileYoutubeVideo,
  highlightify,
  TextList,
} from "./CFMProfile";
import { Pane, PaneItem } from "../../components/Pane";
import { CurrentContextType } from "./CFMCandidateActionBox";
import Icon from "../../components/icon";
import LinkedinLink from "./LinkedinLink";

type HighlightField =
  keyof CFM_DIRECTORY_SEARCH_cofounderMatching_directory_search_results_highlights;

type Props = {
  profile: CFMProfileFragment | CompactCandidateProfile;
  message?: string;
  viewer?: CFMViewerProfileFragment | null;
  showProfileLink?: boolean;
  hideLinkedin?: boolean;
  hideShareableLink?: boolean;
  highlights?: CFM_DIRECTORY_SEARCH_cofounderMatching_directory_search_results_highlights;
  smallAvatar?: boolean;
  actionBox?: React.ReactElement;
  context?: CurrentContextType;
  footer?: React.ReactElement;
};

const P = styled.p({
  fontWeight: 300,
  ...mobileStyles({
    marginBottom: 0,
    display: "flex",
    justifyContent: "center",
  }),
});

const CalendlyBanner = styled.a`
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 5px;
  background-color: #fb651e;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 4px;
  margin: 10px 0;

  &:hover {
    cursor: pointer;
    color: white;
  }
`;

const HeaderListItem = styled.span`
  margin: 3px 0;
`;

const ShareButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: transparent;
  color: #268bd2;
  font-size: 14px;

  &:hover:enabled {
    cursor: pointer;
    color: #0b7ac8;

    &:active {
      color: #07558c;
    }
  }

  svg {
    margin-right: 3px;
  }
`;

export default ({
  profile,
  viewer,
  highlights,
  actionBox,
  showProfileLink,
  hideShareableLink,
  hideLinkedin,
  smallAvatar,
  message,
  context,
  footer,
}: Props) => {
  const lastSeenDateObj = new Date(profile.lastSeenAt);
  const lastSeenDateStr = isToday(lastSeenDateObj)
    ? "today"
    : formatDistance(lastSeenDateObj, new Date(), { addSuffix: true });

  const headerListItems = [];
  if (showProfileLink) {
    headerListItems.push([
      "profileLink",
      <HeaderListItem>
        <a
          css={css({
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            svg: { marginRight: 5 },
          })}
          target="_blank"
          href={
            context === "directory"
              ? `${candidateCofounderMatchingPath(profile.slug)}?fromDirectory=true`
              : candidateCofounderMatchingPath(profile.slug)
          }
        >
          <OpenInNewIcon />
          Profile
        </a>
      </HeaderListItem>,
    ]);
  }

  if (profile.user.age) {
    headerListItems.push([
      "age",
      <HeaderDiv>
        <CakeOutlined />
        <HeaderListItem>{profile.user.age}</HeaderListItem>
      </HeaderDiv>,
    ]);
  }

  if (
    "ycFounderLabel" in profile.user &&
    profile.user.showYcFounder &&
    profile.user.ycFounderLabel
  ) {
    headerListItems.push([
      "ycFounder",
      <HeaderDiv>
        <Icon
          icon="ycY"
          css={css({
            height: 20,
            width: 20,
          })}
        />
        <HeaderListItem css={generateHeaderListCSS(YC_ORANGE, "white", 12)}>
          {profile.user.ycFounderLabel}
        </HeaderListItem>
      </HeaderDiv>,
    ]);
  }

  profile.user.schools.forEach((school) => {
    headerListItems.push([
      school.name,
      <HeaderDiv>
        <SchoolOutlined />
        <HeaderListItem css={generateHeaderListCSS(school.colorPrimary, school.colorSecondary, 12)}>
          {school.title}
        </HeaderListItem>
      </HeaderDiv>,
    ]);
  });

  if (profile.videoLink) {
    headerListItems.push([
      "videoLink",
      <HeaderListItem>
        <a href={profile.videoLink} target="_blank">
          Video
        </a>
      </HeaderListItem>,
    ]);
  }

  const onCopyLink = async () => {
    const url = `${window.location.origin}${candidateCofounderMatchingPath(profile.slug)}`;
    await navigator.clipboard?.writeText(url);
    toast.info("Link copied!");
  };

  const renderHighlight = (field: HighlightField): React.ReactElement | null => {
    if (highlights && highlights[field]) {
      return highlightify(highlights[field] || "");
    }
    return null;
  };

  const { company } = profile;
  const highlightedCompanyName = renderHighlight("companyName");
  let avatarSize = 150;
  if (smallAvatar) {
    avatarSize = 50;
  } else {
    avatarSize = 120;
  }

  return (
    <div
      css={css({
        width: "100%",
        maxWidth: 800,
        overflowWrap: "anywhere",
        padding: 20,
        mark: {
          fontWeight: 600,
        },
      })}
    >
      {fymkBanner(profile, true)}

      <div
        css={css({
          display: "flex",
          alignItems: "flex-start",
          marginBottom: 20,
          ...mobileStyles({
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
          }),
        })}
      >
        <MobileOnly>
          {actionBox && <div css={css({ marginLeft: "auto", marginBottom: 10 })}>{actionBox}</div>}
        </MobileOnly>
        <img
          key={profile.user.avatarUrl}
          css={css({
            borderRadius: "50%",
            marginRight: 20,
            backgroundColor: "#ccc",
            ...mobileStyles({ marginRight: 0 }),
          })}
          src={profile.user.avatarUrl}
          height={avatarSize}
          width={avatarSize}
          alt="candidate avatar"
        />
        <div
          css={css({
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          })}
        >
          <div
            css={css({
              display: "flex",
              ...mobileStyles({ flexDirection: "column", marginBottom: 5 }),
            })}
          >
            <h1 css={css({ fontSize: 30, marginBottom: 0 })}>{profile.user.name}</h1>

            <div
              css={css({
                display: "flex",
                marginLeft: "auto",
                gap: 7,
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                ...mobileStyles({ marginLeft: 0 }),
              })}
            >
              {"twitterLink" in profile.user &&
                profile.user.twitterLink &&
                isValidUrl(profile.user.twitterLink) && (
                  <SocialIcon
                    url={profile.user.twitterLink as string}
                    network="twitter"
                    style={{ height: 35, width: 35 }}
                  />
                )}
              {"instagramLink" in profile.user &&
                profile.user.instagramLink &&
                isValidUrl(profile.user.instagramLink) && (
                  <SocialIcon
                    url={profile.user.instagramLink as string}
                    network="instagram"
                    style={{ height: 35, width: 35 }}
                  />
                )}
              {!hideShareableLink && (
                <P>
                  <HeaderListItem>
                    <ShareButton onClick={onCopyLink}>
                      <Share />
                    </ShareButton>
                  </HeaderListItem>
                </P>
              )}
            </div>
          </div>

          <P css={css({ marginBottom: 6 })}>
            <span
              css={css({
                color: "#121212",
                opacity: 0.34,
                fontSize: 13,
                fontWeight: 500,
              })}
            >
              {`Last active ${lastSeenDateStr}`}
            </span>
          </P>

          <P css={css({ marginBottom: 6 })}>{profile.user.location}</P>

          <P
            css={css({
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              ...mobileStyles({ marginTop: 10 }),
            })}
          >
            {headerListItems.map(([key, element]: [key: string, element: JSX.Element], index) => (
              <React.Fragment key={key}>
                {index !== 0 && <span css={css({ margin: "0 5px" })} />}
                {element}
              </React.Fragment>
            ))}
          </P>
        </div>
        <DesktopOnly>
          {actionBox && <div css={css({ marginLeft: "auto" })}>{actionBox}</div>}
        </DesktopOnly>
      </div>
      {profile.calendlyLink && (
        <CalendlyBanner target="_blank" href={prefilledCalendarLink(profile.calendlyLink, viewer)}>
          Schedule a call
        </CalendlyBanner>
      )}
      {message && (
        <div
          css={css({
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: 6,
            padding: 20,
          })}
        >
          <div css={css({ marginBottom: 5 })}>
            <i>Message</i>
          </div>
          <p css={css({ fontSize: 16 })}>{message}</p>
        </div>
      )}
      <div css={css({ margin: "auto auto 20px" })}>
        <ProfileYoutubeVideo name={profile.user.name} link={profile.videoLink} />
      </div>
      <div css={css({ paddingBottom: 20 })}>{generateSummaryParagraph(profile)}</div>

      <AttributeGroup>
        <Attribute
          block
          title="Intro"
          value={renderHighlight("intro") || linkified(profile.intro)}
        />
        {profile.hasIdea !== "committed" && !!profile.ideas && (
          <Attribute
            title="Potential ideas"
            value={renderHighlight("ideas") || linkified(profile.ideas)}
            block
          />
        )}
        {profile.hasIdea === "committed" && !!company && (
          <Attribute
            block
            value={
              <Pane
                title={highlightedCompanyName ? undefined : company.name}
                header={highlightedCompanyName || undefined}
                noMarginBottom
              >
                <PaneItem>
                  <AttributeGroup padding={20}>
                    <Attribute
                      value={
                        renderHighlight("companyDescription") || linkified(company.description)
                      }
                      block
                    />
                    {!!company.progress?.trim() && (
                      <Attribute
                        title="Progress"
                        value={renderHighlight("companyProgress") || linkified(company.progress)}
                        block
                      />
                    )}
                    {!!company.fundingStatus && (
                      <Attribute
                        title="Funding Status"
                        value={
                          renderHighlight("companyFunding") || linkified(company.fundingStatus)
                        }
                        block
                      />
                    )}
                  </AttributeGroup>
                </PaneItem>
              </Pane>
            }
          />
        )}
        <Attribute
          title="Impressive accomplishment"
          block
          value={
            renderHighlight("impressiveThing") || linkified(profile.user.impressiveThing ?? "")
          }
        />
        <Attribute
          title="Education"
          block
          value={
            <TextList
              text={
                highlights && highlights.education ? highlights.education : profile.user.education
              }
            />
          }
        />
        <Attribute
          title="Employment"
          block
          value={
            <TextList
              text={
                highlights && highlights.employment
                  ? highlights.employment
                  : profile.user.employment
              }
            />
          }
        />
        {!!profile.responsibilities.length && (
          <Attribute
            title="Responsibilities"
            value={
              <ItemList
                items={profile.responsibilities}
                highlight={viewer?.cfResponsibilities}
                label={(r: string) => reqPrefs.responsibilities.get(r as RESPONSIBILITIES)!}
              />
            }
          />
        )}
        {!!profile.interests.length && (
          <Attribute
            title="Interests"
            value={
              <ItemList
                items={profile.interests}
                highlight={viewer?.interests}
                label={(i: string) => reqPrefs.interests.get(i as INTERESTS)!}
              />
            }
          />
        )}
        {!!profile.equity && (
          <Attribute
            block
            title="Equity expectations"
            value={renderHighlight("equity") || linkified(profile.equity)}
          />
        )}
        {!!profile.lifeStory && (
          <Attribute
            block
            title="Life Story"
            value={renderHighlight("lifeStory") || linkified(profile.lifeStory)}
          />
        )}
        {!!profile.freeTime && (
          <Attribute
            block
            title="Free Time"
            value={renderHighlight("freeTime") || linkified(profile.freeTime)}
          />
        )}
        {!!profile.other && (
          <Attribute
            block
            title="Other"
            value={renderHighlight("other") || linkified(profile.other)}
          />
        )}
        {"reqFreeText" in profile && !!profile.reqFreeText && (
          <Attribute
            block
            title="Ideal co-founder"
            value={renderHighlight("reqFreeText") || linkified(profile.reqFreeText)}
          />
        )}
      </AttributeGroup>

      <LinkedinLink profile={profile} />

      {footer}
    </div>
  );
};
