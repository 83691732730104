import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { gql, useMutation } from "@apollo/client";
import Button from "../../components/statelessForms/Button";
import {
  UPDATE_ATTENDING_EVENT,
  UPDATE_ATTENDING_EVENTVariables,
} from "./__generated__/UPDATE_ATTENDING_EVENT";

const RsvpSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    color: ${({ attending }: { attending: boolean }) => (attending ? "#7bcf56" : "gray")};
    font-weight: bold;
  }
  a {
    color: gray;
    font-size: 14px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const RsvpSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  button > span {
    color: white;
  }
  button:not(:last-child) {
    margin: 0 10px;
  }
`;

type Props = {
  slug: string;
  isAttending: boolean | null;
  reloadEvent: () => any;
};

export default ({ slug, isAttending, reloadEvent }: Props) => {
  const [editingRsvp, setEditingRsvp] = useState<boolean | undefined>(undefined);
  const [updateAttending] = useMutation<
    UPDATE_ATTENDING_EVENT,
    UPDATE_ATTENDING_EVENTVariables
  >(gql`
    mutation UPDATE_ATTENDING_EVENT($slug: ID!, $attending: Boolean!) {
      updateAttendingSpeedDatingEvent(slug: $slug, attending: $attending) {
        slug
        attending
      }
    }
  `);

  useEffect(() => {
    if (editingRsvp === undefined) {
      setEditingRsvp(isAttending === null);
    }
  }, [isAttending]);

  const rsvp = async (attending: boolean) => {
    const variables = { slug, attending };
    await updateAttending({ variables });
    await reloadEvent();
    setEditingRsvp(false);
  };

  return (
    <RsvpSection attending={!!isAttending}>
      {isAttending === null ? (
        <span>RSVP here!</span>
      ) : (
        <>
          {isAttending ? (
            <span>You are registered for this event!</span>
          ) : (
            <span>You've said you can't make this event.</span>
          )}
        </>
      )}
      {editingRsvp ? (
        <RsvpSelection>
          <div>Do you plan to attend this event?</div>
          <Button color="orange" onClick={() => rsvp(true)}>
            Yes
          </Button>
          <Button color="orange" onClick={() => rsvp(false)}>
            No
          </Button>
        </RsvpSelection>
      ) : (
        <a onClick={() => setEditingRsvp(true)}>Need to change your RSVP? Click here.</a>
      )}
    </RsvpSection>
  );
};
