import { gql, useMutation } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import Button from "../../components/statelessForms/Button";

type Props = {
  slug: string;
  refetch?: () => any;
};

export default ({ slug, refetch }: Props) => {
  const [flagForAdminReview] = useMutation(gql`
    mutation FLAG_FOR_ADMIN_REVIEW($slug: ID!) {
      flagForAdminReview(slug: $slug) {
        slug
        needsAdminReview
      }
    }
  `);

  const onClick = async () => {
    await flagForAdminReview({ variables: { slug } });
    if (refetch) refetch();
  };

  return (
    <div css={css({ marginBottom: 15 })}>
      <Button onClick={onClick}>Flag for admin review</Button>
    </div>
  );
};
