import React from "react";
import { Theme, Tooltip, withStyles } from "@material-ui/core";
import { TooltipProps } from "@material-ui/core/Tooltip/Tooltip";

const StyledTooltip = withStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.white,
    "&:before": {
      border: "1px solid #D4D4D5",
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    padding: "0.8em 1em",
    border: "1px solid #D4D4D5",
    boxShadow: "0 1px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%)",
    fontSize: 16,
    minWidth: "fit-content",
    maxWidth: 250,
    fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    fontWeight: 400,
  },
}))(Tooltip);

export default (props: TooltipProps) => <StyledTooltip placement="top" arrow {...props} />;
