import React from "react";
import { css } from "@emotion/core";
import { unsuppressEmailUsersPath } from "../../__generated__/routes";
import InfoCard from "../../components/InfoCard";
import Button from "../../components/statelessForms/Button";

type Props = {
  email: string;
  suppression: { reason: string; type: string | null };
};

export default ({ email, suppression }: Props) => {
  const unsuppressPath = unsuppressEmailUsersPath({
    type: suppression.type,
    continue_url: `${window.location.pathname}${window.location.search}`,
  });

  return (
    <InfoCard
      type="negative"
      header="You are not receiving emails!"
      customCss={css({ maxWidth: 725, textAlign: "center" })}
    >
      <p>
        Your email address {email} has been added to our blocklist because {suppression.reason}. If
        you'd like to start getting YC & Startup School emails again, please click{" "}
        <a href={unsuppressPath}>here</a>.
      </p>
      <div css={css({ textAlign: "center" })}>
        <Button color="orange" href={unsuppressPath} fullWidth>
          Unblock YC
        </Button>
      </div>
    </InfoCard>
  );
};
