import { gql, useLazyQuery } from "@apollo/client";
import { SPEED_DATING_EVENT } from "./__generated__/SPEED_DATING_EVENT";

export default (slug: string | undefined, options: any = {}) =>
  useLazyQuery<SPEED_DATING_EVENT>(
    gql`
      query SPEED_DATING_EVENT($slug: ID!) {
        speedDatingEvent(slug: $slug) {
          slug
          attendeeKey
          dailyUserToken
          meetingMinutes
          title
          eventState
          startsAt
          endsAt
          icebreakers
          attending
          attended
          eventType
          currentMeeting {
            endsAt
          }
        }
        currentUser {
          slug
          name
          avatarUrl
          adult
          cfmProfileExists
        }
      }
    `,
    { variables: { slug }, ...options }
  );
