import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { css } from "@emotion/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "@material-ui/core";
import { profileAdminCofounderMatchingPath } from "../../__generated__/routes";
import CFMProfile from "../cofounderMatching/CFMProfile";
import {
  AttributeGroup,
  Attribute,
  StyledValue,
  ItemList,
} from "../cofounderMatching/CFMAttributes";
import { BasicButton } from "../../components/statelessForms/Button";

import {
  CFM_DEACTIVATE_PROFILE,
  CFM_DEACTIVATE_PROFILEVariables,
} from "./__generated__/CFM_DEACTIVATE_PROFILE";
import {
  CFM_UNCOMMIT_FROM_CF,
  CFM_UNCOMMIT_FROM_CFVariables,
} from "./__generated__/CFM_UNCOMMIT_FROM_CF";
import {
  ADMIN_USERS_TO_REVIEW_admin_usersToReview_adminCfmProfile_votes,
  ADMIN_USERS_TO_REVIEW_admin_usersToReview_votes,
} from "./__generated__/ADMIN_USERS_TO_REVIEW";
import LoadingDots from "../../components/statelessForms/LoadingDots";
import {
  ADMIN_LOAD_REPORT_CONVERSATIONS,
  ADMIN_LOAD_REPORT_CONVERSATIONS_admin_cofounderMatching_adminProfile_reports,
  ADMIN_LOAD_REPORT_CONVERSATIONS_admin_cofounderMatching_adminProfile_reports_conversation,
} from "./__generated__/ADMIN_LOAD_REPORT_CONVERSATIONS";
import {
  CFM_CONTACT_AS_PARTNER,
  CFM_CONTACT_AS_PARTNERVariables,
} from "./__generated__/CFM_CONTACT_AS_PARTNER";
import AdminActions from "./AdminActions";
import UserYcAppsList from "./UserYcAppsList";
import AdminUserProfileLinks from "./AdminUserProfileLinks";
import InvitesList from "./InvitesList";
import MessagesList from "./MessagesList";
import DirectorySearches from "./DirectorySearches";
import {
  SET_WEEKLY_INVITE_LIMIT,
  SET_WEEKLY_INVITE_LIMITVariables,
} from "./__generated__/SET_WEEKLY_INVITE_LIMIT";
import Input from "../../components/statelessForms/Input";
import { Pane, PaneItem } from "../../components/Pane";
import NewBadActorReportModal from "./NewBadActorReportModal";
import FlagForAdminReview from "./FlagForAdminReview";
import EnrichedUserData from "./EnrichedUserData";
import { AnyAdminProfile } from "../cofounderMatching/profileFields";

type VoteProps = {
  isSelected?: boolean;
  color: string;
};
export const VoteButton = styled.button<VoteProps>`
  background-color: ${(props) => (props.isSelected ? props.color : "white")};
  color: ${(props) => (props.isSelected ? "white" : props.color)};
  font-weight: ${(props) => (props.isSelected ? "bold" : "regular")};
  border: 1px solid ${(props) => props.color};
  border-radius: 4px;
  padding: 8px 15px;
  margin: 2px;
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  candidate: AnyAdminProfile;
  superAdmin?: boolean;
  allVotes?:
    | ADMIN_USERS_TO_REVIEW_admin_usersToReview_adminCfmProfile_votes[]
    | ADMIN_USERS_TO_REVIEW_admin_usersToReview_votes[];
  toastOnVote?: boolean;
  shouldLoadEngagementStats?: boolean;
  refetch?: () => void;
};

export default ({
  candidate,
  superAdmin,
  allVotes,
  toastOnVote,
  shouldLoadEngagementStats,
  refetch,
}: Props) => {
  const { profile, partnerContact, partnerContactedAt } = candidate;
  const { user } = profile;

  const [selectedReportId, setSelectedReportId] = useState<string | null>(null);
  const [newWeeklyInviteLimit, setNewWeeklyInviteLimit] = useState<number | undefined>();

  const [deactivateProfile] = useMutation<
    CFM_DEACTIVATE_PROFILE,
    CFM_DEACTIVATE_PROFILEVariables
  >(gql`
    mutation CFM_DEACTIVATE_PROFILE($slug: ID!) {
      cfmDeactivateProfile(slug: $slug) {
        slug
        active
      }
    }
  `);

  const [uncommitFromCf] = useMutation<CFM_UNCOMMIT_FROM_CF, CFM_UNCOMMIT_FROM_CFVariables>(gql`
    mutation CFM_UNCOMMIT_FROM_CF($slug: ID!, $otherProfileId: ID!) {
      cfmUncommittedByAdmin(slug: $slug, otherProfileId: $otherProfileId) {
        slug
        committedCofounderIds
      }
    }
  `);

  const [contactAsPartner] = useMutation<
    CFM_CONTACT_AS_PARTNER,
    CFM_CONTACT_AS_PARTNERVariables
  >(gql`
    mutation CFM_CONTACT_AS_PARTNER($profileSlug: ID!) {
      contactAsPartner(profileSlug: $profileSlug) {
        slug
        partnerContact
        partnerContactedAt
      }
    }
  `);

  const [setWeeklyInviteLimit] = useMutation<
    SET_WEEKLY_INVITE_LIMIT,
    SET_WEEKLY_INVITE_LIMITVariables
  >(gql`
    mutation SET_WEEKLY_INVITE_LIMIT($slug: ID!, $weeklyInviteLimit: Int) {
      adminSetCustomWeeklyInviteLimit(slug: $slug, weeklyInviteLimit: $weeklyInviteLimit) {
        slug
        weeklyInviteLimit
      }
    }
  `);

  const [fetchConversationData, { data: conversationData, loading }] =
    useLazyQuery<ADMIN_LOAD_REPORT_CONVERSATIONS>(
      gql`
        query ADMIN_LOAD_REPORT_CONVERSATIONS($slug: ID!) {
          admin {
            cofounderMatching {
              adminProfile(slug: $slug) {
                slug
                email
                reports {
                  id
                  createdAt
                  adminUser {
                    slug
                    id
                    name
                    adminRating
                  }
                  conversation {
                    senderId
                    message
                    createdAt
                  }
                }
              }
            }
          }
        }
      `,
      { variables: { slug: profile.slug } }
    );

  // TODO: bring back profilesViewed and viewsReceived once the edge cleanup job completes
  const [fetchEngagementStats, { data: engagementStatsData }] = useLazyQuery(gql`
    query PROFILE_ENGAGEMENT_STATS($slug: ID!) {
      admin {
        cofounderMatching {
          adminProfile(slug: $slug) {
            slug
            profilesSaved
            profilesHidden
            invitesSent
            invitesReceived
            matches
            matchesMet
            messagesSent
            linkedinsClicked
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (shouldLoadEngagementStats) {
      fetchEngagementStats({
        variables: {
          slug: profile.slug,
        },
      });
    }
  }, []);

  const engagementStats = engagementStatsData?.admin?.cofounderMatching?.adminProfile;

  const onSetWeeklyInviteLimit = () => {
    setWeeklyInviteLimit({
      variables: {
        slug: profile.slug,
        weeklyInviteLimit: newWeeklyInviteLimit || null,
      },
    });
  };

  const showConversation = async (reportId: string) => {
    if (!loading && !conversationData) {
      fetchConversationData();
    }
    setSelectedReportId(reportId);
  };

  const messageSenderName = (
    selectedReport: any,
    message: ADMIN_LOAD_REPORT_CONVERSATIONS_admin_cofounderMatching_adminProfile_reports_conversation
  ) => {
    if (message.senderId === selectedReport?.user.id.toString()) {
      return `${selectedReport?.user.name} (reporter)`;
    }

    return user.name;
  };

  const renderConversation = (
    selectedReport:
      | ADMIN_LOAD_REPORT_CONVERSATIONS_admin_cofounderMatching_adminProfile_reports
      | undefined
  ) => {
    const conversation = selectedReport?.conversation;

    if (!conversation || !conversation.length) {
      return <i>No messages.</i>;
    }

    return conversation.map((message) => (
      <div css={css({ padding: "10px 0" })}>
        <div>
          <b>{messageSenderName(selectedReport, message)}</b>
          <span> - </span>
          <i>{new Date(message.createdAt).toLocaleString()}</i>
        </div>
        <p css={css({ whiteSpace: "pre-wrap" })}>{message.message}</p>
      </div>
    ));
  };
  const conversationModal = (
    <Dialog open={!!selectedReportId} onClose={() => setSelectedReportId(null)} scroll="body">
      <div css={css({ padding: 30 })}>
        {loading ? (
          <LoadingDots />
        ) : (
          renderConversation(
            conversationData?.admin?.cofounderMatching.adminProfile.reports.filter(
              (r) => r.id === selectedReportId
            )[0]
          )
        )}
      </div>
    </Dialog>
  );

  return (
    <div css={css({ display: "flex" })}>
      {conversationModal}
      <div css={css({ display: "flex", flexDirection: "column", width: "100%", maxWidth: 750 })}>
        <CFMProfile profile={profile} />
        <EnrichedUserData
          enrichedUserData={candidate.adminUser.adminUserStub}
          monoidRating={candidate.adminUser.monoidRating}
        />
        {"invitesSentThisWeek" in candidate && !!candidate.invitesSentThisWeek.length && (
          <InvitesList invites={candidate.invitesSentThisWeek} />
        )}
        {"recentMessagesSent" in candidate && !!candidate.recentMessagesSent.length && (
          <MessagesList messages={candidate.recentMessagesSent} />
        )}
        {"recentDirectorySearches" in candidate && !!candidate.recentDirectorySearches.length && (
          <DirectorySearches searches={candidate.recentDirectorySearches} profile={profile} />
        )}
      </div>
      <div css={css({ marginLeft: 40, maxWidth: 350 })}>
        <AdminUserProfileLinks userSlug={profile.user.slug} profileSlug={candidate.slug} />
        <p>
          <a href={`mailto:${candidate.email}`}>{candidate.email}</a>
        </p>
        {!candidate.needsAdminReview && (
          <FlagForAdminReview slug={profile.slug} refetch={refetch} />
        )}
        {superAdmin && <NewBadActorReportModal slug={profile.user.slug} onSuccess={refetch} />}
        <AdminActions
          candidate={candidate}
          superAdmin={superAdmin}
          allVotes={allVotes}
          toastOnVote={toastOnVote}
          refetch={refetch}
        />
        <AttributeGroup>
          <Attribute title="Created" value={format(new Date(profile.createdAt), "PP p")} />
          <Attribute title="Last Seen" value={format(new Date(profile.lastSeenAt), "PP p")} />
          <Attribute
            title="Active"
            value={
              <div css={css({ display: "flex", alignItems: "center" })}>
                <StyledValue text={profile.active ? "Yes" : "No"} />
                {profile.active && (
                  <div css={css({ marginLeft: 10, width: "100%" })}>
                    <BasicButton
                      size="small"
                      onClick={() => {
                        if (confirm("Are you sure you want to deactivate this profile?")) {
                          deactivateProfile({
                            variables: { slug: profile.slug },
                          });
                        }
                      }}
                    >
                      Deactivate
                    </BasicButton>
                  </div>
                )}
              </div>
            }
          />
          {!profile.active && "inactiveReason" in profile && (
            <Attribute title="Reason" value={profile.inactiveReason} />
          )}
          {superAdmin && (
            <Attribute
              title="Partner Contact"
              value={
                partnerContact ? (
                  <>
                    {partnerContact && <span>{partnerContact}</span>}
                    {partnerContactedAt && (
                      <span>, {format(new Date(partnerContactedAt), "PP p")}</span>
                    )}
                  </>
                ) : (
                  <BasicButton
                    onClick={() => {
                      if (
                        confirm("Are you sure you want to reach out to this person as Michael?")
                      ) {
                        contactAsPartner({
                          variables: { profileSlug: profile.slug },
                        });
                      }
                    }}
                  >
                    Contact as Michael
                  </BasicButton>
                )
              }
            />
          )}
          <Attribute
            block
            title="Emails Enabled"
            value={<ItemList items={profile.emailSettings} />}
          />
          <UserYcAppsList ycApps={candidate.adminUser.ycApps} />
        </AttributeGroup>
        <Pane>
          <PaneItem css={css({ padding: "10px 20px 20px 20px" })}>
            <AttributeGroup tight>
              <Attribute
                title="Weekly invite limit"
                value={
                  candidate.weeklyInviteLimit ||
                  `default (${candidate.adminUser.adminRating === "Y" ? 100 : 20})`
                }
              />
            </AttributeGroup>
            <div css={css({ display: "flex", alignItems: "center", width: "100%", marginTop: 5 })}>
              <Input
                type="number"
                value={newWeeklyInviteLimit}
                onChange={(e) =>
                  setNewWeeklyInviteLimit(Number.parseInt(e.target.value, 10) || undefined)
                }
                size="small"
                outlined
              />
              <BasicButton fullWidth onClick={onSetWeeklyInviteLimit}>
                Set new limit
              </BasicButton>
            </div>
          </PaneItem>
        </Pane>
        {engagementStats && (
          <>
            <h4>Engagement</h4>
            <AttributeGroup tight>
              {/* <Attribute title="Viewed" value={engagementStats.profilesViewed} /> */}
              <Attribute title="Saved" value={engagementStats.profilesSaved} />
              <Attribute title="Hidden" value={engagementStats.profilesHidden} />
              <Attribute title="Invited" value={engagementStats.invitesSent} />
              <Attribute title="LinkedIns clicked" value={engagementStats.linkedinsClicked} />
              {/* <Attribute title="Inbound Views" value={engagementStats.viewsReceived} /> */}
              <Attribute title="Inbound Invites" value={engagementStats.invitesReceived} />
              <Attribute title="Matches" value={engagementStats.matches} />
              <Attribute title="Meetings" value={engagementStats.matchesMet} />
              <Attribute title="Messages Sent" value={engagementStats.messagesSent} />
            </AttributeGroup>
          </>
        )}
        {"committedCofounderIds" in candidate && candidate.committedCofounderIds.length > 0 && (
          <>
            <h4>Committed Co-Founder(s)</h4>
            {candidate.committedCofounderIds.map((id) => (
              <div key={id}>
                <a href={profileAdminCofounderMatchingPath(id)}>
                  {profileAdminCofounderMatchingPath(id)}
                </a>
                <BasicButton
                  onClick={() => {
                    if (
                      confirm(`Sanity check: are you trying to un-commit a co-founder (id: ${id})?`)
                    ) {
                      uncommitFromCf({
                        variables: {
                          slug: candidate.slug,
                          otherProfileId: id,
                        },
                      });
                    }
                  }}
                >
                  Un-commit this co-founder
                </BasicButton>
              </div>
            ))}
          </>
        )}
        {"reports" in candidate && candidate.reports.length > 0 && (
          <>
            <h4>Misuse Reports</h4>
            {candidate.reports.map((report) => (
              <div key={report.userCfmId} css={css({ marginBottom: 15 })}>
                <div>
                  <div
                    css={css({
                      color: "gray",
                      fontSize: 14,
                      fontWeight: "bold",
                    })}
                  >
                    {format(new Date(report.createdAt), "PP p")}
                  </div>
                  <a href={profileAdminCofounderMatchingPath(report.userCfmId)}>
                    {`${report.adminUser.name} (${report.adminUser.adminRating})`}
                  </a>
                  : {report.reason}
                </div>
                <div>{report.other}</div>
                <BasicButton onClick={() => showConversation(report.id)}>
                  Show conversation
                </BasicButton>
              </div>
            ))}
          </>
        )}
        {"intervalsBetweenCandidateViews" in candidate &&
          candidate.intervalsBetweenCandidateViews && (
            <>
              <h4># seconds between most recent candidate views</h4>
              <div css={css({ maxWidth: 400 })}>
                {candidate.intervalsBetweenCandidateViews
                  .map((num) => Math.round(num * 100) / 100)
                  .join(", ")}
              </div>
            </>
          )}
      </div>
    </div>
  );
};
