import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { omit } from "lodash";
import Field, { CustomFieldProps } from "./Field";
import TextareaAutosize, { TextareaProps } from "../statelessForms/TextareaAutosize";
import { splitProps } from "./util";
import WithCharCount from "../statelessForms/WithCharCount";

type CustomProps = {
  showCharCount?: boolean;
};

const Textarea = (props: CustomFieldProps<Omit<TextareaProps, "rows"> & CustomProps>) => {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);
  const textAreaProps = omit(otherProps, "showCharCount");

  otherProps.minRows = otherProps.minRows || 3;
  return (
    <Field
      {...fieldProps}
      input={
        <Controller
          name={props.fieldName}
          control={control}
          render={({ field: formProps }) =>
            props.showCharCount ? (
              <WithCharCount
                min={fieldProps.minLength}
                max={fieldProps.maxLength}
                currentLength={formProps.value?.length || 0}
              >
                <TextareaAutosize
                  {...textAreaProps}
                  {...omit(formProps, "ref")}
                  minRows={otherProps.minRows}
                  fullWidth
                />
              </WithCharCount>
            ) : (
              <TextareaAutosize
                {...textAreaProps}
                {...omit(formProps, "ref")}
                minRows={otherProps.minRows}
              />
            )
          }
        />
      }
    />
  );
};

export default Textarea;
