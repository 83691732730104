import React, { useState } from "react";

import { css } from "@emotion/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

export type FAQItemType = {
  question: string;
  answer: string | string[];
};

type ItemProps = {
  item: FAQItemType;
};

function FAQItem(props: ItemProps): React.ReactElement {
  const { question, answer } = props.item;
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div
      css={css({
        border: "1px solid #cdcdcd",
        padding: "27px 30px",
        margin: "0 auto 30px auto",
        textAlign: "left",
        width: "550px",
        "&:hover": {
          cursor: "pointer",
        },
      })}
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      <div css={css({ display: "flex", fontSize: "18px", lineHeight: "1.8" })}>
        {question}
        <div css={css({ color: "#c2c2c2", marginLeft: "auto", paddingTop: 4 })}>
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </div>
      </div>
      {expanded && (
        <div
          css={css({
            color: "#646464",
            fontSize: "16px",
            lineHeight: "1.5",
            marginTop: "25px",
          })}
        >
          {(Array.isArray(answer) ? answer : [answer]).map((paragraph: string) => (
            <p css={css({ lineHeight: "2" })} key={paragraph}>
              {paragraph}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

type Props = {
  items: FAQItemType[];
  title?: string;
};

export default function FAQs(props: Props): React.ReactElement {
  return (
    <div css={css({ textAlign: "center" })}>
      <h1
        css={css({
          color: "#1a1a1a",
          fontSize: 32,
          fontWeight: "bold",
          letterSpacing: 0.24,
          lineHeight: 1.5,
          marginBottom: 55,
        })}
      >
        {props.title || "Frequently Asked Questions"}
      </h1>
      {props.items.map((item) => (
        <FAQItem key={item.question} item={item} />
      ))}
    </div>
  );
}
