import React from "react";
import { TextareaAutosize, TextareaAutosizeProps, makeStyles } from "@material-ui/core";
import { omit } from "lodash";

type CustomTextareaProps = {
  fullWidth?: boolean;
};
const customTextareaPropsKeys: (keyof Required<CustomTextareaProps>)[] = ["fullWidth"];

export type TextareaProps = TextareaAutosizeProps & CustomTextareaProps;

const useStyles = makeStyles(() => ({
  root: {
    borderColor: "rgba(0, 0, 0, 0.23)",
    padding: "10.5px 14px",
    lineHeight: 1.3,
    borderRadius: 3,
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
    "&:focus-visible": {
      outlineColor: "#3f51b5",
    },
    width: ({ fullWidth }: CustomTextareaProps) => (fullWidth ? "100%" : "auto"),
  },
}));

export default (props: TextareaProps) => {
  const classes = useStyles({ fullWidth: props.fullWidth });
  return <TextareaAutosize className={classes.root} {...omit(props, customTextareaPropsKeys)} />;
};
