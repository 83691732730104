import React from "react";
import { CSSObject } from "@emotion/styled";

type Props = {
  customText?: string;
  cssOptions?: CSSObject;
};

export const CFM_EMAIL = "cofounder-matching@ycombinator.com";

export default ({ customText, cssOptions }: Props) => (
  <a href={`mailto:${CFM_EMAIL}`} {...(cssOptions ? { css: cssOptions } : {})}>
    {customText || CFM_EMAIL}
  </a>
);
