import React from "react";
import { useFormContext, useController } from "react-hook-form";
import { Slider, SliderProps, withStyles } from "@material-ui/core";
import Field, { CustomFieldProps } from "./Field";
import { splitProps } from "./util";

export const StyledSlider = withStyles({
  root: {
    color: "#fb641e",
    height: 2,
    padding: "15px 0",
  },
  thumb: {
    backgroundColor: "#fb641e",
  },
  active: {},
  valueLabel: {
    top: -22,
    textAlign: "center",
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  mark: {
    backgroundColor: "#fb641e",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);

type Props = CustomFieldProps<Omit<SliderProps, "onChange">>;

export default (props: Props) => {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);
  const { field } = useController({
    control,
    name: props.fieldName,
    rules: { required: fieldProps.required },
  });

  return (
    <Field {...fieldProps}>
      <StyledSlider
        {...otherProps}
        value={typeof field.value === "number" ? field.value : 50}
        onChange={(_, newValue) => field.onChange(newValue)}
        aria-labelledby="input-slider"
        valueLabelDisplay="on"
      />
    </Field>
  );
};
