import React from "react";
import {
  Checkbox,
  Chip,
  createStyles,
  FormControl,
  ListItemText,
  makeStyles,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
  Theme,
} from "@material-ui/core";

type SelectValueType = string | number | string[] | undefined;
type ExtendedSelectValueType = SelectValueType | boolean;

type Options = Map<string, string> | [ExtendedSelectValueType, string][];

type CustomProps = {
  options: Options;
  onChange: (value: any) => void;
  clearable?: boolean;
  clearValue?: any;
};

type Props = CustomProps & SelectProps;
export { Props, Options, ExtendedSelectValueType };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

export default function Select(props: Props) {
  const classes = useStyles();

  const formatValue = (value: ExtendedSelectValueType) => {
    // convert booleans to strings
    if (typeof value === "boolean") {
      return `${value}`;
    }

    return value;
  };

  const onChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = e.target;

    let formattedValue = value;

    // handle transforming strings back to booleans
    if (value === "true") {
      formattedValue = true;
    }
    if (value === "false") {
      formattedValue = false;
    }

    props.onChange(formattedValue);
  };

  const renderValueProps = props.multiple
    ? {
        renderValue: (selected: any) => {
          const labelLookup = new Map(props.options);
          return (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={labelLookup.get(value)} className={classes.chip} />
              ))}
            </div>
          );
        },
      }
    : {};

  const renderItem = ([key, label]: [ExtendedSelectValueType, string]) => (
    <MenuItem key={`${key}`} value={formatValue(key)}>
      {props.multiple
        ? [
            <Checkbox
              color="default"
              checked={((props.value || []) as any[]).indexOf(key) > -1}
              key={`c-${key}`}
            />,
            <ListItemText primary={label} key={`l-${key}`} />,
          ]
        : label}
    </MenuItem>
  );

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <MuiSelect
        {...props}
        {...renderValueProps}
        value={props.value ?? (props.multiple ? [] : "")}
        onChange={onChange}
      >
        {props.clearable && (
          <MenuItem value={props.clearValue}>
            <p />
          </MenuItem>
        )}
        {Array.from(props.options).map(renderItem)}
      </MuiSelect>
    </FormControl>
  );
}
