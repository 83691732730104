import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useController, useFormContext } from "react-hook-form";
import Field, { CustomFieldProps, FieldProps } from "./Field";
import { splitProps } from "./util";
import { useSmallStyles } from "./MultiSelect";

type CheckboxProps = CustomFieldProps<{ small?: boolean } & FieldProps>;

export default function Input(props: CheckboxProps) {
  const { fieldProps, otherProps } = splitProps(props);

  // Checkboxes are kind of a special case -- they usually just have an inline
  // label, not a field label.
  delete fieldProps.label;

  const { control } = useFormContext();

  const { field } = useController({
    control,
    name: fieldProps.fieldName,
    rules: { required: fieldProps.required },
  });

  const smallStyles = useSmallStyles();

  const classes = otherProps.small ? { label: smallStyles.label } : {};

  return (
    <Field {...fieldProps}>
      <FormControlLabel
        control={<Checkbox color="default" />}
        checked={!!field.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => field.onChange(e.target.checked)}
        label={props.label}
        classes={classes}
      />
    </Field>
  );
}
