import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useJsApiLoader } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import { omit } from "lodash";
import Field, { FieldProps } from "./Field";
import Input from "../statelessForms/Input";
import { splitProps } from "./util";

// Note: it's okay for this to be visible to users. We're expecting them to use this token in their Google Maps API requests.
const GOOGLE_MAPS_API_KEY = "AIzaSyCgpaLmXUre6wNwWuzsNXBbvYzZq3bpgfs";
const LIBRARIES: "places"[] = ["places"];

const InputAutocomplete = ({ inputRef, onFocus, onSelect, onBlur, ...props }: any) => (
  <Autocomplete {...props} onPlaceSelected={onSelect} options={{ types: ["(cities)"] }} />
);

type CustomProps = {
  onSelect?: (value: string) => void;
};

export default function LocationPicker(props: FieldProps & CustomProps) {
  const { fieldProps, otherProps } = splitProps(props);
  const { control, setValue } = useFormContext();

  const { isLoaded: googleMapsLoaded } = useJsApiLoader({
    libraries: LIBRARIES,
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const validate = (data: string) =>
    !fieldProps.required || !!data ? true : "Location is a required field.";

  const onSelect = (place: any) => {
    if (place.formatted_address) {
      setValue(fieldProps.fieldName, place.formatted_address);
    }
    if (otherProps.onSelect) {
      otherProps.onSelect(place.formatted_address);
    }
  };

  // Fall back to a plain text input if we can't load the Google Maps JS
  const autocompleteProps = googleMapsLoaded
    ? {
        inputComponent: InputAutocomplete,
        inputProps: { onSelect },
      }
    : {};

  return (
    <Field
      {...fieldProps}
      validate={validate}
      input={
        <Controller
          name={fieldProps.fieldName}
          control={control}
          render={({ field: formProps }) => (
            <Input
              {...omit(formProps, "ref")}
              placeholder="London, UK"
              size="small"
              {...autocompleteProps}
              outlined
            />
          )}
        />
      }
    />
  );
}
