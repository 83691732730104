import React, { useState } from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { gql, useMutation } from "@apollo/client";
import Button from "../../../components/statelessForms/Button";
import LoadingDots from "../../../components/statelessForms/LoadingDots";
import {
  DIRECTORY_SET_HARD_FILTERS,
  DIRECTORY_SET_HARD_FILTERSVariables,
} from "./__generated__/DIRECTORY_SET_HARD_FILTERS";
import { CfmUpdateProfileInput } from "../../../types/graphqlTypes";
import { COFOUNDER_MATCHING_DIRECTORY_cofounderMatching_profile } from "./__generated__/COFOUNDER_MATCHING_DIRECTORY";
import { reqPrefs } from "../profileFields";
import { DEFAULT_AGE_MAX, DEFAULT_AGE_MIN } from "../../../components/forms/util";

type Props = {
  profile: COFOUNDER_MATCHING_DIRECTORY_cofounderMatching_profile;
  isOpen: boolean;
  onClose: () => void;
  getCurrentValues: () => any;
};

const ModalBody = styled.div`
  padding: 30px;
  h2 {
    text-align: center;
  }
`;

const ModalText = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  button {
    margin: 10px;
    flex-grow: 1;
    flex-basis: 50%;
  }
`;

export default ({ profile, isOpen, onClose, getCurrentValues }: Props) => {
  const [updating, setUpdating] = useState(false);
  const [updateProfile] = useMutation<
    DIRECTORY_SET_HARD_FILTERS,
    DIRECTORY_SET_HARD_FILTERSVariables
  >(gql`
    mutation DIRECTORY_SET_HARD_FILTERS($input: CfmUpdateProfileInput!) {
      cfmUpdateProfile(input: $input) {
        slug
        active
        errors {
          field
          error
        }
      }
    }
  `);
  const onConfirmFilters = async () => {
    setUpdating(true);
    const filters = getCurrentValues();
    const input: CfmUpdateProfileInput = {};

    if (filters.fellowAlumniOnly) {
      input.cfIsFellowAlumniImportance = "require";
    } else if (profile.cfIsFellowAlumniImportance === "require") {
      input.cfIsFellowAlumniImportance = "prefer";
    }

    if (filters.womenOnly) {
      input.cfIsWomanImportance = "require";
    } else if (profile.cfIsWomanImportance === "require") {
      input.cfIsWomanImportance = "prefer";
    }

    const openToNone = filters.hasIdea?.includes("none");
    const openToCommitted = filters.hasIdea?.includes("committed");
    if ((openToNone && !openToCommitted) || (!openToNone && openToCommitted)) {
      input.cfHasIdea = !!openToCommitted;
      input.cfHasIdeaImportance = "require";
    } else if (profile.cfHasIdeaImportance === "require") {
      input.cfHasIdeaImportance = "prefer";
    }

    if (filters.cfIsTechnical?.length === 1) {
      [input.cfIsTechnical] = filters.cfIsTechnical;
      input.cfIsTechnicalImportance = "require";
    } else if (profile.cfIsTechnicalImportance === "require") {
      input.cfIsTechnicalImportance = "prefer";
    }

    if (
      filters.cfResponsibilities?.length &&
      filters.cfResponsibilities.length < reqPrefs.responsibilities.size
    ) {
      input.cfResponsibilities = filters.cfResponsibilities;
      input.cfResponsibilitiesImportance = "require";
    } else if (profile.cfResponsibilitiesImportance === "require") {
      input.cfResponsibilitiesImportance = "prefer";
    }

    if (filters.interests?.length && filters.interests.length < reqPrefs.interests.size) {
      input.interests = filters.interests;
      input.cfInterestsImportance = "require";
    } else if (profile.cfInterestsImportance === "require") {
      input.cfInterestsImportance = "prefer";
    }

    if (
      filters.ageMin !== (profile.cfAgeMin || DEFAULT_AGE_MIN) ||
      filters.ageMax !== (profile.cfAgeMax || DEFAULT_AGE_MAX)
    ) {
      input.cfAgeMin = filters.ageMin;
      input.cfAgeMax = filters.ageMax;
      input.cfAgeImportance = "require";
    }

    await updateProfile({
      variables: { input },
    });
    setUpdating(false);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ModalBody>
        {updating ? (
          <div css={css({ display: "flex", justifyContent: "center" })}>
            <LoadingDots />
          </div>
        ) : (
          <>
            <DialogTitle>
              Are you sure you'd like to save these filters to your profile?
            </DialogTitle>
            <ModalText>
              These filters will apply to all candidates in your queue, and your profile will be
              hidden from candidates who don't pass all these filters.
            </ModalText>
            <ModalText css={css({ fontStyle: "italic", fontSize: 14 })}>
              Note: location and timing filters cannot be saved.
            </ModalText>
            <ButtonRow>
              <Button color="orange" onClick={onConfirmFilters}>
                Yes, confirm new filters
              </Button>
              <Button color="gray" onClick={onClose}>
                No, cancel
              </Button>
            </ButtonRow>
          </>
        )}
      </ModalBody>
    </Dialog>
  );
};
