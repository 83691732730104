import React, { useState } from "react";
import { css } from "@emotion/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import styled from "@emotion/styled";

type Props = {
  title: string;
  count?: number;
  defaultOpen?: boolean;
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CountTag = styled.span`
  margin-left: 10px;
  background-color: #b4c3e1;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  padding: 3px 12px;
  font-size: 14px;
  line-height: 1;
`;

const ExpandableSection: React.FC<Props> = ({ children, title, count, defaultOpen }) => {
  const [expanded, setExpanded] = useState<boolean>(!!defaultOpen);

  return (
    <>
      <div onClick={() => setExpanded(!expanded)}>
        <h1 css={css({ display: "flex", fontSize: 22, marginTop: 20, cursor: "pointer" })}>
          <div css={css({ marginRight: 5, paddingTop: 3 })}>
            {expanded ? (
              <ExpandMoreIcon htmlColor="#2d2d2d" />
            ) : (
              <ExpandLessIcon htmlColor="#2d2d2d" />
            )}
          </div>
          <TitleWrapper>
            <div>{title}</div>
            {!!count && <CountTag>{count}</CountTag>}
          </TitleWrapper>
        </h1>
      </div>
      {expanded ? (
        children
      ) : (
        <hr
          css={css({ backgroundColor: "#c0c0c0", height: 1, border: "none", marginBottom: 30 })}
        />
      )}
    </>
  );
};

export default ExpandableSection;
