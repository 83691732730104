import { css, SerializedStyles } from "@emotion/core";
import authorizedFetch from "@yc/shared/authorizedFetch";
import React, { useRef } from "react";
import { useController, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { updateAvatarUsersPath } from "../../__generated__/routes";
import ErrorLabel from "../statelessForms/ErrorLabel";

// These types are enumerated and validated in bookface_user.rb
const ALLOWABLE_IMAGE_TYPES = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

export const saveAvatar = async (avatarFile: File | string | undefined) => {
  if (!(avatarFile instanceof File)) return;

  const body = new FormData();
  body.append("avatar", avatarFile);

  await authorizedFetch(updateAvatarUsersPath(), {
    method: "POST",
    autoContentType: true,
    body,
  });
};

export const isAvatarPresent = (avatar: string | File) =>
  !!avatar && avatar !== "/avatar.svg" && avatar !== `${window.location.origin}/avatar.svg`;

export default ({
  fieldName,
  ...props
}: React.PropsWithChildren<{
  fieldName: string;
  noAvatarError?: string;
  required?: boolean;
  style: SerializedStyles;
}>) => {
  const { control } = useFormContext();
  let validate;
  if (props.required) {
    validate = (currentAvatar: string | File) => {
      if (!isAvatarPresent(currentAvatar)) {
        return "This is a required field";
      }
      return true;
    };
  }
  const { field } = useController({ control, name: fieldName, rules: { validate } });
  const inputRef = useRef<HTMLInputElement>(null);

  const previewURL = field.value instanceof File ? URL.createObjectURL(field.value) : field.value;
  const noAvatar = !isAvatarPresent(field.value);

  const ensureUTF8String = (string: string) => {
    let result = "";
    for (let i = 0; i < string.length; i += 1) {
      if (string.charCodeAt(i) <= 127) {
        result += string.charAt(i);
      }
    }
    return result;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const image = e.target.files[0];

    const { name, type, size } = image;

    // Sometimes .jfif files have the image/jpeg mimetype, but will fail on upload
    if (name.toLowerCase().endsWith(".jfif")) {
      toast.error("Sorry, .JFIF files are not supported.");
      return;
    }

    const imageBlob = image.slice(0, size, type);
    const utf8Name = ensureUTF8String(name);
    const safeImage = new File([imageBlob], utf8Name, { type });

    field.onChange(safeImage);
  };

  return (
    <div
      {...(props.style ? { css: props.style } : {})}
      css={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      })}
    >
      <img
        css={css({
          width: 100,
          height: 100,
          borderRadius: "50%",
          backgroundColor: "#ccc",
          objectFit: "cover",
        })}
        src={previewURL}
        alt="user avatar"
      />
      <a
        role="button"
        css={css({ marginTop: 7 })}
        tabIndex={-1}
        onClick={() => inputRef.current?.click()}
      >
        {noAvatar ? "Add Picture" : "Change Picture"}
        {!!validate && noAvatar && (
          <span css={css({ color: "#db2828", position: "relative", top: "-0.2em", left: "0.2em" })}>
            *
          </span>
        )}
      </a>
      <input
        css={css({ display: "none" })}
        type="file"
        accept={ALLOWABLE_IMAGE_TYPES.join(", ")}
        ref={inputRef}
        onChange={onChange}
      />
      {props.noAvatarError && noAvatar && (
        <ErrorLabel css={css({ textAlign: "center" })}>{props.noAvatarError}</ErrorLabel>
      )}
    </div>
  );
};
