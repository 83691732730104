import React from "react";
import Icon from "../icon";

export type SubmenuItemType = {
  name: string;
  path?: string;
  id?: string;
  menu?: SubmenuItemType[];
  active_prefix?: string;
  active: boolean;
  notification_dot?: boolean;
  name_suffix?: string;
  open_in_new_tab?: boolean;
  new?: string;
  exact_path?: boolean;
};

export type TabType = {
  name: string;
  path?: string;
  icon: React.ComponentProps<typeof Icon>["icon"] | React.ReactElement | string;
  active?: boolean;
  active_prefix?: string;
  notification_dot?: boolean;
  menu?: SubmenuItemType[];
  id?: string;
  feature_flag?: string;
  always_active?: boolean;
  open_in_new_tab?: boolean;
  exact_path?: boolean;
};

export const measurements = {
  menuOpen: 220,
};

export const menuColors = {
  active: "#303030",
  inactive: "#8b8b8b",
};

export const MenuContext = React.createContext<boolean>(false);

export const animateMenu = {
  transition: "all 0.5s ease",
};
