import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Dialog, DialogTitle } from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import Button from "../../components/statelessForms/Button";
import { cofounderMatchingPath } from "../../__generated__/routes";
import { SET_ADULT, SET_ADULTVariables } from "./__generated__/SET_ADULT";

const ConfirmAgeButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  button {
    margin: 0 10px;
    width: 40%;
  }
`;

type Props = {
  isAdult: boolean | null | undefined;
};

export default ({ isAdult }: Props) => {
  const [confirmedIsMinor, setConfirmedIsMinor] = useState<boolean>(false);

  const [setAdult] = useMutation<SET_ADULT, SET_ADULTVariables>(gql`
    mutation SET_ADULT($adult: Boolean!) {
      setAdult(adult: $adult) {
        slug
        adult
      }
    }
  `);

  const setUserIsAdult = (isAdultValue: boolean) => {
    setAdult({
      variables: { adult: isAdultValue },
    });

    if (!isAdultValue) {
      setConfirmedIsMinor(true);
    }
  };

  const goToDashboardSection = (
    <ConfirmAgeButtonRow>
      <Button color="orange" href={cofounderMatchingPath()}>
        Return to dashboard
      </Button>
    </ConfirmAgeButtonRow>
  );

  const confirmAgeSection = (
    <ConfirmAgeButtonRow>
      <Button color="orange" onClick={() => setUserIsAdult(false)}>
        I am under 18 years old
      </Button>
      <Button color="orange" onClick={() => setUserIsAdult(true)}>
        I am 18+ years old
      </Button>
    </ConfirmAgeButtonRow>
  );

  return (
    <Dialog open={!isAdult} scroll="body" maxWidth="sm">
      <DialogTitle>Are you at least 18 years old?</DialogTitle>
      <div css={css({ padding: "0 24px 30px 24px" })}>
        <div>You must be at least 18 years old to participate in co-founder speed dating.</div>
        {confirmedIsMinor ? goToDashboardSection : confirmAgeSection}
      </div>
    </Dialog>
  );
};
