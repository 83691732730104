import React, { useState } from "react";
import { css } from "@emotion/core";

import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";
import { gql, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { Dialog } from "@material-ui/core";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";
import { mainHoverActionColors, Color } from "../../components/statelessForms/Button";
import { DesktopOnly, MobileOnly, paneBorder } from "../../styles";
import { CFM_REQUEST_MATCH, CFM_REQUEST_MATCHVariables } from "./__generated__/CFM_REQUEST_MATCH";
import { CFM_HIDE_PROFILE, CFM_HIDE_PROFILEVariables } from "./__generated__/CFM_HIDE_PROFILE";
import CFMSaveToFavorites from "./CFMSaveToFavorites";
import {
  CFM_UNHIDE_PROFILE,
  CFM_UNHIDE_PROFILEVariables,
} from "./__generated__/CFM_UNHIDE_PROFILE";
import StickyPageFooter from "./StickyPageFooter";
import { FullCandidateProfile, CompactCandidateProfile } from "./profileFields";

export type CurrentContextType =
  | "candidate"
  | "directory"
  | "saved"
  | "skipped"
  | "trial"
  | "dashboard"
  | "inbox"
  | "speedDating"
  | "meetup"
  | "fymk"
  | "highly-similar-email-alert";

type Props = {
  candidate: FullCandidateProfile | CompactCandidateProfile | null;
  invitesRemaining: number | undefined;
  loading?: boolean;
  requestStatus: string | undefined;
  onlyDisplayConnectActions?: boolean;
  onlyDisplayInviteAction?: boolean;
  showCandidateName?: boolean;
  loadNextCandidate?: (slug?: string) => void;
  reloadCandidate?: () => void;
  onInvite?: () => void;
  currentContext: CurrentContextType;
  isAtBottom?: boolean;
  hideSaveOnMobile?: boolean;
  inviteLimit: number | undefined;
};

type ActionButtonProps = {
  color: Color;
};

const ToastContainer: React.FC = ({ children }) => <div>{children}</div>;

export const ActionButton = styled.button<ActionButtonProps>`
  width: 100%;
  min-width: 120px;
  color: white;
  font-weight: bold;
  font-size: 13px !important;
  border: none;
  padding: 9px;
  border-radius: 4px;
  &:hover:enabled {
    cursor: pointer;
  }
  ${(props) => {
    const [mainColor, hoverColor, activeColor] = mainHoverActionColors.get(props.color) || [];
    return css({
      backgroundColor: mainColor,
      "&:hover:enabled": {
        backgroundColor: hoverColor,
      },
      "&:active:enabled": {
        backgroundColor: activeColor,
      },
    });
  }}
`;

const DismissCandidateActionButton = styled(ActionButton)`
  max-width: fit-content;
  min-width: 135px;
  margin-bottom: 30px;
`;

const SaveToFavoritesSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 500;
  justify-content: flex-end;
  color: #fbbd08;
`;

const ActionButtonsContainer = styled.div`
  margin-top: 5px;
  & button {
    flex: 1;
    margin: 0 2px 3px !important;
    white-space: nowrap;
  }
`;

export default ({
  candidate,
  invitesRemaining,
  loading,
  requestStatus,
  onlyDisplayConnectActions,
  onlyDisplayInviteAction,
  showCandidateName,
  loadNextCandidate,
  reloadCandidate,
  onInvite,
  currentContext,
  isAtBottom,
  hideSaveOnMobile,
  inviteLimit,
}: Props) => {
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [requestMessage, setRequestMessage] = useState<string>("");
  const [sendInviteModalOpen, setSendInviteModelOpen] = useState(false);
  const metAtSpeedDatingToday =
    !!candidate && "metAtSpeedDatingToday" in candidate && candidate.metAtSpeedDatingToday;

  const [cfmRequestMatch] = useMutation<CFM_REQUEST_MATCH, CFM_REQUEST_MATCHVariables>(gql`
    mutation CFM_REQUEST_MATCH($slug: ID!, $requestMessage: String!, $currentContext: String!) {
      cfmRequestMatch(
        slug: $slug
        requestMessage: $requestMessage
        currentContext: $currentContext
      ) {
        slug
      }
    }
  `);

  const [cfmHideProfile] = useMutation<CFM_HIDE_PROFILE, CFM_HIDE_PROFILEVariables>(gql`
    mutation CFM_HIDE_PROFILE($slug: ID!, $currentContext: String!) {
      cfmHideProfile(slug: $slug, currentContext: $currentContext) {
        slug
      }
    }
  `);

  const [cfmUnhideProfile] = useMutation<CFM_UNHIDE_PROFILE, CFM_UNHIDE_PROFILEVariables>(gql`
    mutation CFM_UNHIDE_PROFILE($slug: ID!) {
      cfmUnhideProfile(slug: $slug) {
        slug
      }
    }
  `);

  const nextCandidate = () => {
    setRequestMessage("");
    setSendInviteModelOpen(false);
    if (loadNextCandidate) loadNextCandidate();
  };

  const requestMatch = async () => {
    if (!candidate) {
      return;
    }
    setIsPerformingAction(true);
    if (requestMessage.length === 0) {
      toast.error("Please type a message before we send this invitation.");
      return;
    }
    if (invitesRemaining === 0 && !metAtSpeedDatingToday) {
      toast.error("You have exhausted your invites for the week. Please try again next week.");
      return;
    }
    await cfmRequestMatch({ variables: { slug: candidate.slug, requestMessage, currentContext } });

    toast.success(`Invitation sent to ${candidate.user.name}!`);

    if (onInvite) onInvite();
    nextCandidate();
    setIsPerformingAction(false);
  };

  const hideProfile = async () => {
    if (!candidate) {
      return;
    }
    setIsPerformingAction(true);
    await cfmHideProfile({ variables: { slug: candidate.slug, currentContext } });
    toast.info(
      <ToastContainer>
        <div
          css={css({ display: "flex", justifyContent: "space-around" })}
          onClick={async () => {
            await cfmUnhideProfile({ variables: { slug: candidate.slug } });
            if (loadNextCandidate) loadNextCandidate(candidate.slug);
          }}
        >
          Profile skipped
          <strong css={css({ paddingLeft: 5 })}>
            <u>Undo</u>
          </strong>
        </div>
      </ToastContainer>,
      {
        className: "gray-background-toast wider-max-width-toast",
        autoClose: 3000,
      }
    );
    nextCandidate();
    setIsPerformingAction(false);
  };

  const savedToFavorites = requestStatus === "saved";
  const actionsDisabled = loading || isPerformingAction;
  const inviteButtonDisabled = actionsDisabled || (metAtSpeedDatingToday && invitesRemaining === 0);

  const hideOrNext = !(onlyDisplayConnectActions || onlyDisplayInviteAction) && (
    <div css={css({ display: "flex", flexWrap: "wrap" })}>
      {savedToFavorites ? (
        <ActionButton color="orange" disabled={actionsDisabled} onClick={nextCandidate}>
          See next candidate
        </ActionButton>
      ) : (
        <>
          <ActionButton color="gray" disabled={actionsDisabled} onClick={hideProfile}>
            Not interested
          </ActionButton>
        </>
      )}
    </div>
  );

  const invitesRemainingSection = () => {
    if (metAtSpeedDatingToday) {
      return (
        <p>
          Since you met during co-founder speed dating in the past 24 hours, sending an invite won't
          count against your {inviteLimit || 20}-per-week limit!
        </p>
      );
    }

    if (invitesRemaining && invitesRemaining > 0) {
      return (
        <p>
          (You have <b>{invitesRemaining}</b> invites left for this week.)
        </p>
      );
    }

    return (
      <p>
        ❗You've exhausted your {inviteLimit || 20} invites for the week. You can keep skipping or
        hiding profiles, but cannot send any more invites. Check back on Monday when invites
        refresh!
      </p>
    );
  };

  if (!candidate) {
    return null;
  }

  const inviteTextarea = (small: boolean = false) => (
    <TextareaAutosize
      css={css({
        padding: 11,
        resize: "none",
        width: "100%",
        border: paneBorder,
      })}
      minRows={small ? 5 : 8}
      placeholder={invitePlaceholderText}
      value={requestMessage}
      onChange={(e) => setRequestMessage(e.target.value)}
    />
  );

  const invitePlaceholderText = `If you're excited about potentially working with ${
    showCandidateName ? candidate.user.firstName : "this co-founder"
  }, type a short message here and click "Invite."`;

  let paneCss = {};
  if (currentContext === "directory") {
    paneCss = { margin: 0 };
  }
  if (currentContext === "candidate") {
    paneCss = { marginBottom: 0 };
  }

  if (onlyDisplayInviteAction || onlyDisplayConnectActions || currentContext === "fymk") {
    return (
      <>
        {!onlyDisplayInviteAction && (
          <SaveToFavoritesSection css={css({ justifyContent: "flex-end" })}>
            <CFMSaveToFavorites
              profile={candidate}
              loading={loading}
              onChange={reloadCandidate}
              currentContext={currentContext}
              icon
              showIconText
            />
          </SaveToFavoritesSection>
        )}
        <Pane css={css(paneCss)}>
          {currentContext !== "directory" && (
            <PaneHeader>
              <h2>Invite to Connect</h2>
            </PaneHeader>
          )}
          <PaneItem>
            {inviteTextarea()}
            <div
              css={css({
                marginTop: 5,
                "& button": {
                  flex: 1,
                  margin: "0 2px 3px !important",
                  whiteSpace: "nowrap",
                },
              })}
            >
              <div css={css({ display: "flex", flexWrap: "wrap" })}>
                <ActionButton color="green" disabled={inviteButtonDisabled} onClick={requestMatch}>
                  Invite to connect
                </ActionButton>
              </div>
              {hideOrNext}
            </div>
            <div css={css({ marginTop: 20 })}>{invitesRemainingSection()} </div>
          </PaneItem>
        </Pane>
      </>
    );
  }

  const inviteToConnectContents = (
    <Pane css={css(paneCss)}>
      <PaneItem>
        <h2>Invite to connect</h2>
        {inviteTextarea(true)}
        <ActionButtonsContainer>
          <div css={css({ display: "flex", flexWrap: "wrap" })}>
            <ActionButton color="green" disabled={inviteButtonDisabled} onClick={requestMatch}>
              Invite to connect
            </ActionButton>
          </div>
        </ActionButtonsContainer>
        <div css={css({ marginTop: 10, textAlign: "center" })}>{invitesRemainingSection()} </div>
      </PaneItem>
    </Pane>
  );

  const dismissButton = (
    <DismissCandidateActionButton color="gray" disabled={actionsDisabled} onClick={hideProfile}>
      Skip for now
    </DismissCandidateActionButton>
  );

  const nextProfileButton = (
    <DismissCandidateActionButton color="orange" disabled={actionsDisabled} onClick={nextCandidate}>
      See next profile
    </DismissCandidateActionButton>
  );

  const saveToFavoritesSection = (
    <SaveToFavoritesSection css={css({ justifyContent: "flex-end" })}>
      <CFMSaveToFavorites
        profile={candidate}
        loading={loading}
        onChange={reloadCandidate}
        currentContext={currentContext}
        icon
        showIconText
      />
    </SaveToFavoritesSection>
  );

  return (
    <>
      <DesktopOnly>
        {saveToFavoritesSection}

        {inviteToConnectContents}

        <div css={css({ marginTop: 10, textAlign: "center" })}>
          {savedToFavorites ? nextProfileButton : dismissButton}
        </div>
      </DesktopOnly>

      <MobileOnly>
        <div>{!hideSaveOnMobile && saveToFavoritesSection}</div>

        <Dialog
          open={sendInviteModalOpen}
          onClose={() => setSendInviteModelOpen(false)}
          scroll="body"
          maxWidth="md"
        >
          {inviteToConnectContents}
        </Dialog>

        <StickyPageFooter isAtBottom={isAtBottom}>
          {savedToFavorites ? nextProfileButton : dismissButton}
          <ActionButton color="green" onClick={() => setSendInviteModelOpen(true)}>
            Send invitation
          </ActionButton>
        </StickyPageFooter>
      </MobileOnly>
    </>
  );
};
