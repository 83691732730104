import React from "react";
import { css } from "@emotion/core";
import { Attribute } from "../cofounderMatching/CFMAttributes";

type YcAppType = {
  id: string;
  batch: string;
  result: string;
  url: string;
};

type Props = {
  ycApps: YcAppType[];
};

export default ({ ycApps }: Props) => (
  <Attribute
    block
    title="YC Apps"
    value={
      ycApps.length ? (
        <ul>
          {ycApps.map((ycApp) => (
            <li key={ycApp.id}>
              <a href={ycApp.url} target="_blank">
                {ycApp.batch} ({ycApp.result})
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <p css={css({ color: "gray", fontStyle: "italic" })}>None</p>
      )
    }
  />
);
