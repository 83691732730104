import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";

type Props = {
  currentLength: number;
  min?: number;
  max?: number;
};

const Wrapper = styled.div`
  width: 100%;
`;

const CharCount = styled.div`
  color: darkgray;
  font-size: 14px;
  text-align: end;
`;

const Num = styled.span`
  font-weight: bold;
`;

export default ({ currentLength, min, max, children }: PropsWithChildren<Props>) => {
  const maxText = !!max && `out of ${max}`;
  const minText = !!min && `minimum ${min}`;
  let suffix = [maxText, minText].filter((t) => !!t).join(", ");
  suffix = suffix.length ? ` (${suffix})` : "";

  let css = {};
  if (max && currentLength > max) {
    css = { color: "#ca0b00" };
  } else if (min && currentLength && currentLength < min) {
    css = { color: "orange" };
  }

  return (
    <Wrapper>
      {children}
      <CharCount>
        Characters used: <Num css={css}>{currentLength}</Num>
        <i>{suffix}</i>
      </CharCount>
    </Wrapper>
  );
};
