import React, { KeyboardEvent, KeyboardEventHandler, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { gql, useMutation } from "@apollo/client";
import { CFM_SEND_MESSAGE, CFM_SEND_MESSAGEVariables } from "./__generated__/CFM_SEND_MESSAGE";
import { mobileStyleCss } from "../../../styles";

const NewMessageBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: max(60px, 20%);
  textarea {
    flex-grow: 1;
    height: 100%;
    padding: 10px;
    border-top: 1px solid #c0c0c0;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-bottom-left-radius: 3px;
    resize: none;
  }
  button {
    height: 100%;
    width: max(100px, 10%);
    border-top: 1px solid #c0c0c0;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid #c0c0c0;
    border-bottom-right-radius: 3px;
    &:hover {
      cursor: pointer;
    }
  }

  ${mobileStyleCss(`
    max-height: 70px;
  `)}
`;

type Props = {
  slug: string;
  checkForNewMessages: (slug: string) => any;
  setHasError: (error: boolean) => any;
};

export default ({ slug, checkForNewMessages, setHasError }: Props) => {
  const [newMessage, setNewMessage] = useState<string>("");

  const [sendMessage, { loading }] = useMutation<CFM_SEND_MESSAGE, CFM_SEND_MESSAGEVariables>(gql`
    mutation CFM_SEND_MESSAGE($slug: ID!, $message: String!) {
      sendMessage(slug: $slug, message: $message, mobile: false) {
        createdAt
        message
        errors {
          field
          error
        }
      }
    }
  `);

  useEffect(() => {
    setNewMessage("");
  }, [slug]);

  const handleSendMessageError = (inputText: string) => {
    setNewMessage(inputText);
    setHasError(true);
  };

  const onClickSendMessage = async () => {
    if (loading || !slug || !newMessage) {
      return;
    }
    setHasError(false);
    const inputText = newMessage;

    setNewMessage("");
    try {
      const { data } = await sendMessage({
        variables: {
          slug,
          message: newMessage,
        },
      });

      if (data?.sendMessage.errors) {
        handleSendMessageError(inputText);
      }
    } catch {
      handleSendMessageError(inputText);
    }
    await checkForNewMessages(slug);
  };

  const onKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (
    event: KeyboardEvent<HTMLTextAreaElement>
  ) => {
    // Sends message on CMD+Enter
    if (event.key === "Enter" && event.metaKey) {
      onClickSendMessage();
      event.preventDefault();
    }
  };

  return (
    <NewMessageBox>
      <textarea
        value={newMessage}
        onChange={({ target }) => setNewMessage(target.value)}
        onKeyDown={onKeyDown}
      />
      <button onClick={onClickSendMessage} disabled={loading}>
        Send
      </button>
    </NewMessageBox>
  );
};
