import { css } from "@emotion/core";
import React from "react";
import styled from "@emotion/styled";
import {
  INTERESTS,
  reqPrefs,
  RESPONSIBILITIES,
  getTimingText,
  getHasIdeaText,
  getIsTechnicalText,
  getLocationText,
  getWomenText,
  getYcAlumText,
  getFellowAlumniText,
  IMPORTANCE,
  AnyCFMProfile,
} from "./profileFields";
import { linkified } from "../../components/forms/util";

type TagProps = {
  highlight?: boolean;
  lowlight?: boolean;
};
export const Tag = styled.div`
  background: none #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  padding: 0.5833em 0.833em;
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  border-radius: 4px;
  transition: background 0.1s ease;
  font-size: 12px;
  font-weight: bold;
  ${(props: TagProps) => {
    if (props.highlight) {
      return css`
        background: none #ffffff;
        color: #21ba45;
      `;
    }
    if (props.lowlight) {
      return css`
        background-color: #e8e8e8;
        border: 0 solid transparent;
        color: rgba(0, 0, 0, 0.6);
      `;
    }
  }}
`;

export const StyledValue = (props: { text: string }) => <Tag>{props.text}</Tag>;

export const Title = (props: { title: string; block?: boolean }) => (
  <span
    css={css({
      fontWeight: "bold",
      marginRight: 7,
      marginBottom: props.block ? 10 : 0,
      fontSize: props.block ? 14 : 12,
      color: "#333",
    })}
  >
    {props.title}
  </span>
);

export const Attribute = (props: {
  title?: string;
  value: React.ReactNode;
  block?: boolean;
  paddingBottom?: boolean;
  linkify?: boolean;
}) => {
  if (props.value === null || props.value === undefined || props.value === "") return null;

  return (
    <tr>
      {props.block ? (
        <td colSpan={2}>
          {props.title && <Title title={props.title} block={props.block} />}
          <div
            css={css({
              whiteSpace: "pre-line",
              fontSize: 14,
              fontFamily: "Avenir",
              fontWeight: 300,
              ul: { padding: 0, margin: 0, paddingLeft: 20 },
              paddingBottom: props.paddingBottom ? 20 : 0,
              paddingTop: props.title ? 5 : 0,
            })}
          >
            {props.linkify ? linkified(props.value) : props.value}
          </div>
        </td>
      ) : (
        <>
          <td css={css({ whiteSpace: "nowrap", minWidth: 110 })} valign="top">
            {props.title && <Title title={props.title} />}
          </td>
          <td css={css({ width: "100%" })} valign="top">
            {props.value}
          </td>
        </>
      )}
    </tr>
  );
};

export const AttributeGroup: React.FC<{ tight?: boolean; padding?: number }> = (props) => (
  <table
    css={css({
      borderCollapse: "collapse",
      "tr:not(:last-of-type) td": { paddingBottom: props.padding || (props.tight ? 0 : 14) },
      "tr:last-of-type td": { paddingBottom: 0 },
    })}
  >
    <tbody>{props.children}</tbody>
  </table>
);

export const ItemList = (props: {
  items: string[];
  highlight?: string[] | null;
  label?: (value: string) => string;
}) => {
  const items = props.items.map((i) => ({
    highlight: props.highlight?.includes(i) ?? false,
    label: props.label ? props.label(i) : i,
  }));

  return (
    <span css={css({ lineHeight: "1.8em" })}>
      {items.map(
        (i) =>
          i && (
            <Tag
              key={i.label}
              highlight={i.highlight}
              lowlight={!i.highlight}
              css={css({ "&&&": { marginLeft: 0, marginRight: "0.2rem", marginBottom: 2 } })}
            >
              {i.label}
            </Tag>
          )
      )}
    </span>
  );
};

type Props = {
  importance: IMPORTANCE[];
  profile: AnyCFMProfile;
  profileBeingCompared?: AnyCFMProfile | null;
};

export const CFMAttributes = ({ importance, profile, profileBeingCompared }: Props) => {
  const attributes: Map<string, JSX.Element> = new Map();

  if (importance.includes(profile.cfHasIdeaImportance as IMPORTANCE)) {
    attributes.set("Idea", <StyledValue text={getHasIdeaText(profile)} />);
  }

  if (importance.includes(profile.cfIsTechnicalImportance as IMPORTANCE)) {
    attributes.set("Skills", <StyledValue text={getIsTechnicalText(profile)} />);
  }

  if (importance.includes(profile.cfTimingImportance as IMPORTANCE)) {
    attributes.set("Timing", <StyledValue text={getTimingText(profile)} />);
  }

  if (importance.includes(profile.cfLocationImportance as IMPORTANCE)) {
    attributes.set("Location", <StyledValue text={getLocationText(profile)} />);
  }

  if (
    importance.includes(profile.cfInterestsImportance as IMPORTANCE) &&
    profile.interests.length
  ) {
    const interestsElement = (
      <ItemList
        items={profile.interests}
        highlight={profileBeingCompared?.interests || []}
        label={(r) => reqPrefs.interests.get(r as INTERESTS)!}
      />
    );
    attributes.set("Interests", interestsElement);
  }

  if (
    importance.includes(profile.cfResponsibilitiesImportance as IMPORTANCE) &&
    profile.cfResponsibilities.length
  ) {
    const responsibilitiesElement = (
      <ItemList
        items={profile.cfResponsibilities}
        highlight={profileBeingCompared?.responsibilities || []}
        label={(r) => reqPrefs.responsibilities.get(r as RESPONSIBILITIES)!}
      />
    );
    attributes.set("Responsibilities", responsibilitiesElement);
  }

  if (
    "isWoman" in profile.user &&
    profile.user.isWoman &&
    "cfIsWomanImportance" in profile &&
    importance.includes(profile.cfIsWomanImportance as IMPORTANCE)
  ) {
    attributes.set("Prefer women", <StyledValue text={getWomenText(profile)} />);
  }

  if (
    "showYcFounder" in profile.user &&
    profile.user.showYcFounder &&
    "cfIsYcAlumImportance" in profile &&
    importance.includes(profile.cfIsYcAlumImportance as IMPORTANCE)
  ) {
    attributes.set("Prefer YC alums", <StyledValue text={getYcAlumText(profile)} />);
  }

  if (
    "schools" in profile.user &&
    !!profile.user.schools.length &&
    "cfIsFellowAlumniImportance" in profile &&
    importance.includes(profile.cfIsFellowAlumniImportance as IMPORTANCE)
  ) {
    attributes.set("Prefer fellow alumni", <StyledValue text={getFellowAlumniText(profile)} />);
  }

  if (attributes.size > 0) {
    return (
      <AttributeGroup>
        {Array.from(attributes.entries()).map(([title, valueElement]) => (
          <Attribute title={title} key={title} value={valueElement} />
        ))}
      </AttributeGroup>
    );
  }
  return (
    <div css={css({ fontStyle: "italic", fontSize: 14, fontWeight: 100, color: "gray" })}>
      {`No ${importance.includes("prefer") ? "preferences" : "requirements"}`}
    </div>
  );
};
