import { css } from "@emotion/core";
import { formatDistance, isToday } from "date-fns";
import React from "react";
import styled, { CSSObject } from "@emotion/styled";
import { Share, AccessTime, PlaceOutlined, CakeOutlined, SchoolOutlined } from "@material-ui/icons";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { SocialIcon } from "react-social-icons";
import { toast } from "react-toastify";
import striptags from "striptags";
import linkifyHtml from "linkifyjs/lib/linkify-html";
import isValidUrl from "@yc/shared/utils/isValidUrl";
import { Attribute, AttributeGroup, ItemList } from "./CFMAttributes";

import {
  AnyAdminProfile,
  FullCandidateProfile,
  CompactCandidateProfile,
  getLocationProfileText,
  HAS_IDEA,
  HasIdeaShortText,
  IMPORTANCE,
  INTERESTS,
  reqPrefs,
  RESPONSIBILITIES,
  TimingProfileText,
  TIMINGS,
  ViewerProfile,
} from "./profileFields";
import { CFMProfileFragment } from "./__generated__/CFMProfileFragment";
import { CFMViewerProfileFragment } from "./__generated__/CFMViewerProfileFragment";
import { candidateCofounderMatchingPath } from "../../__generated__/routes";
import { mobileStyles } from "../../styles";
import { Pane, PaneItem } from "../../components/Pane";
import { YC_ORANGE, formatList } from "../../components/forms/util";
import Icon from "../../components/icon";
import LinkedinLink from "./LinkedinLink";
import Recommendation from "./Recommendation";

type ProfileType = CFMProfileFragment | FullCandidateProfile | AnyAdminProfile["profile"];

type Props = {
  profile: ProfileType;
  viewer?: CFMViewerProfileFragment | null;
  showProfileLink?: boolean;
};

export const ProfileYoutubeVideo = (props: { name: string; link: string | null }) => {
  const youtubeRegex = /^.*(youtu.be\/|watch\?v=)(.*)/;
  const matches = props.link?.match(youtubeRegex);
  if (!matches || matches.length < 3) {
    return null;
  }
  const youtube_id = matches[2];
  return (
    // This padding maintains a 16:9 aspect ratio
    <div css={css({ height: "100%", width: "100%", position: "relative", paddingTop: "56.25%" })}>
      <iframe
        css={css({ position: "absolute", top: 0, bottom: 0, height: "100%", width: "100%" })}
        title={props.name}
        src={`https://www.youtube.com/embed/${youtube_id}`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

const P = styled.p({
  fontWeight: 300,
  ...mobileStyles({
    marginBottom: 0,
    display: "flex",
    justifyContent: "center",
  }),
});

export const HeaderDiv = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 5,
});

const CalendlyBanner = styled.a`
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 5px;
  background-color: #fb651e;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 4px;
  margin: 10px 0;

  &:hover {
    cursor: pointer;
    color: white;
  }
`;

const HeaderListItem = styled.span`
  margin: 3px 0;
`;

const ShareButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: transparent;
  color: #268bd2;
  font-size: 14px;

  &:hover:enabled {
    cursor: pointer;
    color: #0b7ac8;

    &:active {
      color: #07558c;
    }
  }

  svg {
    margin-right: 3px;
    margin-left: -8px;
  }
`;

export const highlightify = (text: string) => (
  <>
    <div
      css={css({ fontWeight: 300 })}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: linkifyHtml(striptags(text || "", "<mark>")),
      }}
    />
  </>
);

export const TextList = ({ text }: { text: string | null }) => {
  if (!text) {
    return <ul />;
  }

  const formatTextLine = (initialLine: string) => {
    let line = initialLine;
    if (line.startsWith("-")) {
      line = line.slice(1).trim();
    }

    return line;
  };

  const lines = text
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line.length)
    .map((line) => highlightify(formatTextLine(line)));

  return (
    <ul>
      {lines.map((line, idx) => (
        // eslint-disable-next-line
        <li key={idx}>{line}</li>
      ))}
    </ul>
  );
};

export const aggregateFragmentsIntoSentence = (sentenceFragments: (JSX.Element | string)[]) => {
  const period = ".";
  if (sentenceFragments.length === 0) {
    return sentenceFragments;
  }
  if (sentenceFragments.length < 2) {
    sentenceFragments.push(period);
    return sentenceFragments;
  }

  if (sentenceFragments.length === 2) {
    sentenceFragments.splice(1, 0, <span key={sentenceFragments.join("|")}> and </span>);
    sentenceFragments.push(period);
    return sentenceFragments;
  }
  if (sentenceFragments.length > 2) {
    const commas = new Array(sentenceFragments.length - 1).fill(", ");
    commas[commas.length - 1] = ", and ";
    const aggregator = [];
    for (let i = 0; i < commas.length; i++) {
      aggregator.push(sentenceFragments[i]);
      aggregator.push(commas[i]);
    }
    aggregator.push(sentenceFragments[sentenceFragments.length - 1]);
    aggregator.push(period);
    return aggregator;
  }
  return sentenceFragments;
};

export const generateCfPreferenceSentence = (
  profile: ProfileType | ViewerProfile | null | undefined,
  importance: IMPORTANCE[]
) => {
  if (!profile) return [];
  const sentenceFragments: JSX.Element[] = [];

  if (importance.includes(profile.cfHasIdeaImportance as IMPORTANCE)) {
    sentenceFragments.push(
      <span key="cfHasIdea">
        {profile.cfHasIdea ? (
          <>
            set on a <b>specific idea</b>
          </>
        ) : (
          <>
            <b>not set</b> on a specific idea
          </>
        )}
      </span>
    );
  }

  if (importance.includes(profile.cfIsTechnicalImportance as IMPORTANCE)) {
    sentenceFragments.push(
      <span key="cfIsTechnical">
        <b>{profile.cfIsTechnical ? "technical" : "non-technical"}</b>
      </span>
    );
  }

  if (importance.includes(profile.cfTimingImportance as IMPORTANCE)) {
    const timing = TimingProfileText.get(profile.timing as TIMINGS);
    if (timing) {
      sentenceFragments.push(timing);
    }
  }

  if (importance.includes(profile.cfLocationImportance as IMPORTANCE)) {
    sentenceFragments.push(getLocationProfileText(profile));
  }

  if (
    importance.includes(profile.cfInterestsImportance as IMPORTANCE) &&
    profile.interests.length
  ) {
    sentenceFragments.push(
      <span key="interests">
        <b>aligned</b> with my interests
      </span>
    );
  }

  if (
    importance.includes(profile.cfIsFellowAlumniImportance as IMPORTANCE) &&
    profile.user.schools.length
  ) {
    sentenceFragments.push(
      <span key="fellow-alumni">
        an <b>alumni of {formatList(profile.user.schools.map((s) => s.title) || [])}</b>
      </span>
    );
  }

  if (
    importance.includes(profile.cfIsYcAlumImportance as IMPORTANCE) &&
    profile.user.showYcFounder
  ) {
    sentenceFragments.push(
      <span key="yc-alum">
        a <b>YC alum</b>
      </span>
    );
  }

  return aggregateFragmentsIntoSentence(sentenceFragments);
};

const generateResponsibilitiesSentence = (cfmProfile: ProfileType | CompactCandidateProfile) => {
  const responsibilities: JSX.Element[] = [];

  cfmProfile.responsibilities.forEach((r) => {
    responsibilities.push(<b key={r}>{reqPrefs.responsibilities.get(r as RESPONSIBILITIES)}</b>);
  });

  return aggregateFragmentsIntoSentence(responsibilities);
};

export const generateSummaryParagraph = (cfmProfile: ProfileType | CompactCandidateProfile) => {
  const responsibilities = generateResponsibilitiesSentence(cfmProfile);
  return (
    <div
      css={css({
        fontSize: 14,
        fontFamily: "Avenir",
      })}
    >
      <p css={css({ marginBottom: 5 })}>
        I'm <b>{cfmProfile.user.isTechnical ? "technical" : "non-technical"}</b>,{" "}
        {TimingProfileText.get(cfmProfile.timing as TIMINGS)}, and{" "}
        {HasIdeaShortText.get(cfmProfile.hasIdea as HAS_IDEA)}.
      </p>
      {cfmProfile.responsibilities.length > 0 && responsibilities.length > 0 && (
        <p>I'm willing to do {responsibilities}</p>
      )}
    </div>
  );
};

export const prefilledCalendarLink = (
  calendlyLink: string,
  viewer: CFMViewerProfileFragment | null | undefined
) => {
  if (!viewer) return calendlyLink;

  const url = `${window.location.origin}${candidateCofounderMatchingPath(viewer.slug)}`;
  const meetingNotes = `Hey! I matched with you through Y Combinator's Co-Founder Matching platform. You can see my profile here: ${url}`;
  const prefills = [];
  if (viewer?.user?.name) prefills.push(`name=${viewer.user.name}`);
  // if (viewer?.email) prefills.push(`email=${viewer.email}`);

  if (calendlyLink.includes("calendly.com")) {
    prefills.push(`a1=${meetingNotes}`);
  } else if (calendlyLink.includes("cal.com")) {
    prefills.push(`notes=${meetingNotes}`);
  }

  return `${calendlyLink}?${prefills.join("&")}`;
};

export const fymkBanner = (
  profile: ProfileType | CompactCandidateProfile,
  compact: boolean = false
) => {
  if (!profile.fymkAffinity && !profile.fymkOverlap) {
    return null;
  }

  return (
    <HeaderDiv css={css({ width: "100%", marginBottom: 10 })}>
      <HeaderListItem
        css={generateHeaderListCSS("white", "orange", compact ? 12 : 14, {
          width: "100%",
          textAlign: "center",
          borderRadius: 20,
          border: "1px solid orange",
        })}
      >
        <div css={css({ display: "flex", flexWrap: "wrap" })}>
          {profile.fymkOverlap ? (
            <span style={{ whiteSpace: "pre-wrap", flex: "1 1 auto" }}>
              You may know them from {profile.fymkOverlap}
            </span>
          ) : (
            <span>Founder You May Know</span>
          )}
        </div>
      </HeaderListItem>
    </HeaderDiv>
  );
};

export const generateHeaderListCSS = (
  backgroundColor: string,
  color: string = "white",
  fontSize: number = 14,
  otherStyles: CSSObject = {}
) =>
  css({
    backgroundColor,
    color,
    fontSize,
    fontWeight: "bold",
    padding: "3px 8px",
    borderRadius: 3,
    width: "fit-content",
    ...otherStyles,
  });

export default ({ profile, showProfileLink, viewer }: Props) => {
  const lastSeenDateObj = new Date(profile.lastSeenAt);
  const lastSeenDateStr = isToday(lastSeenDateObj)
    ? "today"
    : formatDistance(lastSeenDateObj, new Date(), { addSuffix: true });

  const headerListItems = [];
  if (showProfileLink) {
    headerListItems.push([
      "profileLink",
      <HeaderListItem>
        <a
          css={css({
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            svg: { marginRight: 5 },
          })}
          target="_blank"
          href={candidateCofounderMatchingPath(profile.slug)}
        >
          <OpenInNewIcon />
          Profile
        </a>
      </HeaderListItem>,
    ]);
  }

  profile.user.schools.forEach((school) => {
    headerListItems.push([
      school.name,
      <HeaderDiv title="School">
        <SchoolOutlined />
        <HeaderListItem css={generateHeaderListCSS(school.colorPrimary, school.colorSecondary)}>
          {school.title}
        </HeaderListItem>
      </HeaderDiv>,
    ]);
  });

  if (profile.videoLink) {
    headerListItems.push([
      "videoLink",
      <HeaderListItem>
        <a href={profile.videoLink} target="_blank">
          Video
        </a>
      </HeaderListItem>,
    ]);
  }

  if (
    "ycFounderLabel" in profile.user &&
    profile.user.showYcFounder &&
    profile.user.ycFounderLabel
  ) {
    headerListItems.push([
      "ycFounder",
      <HeaderDiv>
        <Icon
          icon="ycY"
          css={css({
            height: 20,
            width: 20,
          })}
        />
        <HeaderListItem css={generateHeaderListCSS(YC_ORANGE)}>
          {profile.user.ycFounderLabel}
        </HeaderListItem>
      </HeaderDiv>,
    ]);
  }

  const onCopyLink = async () => {
    const url = `${window.location.origin}${candidateCofounderMatchingPath(profile.slug)}`;
    await navigator.clipboard?.writeText(url);
    toast.info("Link copied!");
  };

  const generateInterestLists = () => {
    const matched: string[] = [];
    const other: string[] = [];
    profile.interests.forEach((i) => {
      if (viewer && viewer.interests.includes(i)) {
        matched.push(i);
      } else {
        other.push(i);
      }
    });

    return [matched, other];
  };

  const { company } = profile;
  const avatarSize = 150;

  const [matchedInterests, otherInterests] = generateInterestLists();
  const [cofounderRequire, cofounderPrefer] = [
    generateCfPreferenceSentence(profile, ["require"]),
    generateCfPreferenceSentence(profile, ["prefer"]),
  ];

  return (
    <div
      css={css({
        width: "100%",
        maxWidth: 800,
        overflowWrap: "anywhere",
        padding: 0,
        mark: {
          fontWeight: 600,
        },
      })}
    >
      {fymkBanner(profile)}
      <div
        css={css({
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
          ...mobileStyles({
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
          }),
        })}
      >
        <img
          key={profile.user.avatarUrl}
          css={css({
            borderRadius: "50%",
            marginRight: 40,
            backgroundColor: "#ccc",
            ...mobileStyles({ marginRight: 0 }),
          })}
          src={profile.user.avatarUrl}
          height={avatarSize}
          width={avatarSize}
          alt="candidate avatar"
        />
        <div
          css={css({
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            ...mobileStyles({ alignItems: "center" }),
          })}
        >
          <div
            css={css({
              display: "flex",
              ...mobileStyles({
                gap: 10,
                paddingTop: 10,
                flexDirection: "column",
              }),
            })}
          >
            <h1 css={css({ fontSize: 30, marginBottom: 0 })}>{profile.user.name}</h1>

            <div
              css={css({
                display: "flex",
                marginLeft: "auto",
                gap: 7,
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                alignItems: "center",
                ...mobileStyles({ marginLeft: 0 }),
              })}
            >
              {"twitterLink" in profile.user &&
                profile.user.twitterLink &&
                isValidUrl(profile.user.twitterLink) && (
                  <SocialIcon
                    rel="noopener noreferrer"
                    href={profile.user.twitterLink as string}
                    // network="twitter"
                    style={{ height: 35, width: 35 }}
                    target="_blank"
                  />
                )}
              {"instagramLink" in profile.user &&
                profile.user.instagramLink &&
                isValidUrl(profile.user.instagramLink) && (
                  <SocialIcon
                    url={profile.user.instagramLink as string}
                    network="instagram"
                    style={{ height: 35, width: 35 }}
                    target="_blank"
                  />
                )}
              <ShareButton onClick={onCopyLink}>
                <Share />
              </ShareButton>
            </div>
          </div>

          <P
            css={css({
              marginBottom: 16,
              marginTop: 16,
              display: "flex",
              gap: 15,
              flexWrap: "wrap",
            })}
          >
            <HeaderDiv title="Location">
              <PlaceOutlined />
              {profile.user.location}
            </HeaderDiv>
            <HeaderDiv title="Age">
              {!!profile.user.age && (
                <>
                  <CakeOutlined />
                  {profile.user.age}
                </>
              )}
            </HeaderDiv>
            <HeaderDiv title="Last seen on co-founder matching">
              <AccessTime />
              {`Last seen ${lastSeenDateStr}`}
            </HeaderDiv>
          </P>

          <P
            css={css({
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              ...mobileStyles({ marginTop: 10 }),
            })}
          >
            {headerListItems.map(([key, element]: [key: string, element: JSX.Element], index) => (
              <React.Fragment key={key}>
                {index !== 0 && <span css={css({ margin: "0 7px" })} />}
                {element}
              </React.Fragment>
            ))}
          </P>
        </div>
      </div>
      {profile.calendlyLink && (
        <CalendlyBanner target="_blank" href={prefilledCalendarLink(profile.calendlyLink, viewer)}>
          Schedule a call
        </CalendlyBanner>
      )}
      <div css={css({ margin: "auto auto 20px" })}>
        <ProfileYoutubeVideo name={profile.user.name} link={profile.videoLink} />
      </div>
      <Pane>
        <PaneItem>{generateSummaryParagraph(profile)}</PaneItem>
      </Pane>
      <Pane title="About Me">
        <PaneItem>
          <AttributeGroup padding={20}>
            <Attribute block title="Intro" value={profile.intro} linkify />
            <Attribute block title="Life Story" value={profile.lifeStory} linkify />
            <Attribute block title="Free Time" value={profile.freeTime} linkify />
            <Attribute block title="Other" value={profile.other} linkify />
          </AttributeGroup>
        </PaneItem>
      </Pane>
      <Pane title="My Background">
        <PaneItem>
          <AttributeGroup padding={20}>
            <Attribute
              title="Impressive accomplishment"
              block
              value={profile.user.impressiveThing}
              linkify
            />
            <Attribute title="Education" block value={<TextList text={profile.user.education} />} />
            <Attribute
              title="Employment"
              block
              value={<TextList text={profile.user.employment} />}
            />
          </AttributeGroup>
        </PaneItem>
      </Pane>

      {profile.hasIdea !== "committed" && profile.ideas && (
        <Pane title="Potential ideas">
          <PaneItem>
            <AttributeGroup padding={20}>
              <Attribute value={profile.ideas} block linkify />
            </AttributeGroup>
          </PaneItem>
        </Pane>
      )}

      {profile.hasIdea === "committed" && company && (
        <Pane title="My idea" header={<b>{company.name}</b>}>
          <PaneItem>
            <AttributeGroup padding={20}>
              <Attribute title={company.name} value={company.description} block linkify />
              <Attribute title="Progress" value={company.progress} block linkify />
              <Attribute title="Funding Status" value={company.fundingStatus} block linkify />
            </AttributeGroup>
          </PaneItem>
        </Pane>
      )}

      {profile.hasCf && (
        <Pane title="About my current co-founder">
          <PaneItem>
            <AttributeGroup padding={20}>
              <Attribute
                block
                value={
                  <p>
                    I already have a{" "}
                    <b>{profile.currentCfTechnical ? "technical" : "non-technical"}</b> co-founder.
                    {profile.currentCfLinkedin ? (
                      <>
                        {" "}
                        <a href={profile.currentCfLinkedin} target="_blank">
                          Here's their LinkedIn
                        </a>
                        .
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                }
              />
              <Attribute
                block
                title="Why I'm looking for another co-founder"
                value={profile.whyLookingForThirdCf}
                linkify
              />
            </AttributeGroup>
          </PaneItem>
        </Pane>
      )}

      <Pane title="What I'm looking for in a co-founder">
        <PaneItem>
          <AttributeGroup padding={20}>
            {cofounderRequire.length > 0 && (
              <Attribute block value={<p>I want a co-founder who is {cofounderRequire}</p>} />
            )}

            {cofounderPrefer.length > 0 && (
              <Attribute block value={<p>Ideally, my co-founder is {cofounderPrefer}</p>} />
            )}

            <Attribute block title="Ideal co-founder" value={profile.reqFreeText} linkify />

            <Attribute block title="Equity expectations" value={profile.equity} linkify />
          </AttributeGroup>
        </PaneItem>
      </Pane>

      {profile.interests.length > 0 && (
        <Pane>
          <PaneItem>
            <AttributeGroup padding={20}>
              {matchedInterests.length > 0 && (
                <Attribute
                  title="Our shared interests"
                  block
                  value={
                    <ItemList
                      items={matchedInterests}
                      highlight={matchedInterests}
                      label={(i) => reqPrefs.interests.get(i as INTERESTS)!}
                    />
                  }
                />
              )}
              {otherInterests.length > 0 && (
                <Attribute
                  title="My interests"
                  block
                  value={
                    <ItemList
                      items={generateInterestLists()[1]}
                      label={(i) => reqPrefs.interests.get(i as INTERESTS)!}
                    />
                  }
                />
              )}
            </AttributeGroup>
          </PaneItem>
        </Pane>
      )}

      {profile.recommendations.length > 0 && (
        <Pane title="Endorsements">
          <PaneItem css={css({ paddingBottom: 10 })}>
            {profile.recommendations.map((rec) => (
              <Recommendation rec={rec} key={rec.slug} />
            ))}
          </PaneItem>
        </Pane>
      )}

      <LinkedinLink profile={profile} />
    </div>
  );
};
