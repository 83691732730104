import { css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { useIntersectionObserver } from "react-intersection-observer-hook";
import { gql, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import CFMSaveToFavorites from "../CFMSaveToFavorites";
import {
  CFM_DIRECTORY_SEARCH_cofounderMatching_directory_search_results,
  CFM_DIRECTORY_SEARCH_cofounderMatching_directory_search_results_profile_request,
} from "./__generated__/CFM_DIRECTORY_SEARCH";
import Button, { BasicButton } from "../../../components/statelessForms/Button";
import { getConversationUrl } from "../inbox/CFMInboxFrame";
import {
  LOG_DIRECTORY_PROFILE_VIEW,
  LOG_DIRECTORY_PROFILE_VIEWVariables,
} from "./__generated__/LOG_DIRECTORY_PROFILE_VIEW";
import CompactCFMProfile from "../CompactCFMProfile";
import { ProfilePreviewFields } from "../__generated__/ProfilePreviewFields";
import { FOUNDERS_YOU_MAY_KNOW_cofounderMatching_foundersYouMayKnow_profiles_request } from "../__generated__/FOUNDERS_YOU_MAY_KNOW";
import { YC_ORANGE } from "../../../components/forms/util";
import { CompactCandidateProfile } from "../profileFields";
import {
  CFM_DIRECTORY_HIDE_PROFILE,
  CFM_DIRECTORY_HIDE_PROFILEVariables,
} from "./__generated__/CFM_DIRECTORY_HIDE_PROFILE";
import {
  CFM_DIRECTORY_UNHIDE_PROFILE,
  CFM_DIRECTORY_UNHIDE_PROFILEVariables,
} from "./__generated__/CFM_DIRECTORY_UNHIDE_PROFILE";

const NotInterestedWrapper = styled.div`
  min-height: 60px;
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

const NotInterestedText = styled.div`
  color: gray;
  font-style: italic;
  display: flex;
  flex-direction: row;
  justify-content: center;

  a {
    margin-left: 10px;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const statusPreviewMatchedOrSent = (
  profile: CompactCandidateProfile | ProfilePreviewFields,
  request:
    | CFM_DIRECTORY_SEARCH_cofounderMatching_directory_search_results_profile_request
    | FOUNDERS_YOU_MAY_KNOW_cofounderMatching_foundersYouMayKnow_profiles_request
) => {
  const conversationUrl = getConversationUrl(request.slug);
  const requestSenderIsOtherPerson = request.sender.slug === profile.slug;

  if (request.status === "accepted") {
    return (
      <>
        <div css={css({ fontSize: 14, marginBottom: 5, color: "#28A828" })}>
          <b>You matched with {profile.user.firstName}!</b>
        </div>
        <BasicButton
          fullWidth
          onClick={() => {
            window.open(conversationUrl, "_blank")?.focus();
          }}
        >
          Go to conversation
        </BasicButton>
      </>
    );
  }
  return requestSenderIsOtherPerson ? (
    <>
      <div css={css({ fontSize: 14, marginBottom: 5, color: YC_ORANGE })}>
        <b>{profile.user.firstName} invited you to match!</b>
      </div>
      <Button
        fullWidth
        color="orange"
        onClick={() => {
          window.open(conversationUrl, "_blank")?.focus();
        }}
      >
        Respond to invite
      </Button>
    </>
  ) : (
    // you are the sender, this can happen right after you send an invite
    <div css={css({ fontSize: 14, color: "darkgray", fontWeight: 300 })}>Invite sent!</div>
  );
};

type Props = {
  result: CFM_DIRECTORY_SEARCH_cofounderMatching_directory_search_results;
  onInviteClicked: () => void;
  hideInviteToConnect: boolean;
};

export default ({ result, onInviteClicked, hideInviteToConnect }: Props) => {
  const { profile, highlights } = result;
  const { request } = profile;

  const [ref, { entry }] = useIntersectionObserver();
  const isVisible = entry && entry.isIntersecting;
  const [viewed, setViewed] = useState(false);
  const [isDismissed, setIsDismissed] = useState(profile.request?.status === "dismissed");

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (isVisible && !viewed) {
      timeoutId = setTimeout(() => {
        logDirectoryProfileView({ variables: { slug: profile.slug } });
        setViewed(true);
      }, 2000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isVisible]);

  const [logDirectoryProfileView] = useMutation<
    LOG_DIRECTORY_PROFILE_VIEW,
    LOG_DIRECTORY_PROFILE_VIEWVariables
  >(gql`
    mutation LOG_DIRECTORY_PROFILE_VIEW($slug: ID!) {
      logDirectoryProfileView(slug: $slug) {
        success
      }
    }
  `);

  const [hideProfile] = useMutation<
    CFM_DIRECTORY_HIDE_PROFILE,
    CFM_DIRECTORY_HIDE_PROFILEVariables
  >(gql`
    mutation CFM_DIRECTORY_HIDE_PROFILE($slug: ID!) {
      cfmHideProfile(slug: $slug, currentContext: "directory") {
        slug
      }
    }
  `);

  const [unhideProfile] = useMutation<
    CFM_DIRECTORY_UNHIDE_PROFILE,
    CFM_DIRECTORY_UNHIDE_PROFILEVariables
  >(gql`
    mutation CFM_DIRECTORY_UNHIDE_PROFILE($slug: ID!) {
      cfmUnhideProfile(slug: $slug) {
        slug
      }
    }
  `);

  const onHideProfile = async (undo: boolean = false) => {
    if (undo) {
      await unhideProfile({ variables: { slug: profile.slug } });
      setIsDismissed(false);
    } else {
      await hideProfile({ variables: { slug: profile.slug } });
      setIsDismissed(true);
    }
  };

  const renderActionBox = () => {
    if (request?.status === "accepted" || request?.status === "sent") {
      return statusPreviewMatchedOrSent(profile, request);
    }
    return (
      <div css={css({ display: "flex", width: "fit-content" })}>
        <div css={css({ marginRight: 5 })}>
          {hideInviteToConnect ? (
            <BasicButton disabled>Invited!</BasicButton>
          ) : (
            <BasicButton onClick={onInviteClicked}>Invite to connect</BasicButton>
          )}
        </div>
        {!hideInviteToConnect && (
          <CFMSaveToFavorites profile={profile} currentContext="directory" trackSavedStatus icon />
        )}
      </div>
    );
  };

  const renderDismissedFooter = () => {
    const status = profile.request?.status;

    if (isDismissed) {
      return (
        <NotInterestedWrapper>
          <NotInterestedText>
            <div>You said you're not interested in this profile.</div>
            <a onClick={() => onHideProfile(true)}>Undo</a>
          </NotInterestedText>
        </NotInterestedWrapper>
      );
    }

    if (!status && !hideInviteToConnect) {
      return (
        <NotInterestedWrapper css={css({ display: "flex", justifyContent: "center" })}>
          <Button size="small" onClick={() => onHideProfile()}>
            I'm not interested
          </Button>
        </NotInterestedWrapper>
      );
    }

    return <div />;
  };

  return (
    <div ref={ref}>
      <div css={css({ padding: 5 })}>
        <CompactCFMProfile
          profile={profile}
          highlights={highlights}
          actionBox={renderActionBox()}
          footer={renderDismissedFooter()}
          showProfileLink
          hideShareableLink
          context="directory"
        />
      </div>
    </div>
  );
};
