import { css } from "@emotion/core";
import React from "react";
import { toast } from "react-toastify";
import { gql, useMutation } from "@apollo/client";
import {
  COFOUNDER_MATCHING_CANDIDATE_cofounderMatching_candidate,
  COFOUNDER_MATCHING_CANDIDATE_cofounderMatching_candidate_request,
} from "./__generated__/COFOUNDER_MATCHING_CANDIDATE";
import { Pane, PaneHeader, PaneItem } from "../../components/Pane";
import { CFM_PROFILE_MODAL_cofounderMatching_candidate } from "./__generated__/CFM_PROFILE_MODAL";
import {
  CFM_ACCEPT_REQUEST,
  CFM_ACCEPT_REQUESTVariables,
} from "./__generated__/CFM_ACCEPT_REQUEST";
import {
  CFM_DECLINE_REQUEST,
  CFM_DECLINE_REQUESTVariables,
} from "./__generated__/CFM_DECLINE_REQUEST";
import Button from "../../components/statelessForms/Button";
import { getConversationUrl } from "./inbox/CFMInboxFrame";
import { goto } from "../../components/forms/util";
import { mobileStyles } from "../../styles";

type Props = {
  candidate:
    | CFM_PROFILE_MODAL_cofounderMatching_candidate
    | COFOUNDER_MATCHING_CANDIDATE_cofounderMatching_candidate;
  request: COFOUNDER_MATCHING_CANDIDATE_cofounderMatching_candidate_request;
  onAccept?: () => void;
  onDecline?: () => void;
};

export default ({ candidate, request, onAccept, onDecline }: Props) => {
  const senderSlug = request.sender.slug;

  const [cfmAcceptRequest] = useMutation<CFM_ACCEPT_REQUEST, CFM_ACCEPT_REQUESTVariables>(gql`
    mutation CFM_ACCEPT_REQUEST($slug: ID!) {
      cfmAcceptRequest(slug: $slug, currentContext: "candidate-web") {
        slug
      }
    }
  `);

  const [cfmDeclineRequest] = useMutation<CFM_DECLINE_REQUEST, CFM_DECLINE_REQUESTVariables>(gql`
    mutation CFM_DECLINE_REQUEST($slug: ID!) {
      cfmDeclineRequest(slug: $slug, currentContext: "candidate-web") {
        slug
      }
    }
  `);

  const acceptMatch = async () => {
    await cfmAcceptRequest({ variables: { slug: senderSlug } });
    if (onAccept) onAccept();
    toast.success(`Matched with ${request.sender.user.name}!`);

    goto(getConversationUrl(request.slug));
  };

  const declineMatch = async () => {
    await cfmDeclineRequest({ variables: { slug: senderSlug } });
    if (onDecline) onDecline();
    toast.info("Invitation declined", {
      className: "gray-background-toast",
    });
  };

  return (
    <Pane styles={mobileStyles({ marginBottom: 0 })}>
      <PaneHeader>
        <h2>Respond to Invite</h2>
      </PaneHeader>
      <PaneItem>
        <p>{candidate.user.name} has sent you an invitation with the following message:</p>
        <blockquote
          css={css({
            background: "#f9f9f9",
            borderLeft: "7px solid #ccc",
            marginLeft: 10,
            padding: "10px 15px",
            whiteSpace: "pre-line",
          })}
        >
          {request.message}
        </blockquote>
        <div>
          If you're excited about potentially working with this co-founder, click <b>Accept</b>{" "}
          below to match! We'll send you both an email to start the conversation.
        </div>
        <br />
        <div>
          If you don't want to match with them, click <b>Ignore</b>. They won't be notified that you
          ignored their invite, and we won't show you their profile again.
        </div>
        <br />
        <div
          css={css({
            display: "flex",
            marginTop: 5,
            "& > button": {
              flex: 1,
              "&:first-of-type": { marginRight: 3 },
              "&:last-of-type": { marginLeft: 3 },
            },
          })}
        >
          <Button color="green" onClick={acceptMatch}>
            Accept
          </Button>
          <Button color="gray" onClick={declineMatch}>
            Ignore
          </Button>
        </div>
      </PaneItem>
    </Pane>
  );
};
