import React from "react";
import { css } from "@emotion/core";
import { formatDistance } from "date-fns";
import styled from "@emotion/styled";
import { CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews } from "./__generated__/CONVERSATIONS_TYPE_CONVERSATION_PREVIEW";

type Props = {
  conversationPreview: CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews;
  isSelected: boolean;
  onSelect: () => void;
  previewMovedMessage: string | undefined;
};

type PreviewCardProps = {
  isSelected?: boolean;
  isMoved?: boolean;
};

const PreviewWrapper = styled.div<PreviewCardProps>`
  display: flex;
  flex-direction: column;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #c0c0c0;

  ${({ isSelected, isMoved }) => {
    if (isMoved) {
      return css`
        background-color: #e8e8e8;
      `;
    }
    if (isSelected) {
      return css`
        background-color: #b8daf2;
      `;
    }
    return css`
      background-color: white;
      &:hover {
        background-color: #e3f1fa;
      }
    `;
  }}

  &:hover {
    cursor: pointer;
  }
`;

const PreviewCard = styled.button<PreviewCardProps>`
  padding: 15px;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: none;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
`;

const UnreadIndicatorContainer = styled.div({
  width: "30px",
  marginLeft: "-15px",
  display: "flex",
  justifyContent: "center",
});

const UnreadIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f06827;
`;

const AvatarImage = styled.img({
  borderRadius: "50%",
  marginRight: 15,
  backgroundColor: "#ccc",
  height: 30,
  width: 30,
});

const MessageSection = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  width: "75%",
});

type MessageHeaderProps = {
  isMoved?: boolean;
};
const MessageHeader = styled.div<MessageHeaderProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 5px;
  color: ${({ isMoved }) => (isMoved ? "gray" : "black")};
`;

type MessagePreviewProps = {
  isOutgoing: boolean;
  isUnread: boolean;
};
const MessagePreview = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${(p: MessagePreviewProps) => (p.isOutgoing ? "gray" : "black")};
  font-style: ${(p: MessagePreviewProps) => (p.isOutgoing ? "italic" : "normal")};
  font-weight: ${(p: MessagePreviewProps) => {
    if (p.isOutgoing) {
      return 100;
    }
    if (p.isUnread) {
      return "bold";
    }
    return "regular";
  }};
`;

const PreviewMovedMessage = styled.div`
  font-weight: 100;
  color: gray;
  font-style: italic;
  font-size: 12px;
`;

export const isPreviewUnread = (
  preview: CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews
) => {
  const { latestMessage, lastRead } = preview;
  const { createdAt, outgoing } = latestMessage;

  return !outgoing && lastRead && lastRead < createdAt;
};

export default ({ conversationPreview, isSelected, onSelect, previewMovedMessage }: Props) => {
  const { latestMessage, profile } = conversationPreview;
  const { message, createdAt, outgoing } = latestMessage;

  const lastMessageSentAt = formatDistance(new Date(createdAt), new Date())
    .replace("about ", "")
    .replace("less than a minute", "now");

  const isUnread = isPreviewUnread(conversationPreview);
  const isMoved = !!previewMovedMessage;

  return (
    <PreviewWrapper isSelected={isSelected} isMoved={isMoved}>
      <PreviewCard onClick={() => onSelect()}>
        <UnreadIndicatorContainer>{isUnread && <UnreadIndicator />}</UnreadIndicatorContainer>

        <AvatarImage src={profile.user.avatarUrl} alt="candidate avatar" />

        <MessageSection>
          <MessageHeader isMoved={isMoved}>
            <div css={css({ fontWeight: "bold" })}>{profile.user.name}</div>
            <div>{lastMessageSentAt}</div>
          </MessageHeader>
          {previewMovedMessage ? (
            <PreviewMovedMessage>{previewMovedMessage}</PreviewMovedMessage>
          ) : (
            <MessagePreview isUnread={isUnread} isOutgoing={outgoing}>
              {message}
            </MessagePreview>
          )}
        </MessageSection>
      </PreviewCard>
    </PreviewWrapper>
  );
};
