import { gql, useMutation } from "@apollo/client";
import React from "react";
import { RATE_USER_INF, RATE_USER_INFVariables } from "./__generated__/RATE_USER_INF";
import { UserAdminActionFragment } from "../cofounderMatching/fragments";
import { VoteButton } from "./SingleAdminCofounderProfile";

type Props = {
  userId: string;
  inf: boolean;
  reason?: string;
  onRatingInf?: () => void;
};

export default ({ userId, inf, reason, onRatingInf }: Props) => {
  const [rateUserInf] = useMutation<RATE_USER_INF, RATE_USER_INFVariables>(gql`
    mutation RATE_USER_INF($userId: ID!, $inf: Boolean!, $reason: String) {
      adminRateUserInf(id: $userId, inf: $inf, reason: $reason) {
        id
        slug
        adminRating
        adminRatingInf
        votes {
          ...UserAdminActionFragment
        }
      }
    }
    ${UserAdminActionFragment}
  `);

  const newInf = !inf;

  return (
    <VoteButton
      key="inf"
      isSelected={inf}
      color="#21BA45"
      onClick={async () => {
        await rateUserInf({
          variables: { userId, inf: newInf, reason },
        });
        if (newInf && onRatingInf) onRatingInf();
      }}
    >
      inf
    </VoteButton>
  );
};
