/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CompletableAction {
  add_co_founders = "add_co_founders",
  dashboard_tour = "dashboard_tour",
  dismiss_2022_course = "dismiss_2022_course",
  dismiss_cofounder_matching = "dismiss_cofounder_matching",
  dismiss_kickoff = "dismiss_kickoff",
  dismiss_survey_104 = "dismiss_survey_104",
  dismiss_survey_112 = "dismiss_survey_112",
  dismiss_survey_120 = "dismiss_survey_120",
  dismiss_survey_128 = "dismiss_survey_128",
  dismiss_survey_136 = "dismiss_survey_136",
  dismiss_survey_144 = "dismiss_survey_144",
  dismiss_survey_152 = "dismiss_survey_152",
  dismiss_survey_16 = "dismiss_survey_16",
  dismiss_survey_160 = "dismiss_survey_160",
  dismiss_survey_24 = "dismiss_survey_24",
  dismiss_survey_32 = "dismiss_survey_32",
  dismiss_survey_4 = "dismiss_survey_4",
  dismiss_survey_40 = "dismiss_survey_40",
  dismiss_survey_48 = "dismiss_survey_48",
  dismiss_survey_56 = "dismiss_survey_56",
  dismiss_survey_64 = "dismiss_survey_64",
  dismiss_survey_72 = "dismiss_survey_72",
  dismiss_survey_8 = "dismiss_survey_8",
  dismiss_survey_80 = "dismiss_survey_80",
  dismiss_survey_88 = "dismiss_survey_88",
  dismiss_survey_96 = "dismiss_survey_96",
}

export interface AdminUpdateEventInput {
  endsAt?: string | null;
  locationForGeocoding?: string | null;
  registrationClosesAt?: string | null;
  startsAt?: string | null;
  eventType?: string | null;
  title?: string | null;
  friendlyName?: string | null;
  slug?: string | null;
  attendeeType?: string | null;
  description?: string | null;
  publicLocation?: string | null;
  address?: string | null;
  instructions?: string | null;
  capacity?: number | null;
  finalAttendance?: number | null;
  cancelled?: boolean | null;
  socialImageUrl?: string | null;
  cost?: number | null;
  remindToApply?: boolean | null;
  linkedinRequired?: boolean | null;
  landingPageDisabled?: boolean | null;
  inviteExpirationDisabled?: boolean | null;
}

export interface AdminUpdateMeetupOfficeHoursInput {
  description?: string | null;
  endsAt?: string | null;
  id?: string | null;
  location?: string | null;
  owner2Id?: string | null;
  ownerId?: string | null;
  startsAt?: string | null;
}

export interface AsfUpdateProfileInput {
  hopeToLearn?: string | null;
  ideas?: string | null;
  when?: string | null;
}

export interface BrowseCfmProfilesInput {
  active?: boolean | null;
  appliedToLatestBatch?: boolean | null;
  hasIdea: string[];
  interests: string[];
  keyword?: string | null;
  maxAge?: number | null;
  maxCreated?: any | null;
  maxLastActive?: any | null;
  maxMatches?: number | null;
  maxRequestsReceived?: number | null;
  maxRequestsSent?: number | null;
  minAge?: number | null;
  minCreated?: any | null;
  minLastActive?: any | null;
  minMatches?: number | null;
  minRequestsReceived?: number | null;
  minRequestsSent?: number | null;
  region?: string[] | null;
  responsibilities: string[];
  superRegion?: string[] | null;
  timing: string[];
}

export interface BrowseUsersInput {
  appliedToLatestBatch?: boolean | null;
  approvedForDeals?: boolean | null;
  companyCategory?: string[] | null;
  companyStage?: string[] | null;
  inBuildSprint?: boolean | null;
  inCourse?: boolean | null;
  inclusiveGroups?: string[] | null;
  location?: string | null;
  locationRadius?: string | null;
  maxAge?: number | null;
  maxCreated?: any | null;
  maxLastActive?: any | null;
  minAge?: number | null;
  minCreated?: any | null;
  minLastActive?: any | null;
  omitRejectedFromTwoEvents?: boolean | null;
  primaryMetric?: string[] | null;
  ratings?: string[] | null;
  region?: string[] | null;
  superRegion?: string[] | null;
  technical?: boolean | null;
  track?: string | null;
  woman?: boolean | null;
}

export interface CfmBlockProfileInput {
  other?: string | null;
  reason: string;
  slug: string;
}

export interface CfmCreateSpeedDatingEventInput {
  customTitle?: string | null;
  endsAt: any;
  eventType: string;
  icebreakers?: string[] | null;
  industry?: string | null;
  location?: string | null;
  noIdeaOnly?: boolean | null;
  ratings?: string[] | null;
  sameCountry?: boolean | null;
  startsAt: any;
  technicalOnly?: boolean | null;
  ycAlumsOnly?: boolean | null;
  ycStaffOnly?: boolean | null;
}

export interface CfmUpdateProfileInput {
  active?: boolean | null;
  calendlyLink?: string | null;
  companyProgress?: string | null;
  currentCfLinkedin?: string | null;
  currentCfTechnical?: boolean | null;
  education?: string | null;
  employment?: string | null;
  equity?: string | null;
  freeTime?: string | null;
  hasCf?: boolean | null;
  hasIdea?: string | null;
  howHeard?: string | null;
  ideas?: string | null;
  instagramLink?: string | null;
  interests?: string[] | null;
  intro?: string | null;
  lifeStory?: string | null;
  other?: string | null;
  reqFreeText?: string | null;
  responsibilities?: string[] | null;
  timing?: string | null;
  twitterLink?: string | null;
  videoLink?: string | null;
  whyLookingForThirdCf?: string | null;
  cfAgeImportance?: string | null;
  cfAgeMax?: number | null;
  cfAgeMin?: number | null;
  cfHasIdea?: boolean | null;
  cfHasIdeaImportance?: string | null;
  cfInterestsImportance?: string | null;
  cfIsFellowAlumniImportance?: string | null;
  cfIsTechnical?: boolean | null;
  cfIsTechnicalImportance?: string | null;
  cfIsWomanImportance?: string | null;
  cfIsYcAlumImportance?: string | null;
  cfLocation?: string | null;
  cfLocationImportance?: string | null;
  cfLocationKmRange?: number | null;
  cfResponsibilities?: string[] | null;
  cfResponsibilitiesImportance?: string | null;
  cfTimingImportance?: string | null;
}

export interface CfmUpdateSettingsInput {
  active?: boolean | null;
  committedCofounderSlugs?: string[] | null;
  emailSettings?: string[] | null;
  hideAge?: boolean | null;
  inactiveReason?: string | null;
}

export interface CfmUpdateTrialInput {
  commitment?: string | null;
  description?: string | null;
  endDate?: any | null;
  goals?: string | null;
  ownership?: string | null;
  startDate?: any | null;
}

export interface CreateOrUpdateEmailPushInput {
  autoLoginLinks?: boolean | null;
  campaignId?: string | null;
  disabled?: boolean | null;
  emailBody?: string | null;
  filters?: string | null;
  fromEmail?: string | null;
  harmonicSavedSearchId?: number | null;
  id?: string | null;
  name?: string | null;
  recipients?: ExternalRecipientInput[] | null;
  replyToEmail?: string | null;
  sendsAt?: any | null;
  subject?: string | null;
}

export interface DirectorySearchInput {
  ageMax?: number | null;
  ageMin?: number | null;
  excludeSkipped?: boolean | null;
  fellowAlumniOnly?: boolean | null;
  hasIdea?: string[] | null;
  interests?: string[] | null;
  keyword?: string | null;
  locations?: string[] | null;
  page?: number | null;
  regions?: string[] | null;
  responsibilities?: string[] | null;
  technical?: boolean[] | null;
  timing?: string[] | null;
  womenOnly?: boolean | null;
  ycAlumOnly?: boolean | null;
}

export interface ExternalRecipientInput {
  email: string;
  firstName: string;
}

export interface FollowerAttributes {
  email?: string | null;
  name?: string | null;
}

export interface QuestionResponseInput {
  questionId: string;
  response: string;
}

export interface UpdateCompanyInput {
  slug?: string | null;
  name?: string | null;
  url?: string | null;
  description?: string | null;
  verticalId?: number | null;
  why?: string | null;
  commitment?: string | null;
  stage?: string | null;
  incorporated?: boolean | null;
  progress?: string | null;
  fundingStatus?: string | null;
  showInDirectory?: boolean | null;
}

export interface UpdateMeetupQuestionInput {
  meetupId?: number | null;
  questionText?: string | null;
  questionSubtitle?: string | null;
  questionType?: string | null;
  required?: boolean | null;
  sortOrder?: number | null;
  otherRequirements?: string | null;
  questionId?: string | null;
}

export interface UpdateMeetupRsvpInput {
  confirm?: boolean | null;
  confirmedUsCitizen?: boolean | null;
  decline?: boolean | null;
  hasIdea?: string | null;
  ideaOneLiner?: string | null;
  joinToken?: string | null;
  joinWaitlist?: boolean | null;
  leaveWaitlist?: boolean | null;
  ohSlotStart?: string | null;
  personalOneLiner?: string | null;
  questionResponses?: QuestionResponseInput[] | null;
  referrerSlug?: string | null;
  slug: string;
}

export interface UpdateSsoUserInput {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  linkedin?: string | null;
}

export interface UpdateUserInput {
  location?: string | null;
  education?: string | null;
  employment?: string | null;
  emailSettings?: string[] | null;
  expertise?: string | null;
  gender?: string | null;
  birthdate?: any | null;
  howHeardFreeText?: string | null;
  howHeard?: string | null;
  impressiveThing?: string | null;
  isTechnical?: boolean | null;
  noLinkedin?: boolean | null;
  seekingCofounder?: boolean | null;
  track?: string | null;
  showYcFounder?: boolean | null;
  utmSource?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
  twitterLink?: string | null;
  instagramLink?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
