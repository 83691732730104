/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /rails/mailers(.:format) */
export function railsMailersPath(options?: object): string {
  return "/" + "rails" + "/" + "mailers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/properties(.:format) */
export function railsInfoPropertiesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/routes(.:format) */
export function railsInfoRoutesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "routes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info(.:format) */
export function railsInfoPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /oauth/token(.:format) */
export function oauthTokenPath(options?: object): string {
  return "/" + "oauth" + "/" + "token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /oauth/revoke(.:format) */
export function oauthRevokePath(options?: object): string {
  return "/" + "oauth" + "/" + "revoke" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /oauth/introspect(.:format) */
export function oauthIntrospectPath(options?: object): string {
  return "/" + "oauth" + "/" + "introspect" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /oauth/token/info(.:format) */
export function oauthTokenInfoPath(options?: object): string {
  return "/" + "oauth" + "/" + "token" + "/" + "info" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /graphiql */
export function graphiqlRailsPath(options?: object): string {
  return "/" + "graphiql" + $buildOptions(options, []);
}

/** /graphql(.:format) */
export function graphqlPath(options?: object): string {
  return "/" + "graphql" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /media(.:format) */
export function mediaPath(options?: object): string {
  return "/" + "media" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /meetups/qr(.:format) */
export function qrMeetupsPath(options?: object): string {
  return "/" + "meetups" + "/" + "qr" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /meetups/:id(.:format) */
export function meetupPath(id: ScalarType, options?: object): string {
  return "/" + "meetups" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /users/sign_up(.:format) */
export function signUpUsersPath(options?: object): string {
  return "/" + "users" + "/" + "sign_up" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_in(.:format) */
export function signInUsersPath(options?: object): string {
  return "/" + "users" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_out(.:format) */
export function signOutUsersPath(options?: object): string {
  return "/" + "users" + "/" + "sign_out" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/:id(.:format) */
export function userPath(id: ScalarType, options?: object): string {
  return "/" + "users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /:id(.:format) */
export function showEventPath(id: ScalarType, options?: object): string {
  return "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /users/:id/certificate(.:format) */
export function certificateUserPath(id: ScalarType, options?: object): string {
  return "/" + "users" + "/" + id + "/" + "certificate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /users/:id/ff-certificate(.:format) */
export function asfCertificateUserPath(id: ScalarType, options?: object): string {
  return "/" + "users" + "/" + id + "/" + "ff-certificate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /users/delete_user(.:format) */
export function deleteUserUsersPath(options?: object): string {
  return "/" + "users" + "/" + "delete_user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/update_avatar(.:format) */
export function updateAvatarUsersPath(options?: object): string {
  return "/" + "users" + "/" + "update_avatar" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/edit(.:format) */
export function editUsersPath(options?: object): string {
  return "/" + "users" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/edit_sign_up(.:format) */
export function editSignUpUsersPath(options?: object): string {
  return "/" + "users" + "/" + "edit_sign_up" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/unsuppress_email(.:format) */
export function unsuppressEmailUsersPath(options?: object): string {
  return "/" + "users" + "/" + "unsuppress_email" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/choose_kickoff_call(.:format) */
export function chooseKickoffCallUsersPath(options?: object): string {
  return "/" + "users" + "/" + "choose_kickoff_call" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/unsubscribe/:token(.:format) */
export function unsubscribeUsersPath(token: ScalarType, options?: object): string {
  return "/" + "users" + "/" + "unsubscribe" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /users/resubscribe/:token(.:format) */
export function resubscribeUsersPath(token: ScalarType, options?: object): string {
  return "/" + "users" + "/" + "resubscribe" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /users/confirm_sanction_requirement(.:format) */
export function confirmSanctionRequirementUsersPath(options?: object): string {
  return "/" + "users" + "/" + "confirm_sanction_requirement" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/generate_auto_login_token(.:format) */
export function generateAutoLoginTokenUsersPath(options?: object): string {
  return "/" + "users" + "/" + "generate_auto_login_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /courses/:id/schedule(.:format) */
export function scheduleCoursePath(id: ScalarType, options?: object): string {
  return "/" + "courses" + "/" + id + "/" + "schedule" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /courses/:id/create_event(.:format) */
export function createEventCoursePath(id: ScalarType, options?: object): string {
  return "/" + "courses" + "/" + id + "/" + "create_event" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /courses/:id/delete_event(.:format) */
export function deleteEventCoursePath(id: ScalarType, options?: object): string {
  return "/" + "courses" + "/" + id + "/" + "delete_event" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /courses/:id/grant_winners(.:format) */
export function grantWinnersCoursePath(id: ScalarType, options?: object): string {
  return "/" + "courses" + "/" + id + "/" + "grant_winners" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /courses/:id/register(.:format) */
export function registerCoursePath(id: ScalarType, options?: object): string {
  return "/" + "courses" + "/" + id + "/" + "register" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /courses/:id/confirm_registration(.:format) */
export function confirmRegistrationCoursePath(id: ScalarType, options?: object): string {
  return "/" + "courses" + "/" + id + "/" + "confirm_registration" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /courses/register(.:format) */
export function registerCoursesPath(options?: object): string {
  return "/" + "courses" + "/" + "register" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /courses/founder-social/:id(.:format) */
export function founderSocialCoursesPath(id: ScalarType, options?: object): string {
  return "/" + "courses" + "/" + "founder-social" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /company/remove_cofounder(.:format) */
export function removeCofounderCompanyPath(options?: object): string {
  return "/" + "company" + "/" + "remove_cofounder" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /company/edit(.:format) */
export function editCompanyPath(options?: object): string {
  return "/" + "company" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /aspiring_founder_profile(.:format) */
export function aspiringFounderProfilePath(options?: object): string {
  return "/" + "aspiring_founder_profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /track/submit_redirect(.:format) */
export function submitRedirectTrackPath(options?: object): string {
  return "/" + "track" + "/" + "submit_redirect" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /track(.:format) */
export function trackPath(options?: object): string {
  return "/" + "track" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/dashboard(.:format) */
export function dashboardCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/profile(.:format) */
export function profileCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/my-profile(.:format) */
export function myProfileCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "my-profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/settings(.:format) */
export function settingsCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/candidate/:id(.:format) */
export function candidateCofounderMatchingPath(id: ScalarType, options?: object): string {
  return "/" + "cofounder-matching" + "/" + "candidate" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /cofounder-matching/directory(.:format) */
export function directoryCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "directory" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/saved-profiles(.:format) */
export function savedProfilesCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "saved-profiles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/skipped-profiles(.:format) */
export function skippedProfilesCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "skipped-profiles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/delete-account(.:format) */
export function deleteAccountCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "delete-account" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/inbox(.:format) */
export function inboxCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "inbox" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/trials(.:format) */
export function trialsCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "trials" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/speed-dating(.:format) */
export function speedDatingCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "speed-dating" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/speed-dating/:id(.:format) */
export function speedDatingEventCofounderMatchingPath(id: ScalarType, options?: object): string {
  return "/" + "cofounder-matching" + "/" + "speed-dating" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /cofounder-matching/trial/:id(.:format) */
export function trialCofounderMatchingPath(id: ScalarType, options?: object): string {
  return "/" + "cofounder-matching" + "/" + "trial" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /cofounder-matching/suspension-appeal(.:format) */
export function suspensionAppealCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "suspension-appeal" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/behavior_guidelines(.:format) */
export function behaviorGuidelinesCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "behavior_guidelines" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/meetups(.:format) */
export function meetupsCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "meetups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/meetup/:id(.:format) */
export function meetupCofounderMatchingPath(id: ScalarType, options?: object): string {
  return "/" + "cofounder-matching" + "/" + "meetup" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /cofounder-matching/response_needed(.:format) */
export function responseNeededCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "response_needed" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/instant-match/:id(.:format) */
export function instantMatchCofounderMatchingPath(id: ScalarType, options?: object): string {
  return "/" + "cofounder-matching" + "/" + "instant-match" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /cofounder-matching/update-request/:id(.:format) */
export function updateRequestCofounderMatchingPath(id: ScalarType, options?: object): string {
  return "/" + "cofounder-matching" + "/" + "update-request" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /cofounder-matching/announcement-link/:id(.:format) */
export function announcementLinkCofounderMatchingPath(id: ScalarType, options?: object): string {
  return "/" + "cofounder-matching" + "/" + "announcement-link" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /cofounder-matching/founders-you-may-know(.:format) */
export function foundersYouMayKnowCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "founders-you-may-know" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/nominate(.:format) */
export function nominateCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "nominate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/endorsements(.:format) */
export function endorsementsCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "endorsements" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/profile_review(.:format) */
export function profileReviewCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "profile_review" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching/rater_data(.:format) */
export function raterDataCofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + "/" + "rater_data" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /cofounder-matching(.:format) */
export function cofounderMatchingPath(options?: object): string {
  return "/" + "cofounder-matching" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kickoff-calls/founder-meetings(.:format) */
export function founderMeetingsKickoffCallsPath(options?: object): string {
  return "/" + "kickoff-calls" + "/" + "founder-meetings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kickoff-calls/founder-meetings/:id(.:format) */
export function founderMeetingEventKickoffCallsPath(id: ScalarType, options?: object): string {
  return "/" + "kickoff-calls" + "/" + "founder-meetings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kickoff-calls/new(.:format) */
export function newKickoffCallsPath(options?: object): string {
  return "/" + "kickoff-calls" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kickoff-calls/edit(.:format) */
export function editKickoffCallsPath(options?: object): string {
  return "/" + "kickoff-calls" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kickoff-calls(.:format) */
export function kickoffCallsPath(options?: object): string {
  return "/" + "kickoff-calls" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /invites/manage(.:format) */
export function manageInvitesPath(options?: object): string {
  return "/" + "invites" + "/" + "manage" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /invites(.:format) */
export function invitesPath(options?: object): string {
  return "/" + "invites" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /invites/:id(.:format) */
export function invitePath(id: ScalarType, options?: object): string {
  return "/" + "invites" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /group_sessions/join(.:format) */
export function joinGroupSessionsPath(options?: object): string {
  return "/" + "group_sessions" + "/" + "join" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /group_sessions/:id/peer_feedback(.:format) */
export function peerFeedbackGroupSessionPath(id: ScalarType, options?: object): string {
  return "/" + "group_sessions" + "/" + id + "/" + "peer_feedback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /group_sessions/:id/feedback(.:format) */
export function feedbackGroupSessionPath(id: ScalarType, options?: object): string {
  return "/" + "group_sessions" + "/" + id + "/" + "feedback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /group_sessions/:id/attendance(.:format) */
export function attendanceGroupSessionPath(id: ScalarType, options?: object): string {
  return "/" + "group_sessions" + "/" + id + "/" + "attendance" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /group_sessions/:id/merge(.:format) */
export function mergeGroupSessionPath(id: ScalarType, options?: object): string {
  return "/" + "group_sessions" + "/" + id + "/" + "merge" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /group_sessions(.:format) */
export function groupSessionsPath(options?: object): string {
  return "/" + "group_sessions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /group_sessions/:id(.:format) */
export function groupSessionPath(id: ScalarType, options?: object): string {
  return "/" + "group_sessions" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /updates/follow/:slug(.:format) */
export function viewAsFollowerUpdatesPath(slug: ScalarType, options?: object): string {
  return "/" + "updates" + "/" + "follow" + "/" + slug + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["slug","format"]);
}

/** /updates/follow/:slug/remove(.:format) */
export function removeFollowerUpdatesPath(slug: ScalarType, options?: object): string {
  return "/" + "updates" + "/" + "follow" + "/" + slug + "/" + "remove" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["slug","format"]);
}

/** /updates(.:format) */
export function updatesPath(options?: object): string {
  return "/" + "updates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /updates/new(.:format) */
export function newUpdatePath(options?: object): string {
  return "/" + "updates" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /updates/:id/edit(.:format) */
export function editUpdatePath(id: ScalarType, options?: object): string {
  return "/" + "updates" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /updates/:id(.:format) */
export function updatePath(id: ScalarType, options?: object): string {
  return "/" + "updates" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /letter_opener */
export function letterOpenerWebPath(options?: object): string {
  return "/" + "letter_opener" + $buildOptions(options, []);
}

/** /letter_opener/ */
export function letterOpenerWebLettersPath(options?: object): string {
  return "/" + "letter_opener" + "/" + $buildOptions(options, []);
}

/** /letter_opener/clear(.:format) */
export function letterOpenerWebClearLettersPath(options?: object): string {
  return "/" + "letter_opener" + "/" + "clear" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /letter_opener/:id(/:style)(.:format) */
export function letterOpenerWebLetterPath(id: ScalarType, options?: object): string {
  return "/" + "letter_opener" + "/" + id + ($hasPresentOwnProperty(options, "style") ? "/" + (options as any).style : "") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","style","format"]);
}

/** /letter_opener/:id/delete(.:format) */
export function letterOpenerWebDeleteLetterPath(id: ScalarType, options?: object): string {
  return "/" + "letter_opener" + "/" + id + "/" + "delete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /sidekiq */
export function sidekiqWebPath(options?: object): string {
  return "/" + "sidekiq" + $buildOptions(options, []);
}

/** /admin/users/by_uuid/:uuid(.:format) */
export function byUuidAdminUsersPath(uuid: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + "by_uuid" + "/" + uuid + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["uuid","format"]);
}

/** /admin/users/by_slug/:slug(.:format) */
export function bySlugAdminUsersPath(slug: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + "by_slug" + "/" + slug + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["slug","format"]);
}

/** /admin/users/kickoff(.:format) */
export function kickoffAdminUsersPath(options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + "kickoff" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/users/mark_kickoff(.:format) */
export function markKickoffAdminUsersPath(options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + "mark_kickoff" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/users/browse(.:format) */
export function browseAdminUsersPath(options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + "browse" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/users/rating_review(.:format) */
export function ratingReviewAdminUsersPath(options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + "rating_review" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/users/review_users(.:format) */
export function reviewUsersAdminUsersPath(options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + "review_users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/users/:id/impersonate(.:format) */
export function impersonateAdminUserPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + id + "/" + "impersonate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/users/:id/merge(.:format) */
export function mergeAdminUserPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + id + "/" + "merge" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/users/export(.:format) */
export function exportAdminUsersPath(options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/users(.:format) */
export function adminUsersPath(options?: object): string {
  return "/" + "admin" + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/users/new(.:format) */
export function newAdminUserPath(options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/users/:id/edit(.:format) */
export function editAdminUserPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/users/:id(.:format) */
export function adminUserPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/company_messages(.:format) */
export function adminCompanyMessagesPath(options?: object): string {
  return "/" + "admin" + "/" + "company_messages" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/deals_applications/approve_all(.:format) */
export function approveAllAdminDealsApplicationsPath(options?: object): string {
  return "/" + "admin" + "/" + "deals_applications" + "/" + "approve_all" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/deals_applications/:id/approve(.:format) */
export function approveAdminDealsApplicationPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "deals_applications" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/deals_applications/:id/deny(.:format) */
export function denyAdminDealsApplicationPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "deals_applications" + "/" + id + "/" + "deny" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/deals_applications(.:format) */
export function adminDealsApplicationsPath(options?: object): string {
  return "/" + "admin" + "/" + "deals_applications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/yc_apps(.:format) */
export function adminYCAppsPath(options?: object): string {
  return "/" + "admin" + "/" + "yc_apps" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/cofounder_matching/manage_speed_dating_events(.:format) */
export function manageSpeedDatingEventsAdminCofounderMatchingPath(options?: object): string {
  return "/" + "admin" + "/" + "cofounder_matching" + "/" + "manage_speed_dating_events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/cofounder_matching/rejected_profiles(.:format) */
export function rejectedProfilesAdminCofounderMatchingPath(options?: object): string {
  return "/" + "admin" + "/" + "cofounder_matching" + "/" + "rejected_profiles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/cofounder_matching/Y_profiles(.:format) */
export function yProfilesAdminCofounderMatchingPath(options?: object): string {
  return "/" + "admin" + "/" + "cofounder_matching" + "/" + "Y_profiles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/cofounder_matching/top_inviters(.:format) */
export function topInvitersAdminCofounderMatchingPath(options?: object): string {
  return "/" + "admin" + "/" + "cofounder_matching" + "/" + "top_inviters" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/cofounder_matching/admin_queue(.:format) */
export function adminQueueAdminCofounderMatchingPath(options?: object): string {
  return "/" + "admin" + "/" + "cofounder_matching" + "/" + "admin_queue" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/cofounder_matching/suspension_appeals(.:format) */
export function suspensionAppealsAdminCofounderMatchingPath(options?: object): string {
  return "/" + "admin" + "/" + "cofounder_matching" + "/" + "suspension_appeals" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/cofounder_matching/:id(.:format) */
export function profileAdminCofounderMatchingPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "cofounder_matching" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/cofounder_matching/by_slug/:slug(.:format) */
export function bySlugAdminCofounderMatchingPath(slug: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "cofounder_matching" + "/" + "by_slug" + "/" + slug + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["slug","format"]);
}

/** /admin/cofounder_matching/speed-dating/:id(.:format) */
export function speedDatingAdminCofounderMatchingPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "cofounder_matching" + "/" + "speed-dating" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/cofounder_matching/by_user_id/:id(.:format) */
export function profileByUserIdAdminCofounderMatchingPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "cofounder_matching" + "/" + "by_user_id" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies/:id/set_primary_founder(.:format) */
export function setPrimaryFounderAdminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + id + "/" + "set_primary_founder" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies/:id/remove_founder(.:format) */
export function removeFounderAdminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + id + "/" + "remove_founder" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies/:id/merge(.:format) */
export function mergeAdminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + id + "/" + "merge" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies/:id/clear_missed_group_sessions(.:format) */
export function clearMissedGroupSessionsAdminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + id + "/" + "clear_missed_group_sessions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies/:id/approve_for_deals(.:format) */
export function approveForDealsAdminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + id + "/" + "approve_for_deals" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies(.:format) */
export function adminCompaniesPath(options?: object): string {
  return "/" + "admin" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/companies/:id/edit(.:format) */
export function editAdminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies/:id(.:format) */
export function adminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/group_sessions/at_time/:time(.:format) */
export function atTimeAdminGroupSessionsPath(time: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "group_sessions" + "/" + "at_time" + "/" + time + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["time","format"]);
}

/** /admin/group_sessions/recording/:id(.:format) */
export function recordingAdminGroupSessionsPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "group_sessions" + "/" + "recording" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/group_sessions(.:format) */
export function adminGroupSessionsPath(options?: object): string {
  return "/" + "admin" + "/" + "group_sessions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/group_session_topics(.:format) */
export function adminGroupSessionTopicsPath(options?: object): string {
  return "/" + "admin" + "/" + "group_session_topics" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/group_session_topics/new(.:format) */
export function newAdminGroupSessionTopicPath(options?: object): string {
  return "/" + "admin" + "/" + "group_session_topics" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/group_session_topics/:id/edit(.:format) */
export function editAdminGroupSessionTopicPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "group_session_topics" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/group_session_topics/:id(.:format) */
export function adminGroupSessionTopicPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "group_session_topics" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/kickoff_calls(.:format) */
export function adminKickoffCallsPath(options?: object): string {
  return "/" + "admin" + "/" + "kickoff_calls" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/kickoff_calls/new(.:format) */
export function newAdminKickoffCallPath(options?: object): string {
  return "/" + "admin" + "/" + "kickoff_calls" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/kickoff_calls/:id/edit(.:format) */
export function editAdminKickoffCallPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "kickoff_calls" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/kickoff_calls/:id(.:format) */
export function adminKickoffCallPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "kickoff_calls" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/courses/showcase(.:format) */
export function showcaseAdminCoursesPath(options?: object): string {
  return "/" + "admin" + "/" + "courses" + "/" + "showcase" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/courses/showcase_slides(.:format) */
export function showcaseSlidesAdminCoursesPath(options?: object): string {
  return "/" + "admin" + "/" + "courses" + "/" + "showcase_slides" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/courses/showcase_slot/:id(.:format) */
export function showcaseSlotAdminCoursesPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "courses" + "/" + "showcase_slot" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/courses/set_moderator/:group_id/:user_id(.:format) */
export function setModeratorAdminCoursesPath(group_id: ScalarType, user_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "courses" + "/" + "set_moderator" + "/" + group_id + "/" + user_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["group_id","user_id","format"]);
}

/** /admin/courses/approve_slides(.:format) */
export function approveSlidesAdminCoursesPath(options?: object): string {
  return "/" + "admin" + "/" + "courses" + "/" + "approve_slides" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/courses/:id/deny_slide_or_warn(.:format) */
export function denySlideOrWarnAdminCoursePath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "courses" + "/" + id + "/" + "deny_slide_or_warn" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/courses/:id(.:format) */
export function adminCoursePath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "courses" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/meetups/locations/:id(.:format) */
export function locationsAdminMeetupsPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "meetups" + "/" + "locations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/meetups/nametags/:id(.:format) */
export function nametagsAdminMeetupsPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "meetups" + "/" + "nametags" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/meetups/prospects/:id(.:format) */
export function prospectsAdminMeetupsPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "meetups" + "/" + "prospects" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/meetups/waitlist-bypass-urls/:id(.:format) */
export function waitlistBypassUrlsAdminMeetupsPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "meetups" + "/" + "waitlist-bypass-urls" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/meetups/app-stats/all(.:format) */
export function allAppStatsAdminMeetupsPath(options?: object): string {
  return "/" + "admin" + "/" + "meetups" + "/" + "app-stats" + "/" + "all" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/meetups/app-stats/:id(.:format) */
export function appStatsAdminMeetupsPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "meetups" + "/" + "app-stats" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/meetups/incremental-queue(.:format) */
export function incrementalQueueAdminMeetupsPath(options?: object): string {
  return "/" + "admin" + "/" + "meetups" + "/" + "incremental-queue" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/meetups(.:format) */
export function adminMeetupsPath(options?: object): string {
  return "/" + "admin" + "/" + "meetups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/meetups/new(.:format) */
export function newAdminMeetupPath(options?: object): string {
  return "/" + "admin" + "/" + "meetups" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/meetups/:id/edit(.:format) */
export function editAdminMeetupPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "meetups" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/meetups/:id(.:format) */
export function adminMeetupPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "meetups" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/email_campaigns/:id/new_push(.:format) */
export function newPushAdminEmailCampaignPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "email_campaigns" + "/" + id + "/" + "new_push" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/email_campaigns/:id/edit_push/:push_id(.:format) */
export function editPushAdminEmailCampaignPath(id: ScalarType, push_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "email_campaigns" + "/" + id + "/" + "edit_push" + "/" + push_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","push_id","format"]);
}

/** /admin/email_campaigns/:id/review_push/:push_id(.:format) */
export function reviewPushAdminEmailCampaignPath(id: ScalarType, push_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "email_campaigns" + "/" + id + "/" + "review_push" + "/" + push_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","push_id","format"]);
}

/** /admin/email_campaigns/:id/all_pushes(.:format) */
export function allPushesAdminEmailCampaignPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "email_campaigns" + "/" + id + "/" + "all_pushes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/email_campaigns(.:format) */
export function adminEmailCampaignsPath(options?: object): string {
  return "/" + "admin" + "/" + "email_campaigns" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/email_campaigns/new(.:format) */
export function newAdminEmailCampaignPath(options?: object): string {
  return "/" + "admin" + "/" + "email_campaigns" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/email_campaigns/:id/edit(.:format) */
export function editAdminEmailCampaignPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "email_campaigns" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/email_campaigns/:id(.:format) */
export function adminEmailCampaignPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "email_campaigns" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/announcements/:id/generate_user_announcements(.:format) */
export function generateUserAnnouncementsAdminAnnouncementPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "announcements" + "/" + id + "/" + "generate_user_announcements" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/announcements(.:format) */
export function adminAnnouncementsPath(options?: object): string {
  return "/" + "admin" + "/" + "announcements" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/announcements/new(.:format) */
export function newAdminAnnouncementPath(options?: object): string {
  return "/" + "admin" + "/" + "announcements" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/announcements/:id/edit(.:format) */
export function editAdminAnnouncementPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "announcements" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/announcements/:id(.:format) */
export function adminAnnouncementPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "announcements" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/telegram_groups(.:format) */
export function adminTelegramGroupsPath(options?: object): string {
  return "/" + "admin" + "/" + "telegram_groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/spectators/export(.:format) */
export function exportAdminSpectatorsPath(options?: object): string {
  return "/" + "admin" + "/" + "spectators" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/updates/:id(.:format) */
export function adminUpdatePath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "updates" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/verticals(.:format) */
export function adminVerticalsPath(options?: object): string {
  return "/" + "admin" + "/" + "verticals" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/verticals/new(.:format) */
export function newAdminVerticalPath(options?: object): string {
  return "/" + "admin" + "/" + "verticals" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/verticals/:id/edit(.:format) */
export function editAdminVerticalPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "verticals" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/verticals/:id(.:format) */
export function adminVerticalPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "verticals" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/curriculum_items(.:format) */
export function adminCurriculumItemsPath(options?: object): string {
  return "/" + "admin" + "/" + "curriculum_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/curriculum_items/new(.:format) */
export function newAdminCurriculumItemPath(options?: object): string {
  return "/" + "admin" + "/" + "curriculum_items" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/curriculum_items/:id/edit(.:format) */
export function editAdminCurriculumItemPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "curriculum_items" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/curriculum_items/:id(.:format) */
export function adminCurriculumItemPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "curriculum_items" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/raters/health(.:format) */
export function healthAdminRatersPath(options?: object): string {
  return "/" + "admin" + "/" + "raters" + "/" + "health" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/raters(.:format) */
export function adminRatersPath(options?: object): string {
  return "/" + "admin" + "/" + "raters" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/raters/:id(.:format) */
export function adminRaterPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "raters" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/rating(.:format) */
export function adminRatingPath(options?: object): string {
  return "/" + "admin" + "/" + "rating" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/search(.:format) */
export function adminQueryPath(options?: object): string {
  return "/" + "admin" + "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/*unmatched(.:format) */
export function adminPath(unmatched: ScalarType[], options?: object): string {
  return "/" + "admin" + "/" + unmatched.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["unmatched","format"]);
}

/** /api/companies(.:format) */
export function apiCompaniesPath(options?: object): string {
  return "/" + "api" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/cofounder_matchings/apps(.:format) */
export function appsApiCofounderMatchingsPath(options?: object): string {
  return "/" + "api" + "/" + "cofounder_matchings" + "/" + "apps" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/cofounder_matchings/import_recommendation(.:format) */
export function importRecommendationApiCofounderMatchingsPath(options?: object): string {
  return "/" + "api" + "/" + "cofounder_matchings" + "/" + "import_recommendation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/cofounder_matchings(.:format) */
export function apiCofounderMatchingsPath(options?: object): string {
  return "/" + "api" + "/" + "cofounder_matchings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/users/birthdate/:uuid(.:format) */
export function birthdateApiUsersPath(uuid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "users" + "/" + "birthdate" + "/" + uuid + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["uuid","format"]);
}

/** /api/users/us-like/:uuid(.:format) */
export function usLikeApiUsersPath(uuid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "users" + "/" + "us-like" + "/" + uuid + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["uuid","format"]);
}

/** /api/users/import_yc_founders(.:format) */
export function importYCFoundersApiUsersPath(options?: object): string {
  return "/" + "api" + "/" + "users" + "/" + "import_yc_founders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/users(.:format) */
export function apiUsersPath(options?: object): string {
  return "/" + "api" + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/users/new(.:format) */
export function newApiUserPath(options?: object): string {
  return "/" + "api" + "/" + "users" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/users/:id/edit(.:format) */
export function editApiUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "users" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/users/:id(.:format) */
export function apiUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/emails/register_delivered(.:format) */
export function registerDeliveredApiEmailsPath(options?: object): string {
  return "/" + "api" + "/" + "emails" + "/" + "register_delivered" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/emails/register_opened(.:format) */
export function registerOpenedApiEmailsPath(options?: object): string {
  return "/" + "api" + "/" + "emails" + "/" + "register_opened" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/emails/register_failure(.:format) */
export function registerFailureApiEmailsPath(options?: object): string {
  return "/" + "api" + "/" + "emails" + "/" + "register_failure" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/emails/register_link_clicked(.:format) */
export function registerLinkClickedApiEmailsPath(options?: object): string {
  return "/" + "api" + "/" + "emails" + "/" + "register_link_clicked" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/emails(.:format) */
export function apiEmailsPath(options?: object): string {
  return "/" + "api" + "/" + "emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/emails/new(.:format) */
export function newApiEmailPath(options?: object): string {
  return "/" + "api" + "/" + "emails" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/emails/:id/edit(.:format) */
export function editApiEmailPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "emails" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/emails/:id(.:format) */
export function apiEmailPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "emails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/meetups/sync_summer_conference_rsvps(.:format) */
export function syncSummerConferenceRsvpsApiMeetupsPath(options?: object): string {
  return "/" + "api" + "/" + "meetups" + "/" + "sync_summer_conference_rsvps" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/meetups(.:format) */
export function apiMeetupsPath(options?: object): string {
  return "/" + "api" + "/" + "meetups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/meetups/new(.:format) */
export function newApiMeetupPath(options?: object): string {
  return "/" + "api" + "/" + "meetups" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/meetups/:id/edit(.:format) */
export function editApiMeetupPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "meetups" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/meetups/:id(.:format) */
export function apiMeetupPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "meetups" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/start_memory_profiler(.:format) */
export function apiStartMemoryProfilerPath(options?: object): string {
  return "/" + "api" + "/" + "start_memory_profiler" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/stop_memory_profiler(.:format) */
export function apiStopMemoryProfilerPath(options?: object): string {
  return "/" + "api" + "/" + "stop_memory_profiler" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /zapier/find_cfm_profile(.:format) */
export function findCfmProfileZapierIndexPath(options?: object): string {
  return "/" + "zapier" + "/" + "find_cfm_profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /zapier/find_user(.:format) */
export function findUserZapierIndexPath(options?: object): string {
  return "/" + "zapier" + "/" + "find_user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /zapier(.:format) */
export function zapierIndexPath(options?: object): string {
  return "/" + "zapier" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /zapier/new(.:format) */
export function newZapierPath(options?: object): string {
  return "/" + "zapier" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /zapier/:id/edit(.:format) */
export function editZapierPath(id: ScalarType, options?: object): string {
  return "/" + "zapier" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /zapier/:id(.:format) */
export function zapierPath(id: ScalarType, options?: object): string {
  return "/" + "zapier" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /videos/:id(.:format) */
export function videoPath(id: ScalarType, options?: object): string {
  return "/" + "videos" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /spectators/disable_emails(.:format) */
export function disableEmailsSpectatorsPath(options?: object): string {
  return "/" + "spectators" + "/" + "disable_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /companies/:id(.:format) */
export function companiesPath(id: ScalarType, options?: object): string {
  return "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /invites/:id/accept(.:format) */
export function acceptInvitePath(id: ScalarType, options?: object): string {
  return "/" + "invites" + "/" + id + "/" + "accept" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /invites/new(.:format) */
export function newInvitePath(options?: object): string {
  return "/" + "invites" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /invites/:id/edit(.:format) */
export function editInvitePath(id: ScalarType, options?: object): string {
  return "/" + "invites" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /XkXwhGYqyAqIKXLkWtXy(.:format) */
export function xkXwhGYqyAqIKXLkWtXyPath(options?: object): string {
  return "/" + "XkXwhGYqyAqIKXLkWtXy" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /images/refresh-cache(.:format) */
export function imagesRefreshCachePath(options?: object): string {
  return "/" + "images" + "/" + "refresh-cache" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /directory/images/refresh-cache(.:format) */
export function directoryImagesRefreshCachePath(options?: object): string {
  return "/" + "directory" + "/" + "images" + "/" + "refresh-cache" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /about(.:format) */
export function aboutPath(options?: object): string {
  return "/" + "about" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /previous(.:format) */
export function previousPath(options?: object): string {
  return "/" + "previous" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /faq(.:format) */
export function faqPath(options?: object): string {
  return "/" + "faq" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /help(.:format) */
export function helpPath(options?: object): string {
  return "/" + "help" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /privacy(.:format) */
export function privacyPath(options?: object): string {
  return "/" + "privacy" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /confirm_sanction_requirement(.:format) */
export function confirmSanctionRequirementPath(options?: object): string {
  return "/" + "confirm_sanction_requirement" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /generative-ai-cofounder-meetup(.:format) */
export function generativeAiCofounderMeetupPath(options?: object): string {
  return "/" + "generative-ai-cofounder-meetup" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /audit_success(.:format) */
export function auditSuccessPath(options?: object): string {
  return "/" + "audit_success" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /dashboard(.:format) */
export function dashboardPath(options?: object): string {
  return "/" + "dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /sprint(.:format) */
export function sprintPath(options?: object): string {
  return "/" + "sprint" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /sprint/register(.:format) */
export function sprintRegisterPath(options?: object): string {
  return "/" + "sprint" + "/" + "register" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /curriculum/:id(.:format) */
export function curriculumItemPath(id: ScalarType, options?: object): string {
  return "/" + "curriculum" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /curriculum(.:format) */
export function curriculumPath(options?: object): string {
  return "/" + "curriculum" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /videos(.:format) */
export function videosPath(options?: object): string {
  return "/" + "videos" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /showcase(.:format) */
export function showcasePath(options?: object): string {
  return "/" + "showcase" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /showcase/:id(.:format) */
export function showcaseGroupPath(id: ScalarType, options?: object): string {
  return "/" + "showcase" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /future-founders(.:format) */
export function futureFoundersPath(options?: object): string {
  return "/" + "future-founders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /aspiring-founders(.:format) */
export function aspiringFoundersPath(options?: object): string {
  return "/" + "aspiring-founders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /library(.:format) */
export function libraryPath(options?: object): string {
  return "/" + "library" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /healthcheck(.:format) */
export function healthcheckPath(options?: object): string {
  return "/" + "healthcheck" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /latest(.:format) */
export function latestPath(options?: object): string {
  return "/" + "latest" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /lessons(.:format) */
export function lessonsPath(options?: object): string {
  return "/" + "lessons" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /email_processor(.:format) */
export function emailProcessorPath(options?: object): string {
  return "/" + "email_processor" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/postmark/inbound_emails(.:format) */
export function railsPostmarkInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "postmark" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/relay/inbound_emails(.:format) */
export function railsRelayInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "relay" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/sendgrid/inbound_emails(.:format) */
export function railsSendgridInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "sendgrid" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundHealthCheckPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mailgun/inbound_emails/mime(.:format) */
export function railsMailgunInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mailgun" + "/" + "inbound_emails" + "/" + "mime" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails(.:format) */
export function railsConductorInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/new(.:format) */
export function newRailsConductorInboundEmailPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format) */
export function editRailsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id(.:format) */
export function railsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format) */
export function newRailsConductorInboundEmailSourcePath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources(.:format) */
export function railsConductorInboundEmailSourcesPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format) */
export function railsConductorInboundEmailReroutePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "reroute" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id","format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format) */
export function railsConductorInboundEmailIncineratePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "incinerate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id","format"]);
}

/** /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format) */
export function railsServiceBlobPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "redirect" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format) */
export function railsServiceBlobProxyPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "proxy" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "redirect" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationProxyPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "proxy" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_key/*filename(.:format) */
export function railsDiskServicePath(encoded_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_token","format"]);
}

/** /rails/active_storage/direct_uploads(.:format) */
export function railsDirectUploadsPath(options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "direct_uploads" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
