import React from "react";
import { omit, pick } from "lodash";
import Linkify from "linkify-react";
import { subYears } from "date-fns";
import { FieldProps } from "./Field";

// eslint-disable-next-line import/prefer-default-export
export function splitProps<T>(props: FieldProps & T): {
  fieldProps: FieldProps;
  otherProps: Omit<T, keyof FieldProps>;
} {
  const fieldPropKeys: (keyof Required<FieldProps>)[] = [
    "fieldName",
    "required",
    "label",
    "subLabel",
    "input",
    "children",
    "anchor",
    "minLength",
    "maxLength",
    "validate",
    "noMargin",
  ];

  return {
    fieldProps: pick(props, fieldPropKeys),
    otherProps: omit(props, fieldPropKeys),
  };
}

export function validateLinkedinUrl(url: string) {
  const validRegex = /^(https?:\/\/)([a-z]{1,3}\.)?linkedin\.[a-z]{1,3}(\.[a-z]{1,3})?\/.+/i;
  return validRegex.test(url);
}

export const DEFAULT_AGE_MIN = 12;
export const DEFAULT_AGE_MAX = 100;

export const validateBirthdate = (value: string) => {
  if (!value) {
    return "Birthdate is required.";
  }
  const dob = new Date(value);

  if (dob < subYears(new Date(), 100)) {
    return "You cannot set a birthdate over 100 years ago.";
  }

  if (dob > subYears(new Date(), 12)) {
    return "You must be at least 12 years old to use the platform.";
  }

  return true;
};

export function encodeLinkedinUrl(url: string | null | undefined) {
  if (!url) {
    return url;
  }

  return window.encodeURI(url);
}

export function decodeLinkedinUrl(url: string | null | undefined) {
  if (!url) {
    return url;
  }

  return window.decodeURI(url);
}

export function getUtmFields() {
  const params = new URLSearchParams(window.location.search);
  const utmSource = params.get("utm_source");
  const utmCampaign = params.get("utm_campaign");
  const utmContent = params.get("utm_content");

  return { utmSource, utmCampaign, utmContent };
}

export function defaultHome() {
  return window.DEFAULT_HOME_PATH;
}

export function loadDefaultHome() {
  loadContinueUrl(defaultHome());
}

export function loadContinueUrl(fallback: string | undefined | null = undefined) {
  let continueUrl = null;

  // allow only internal continue urls
  const continueUrlParam = new URLSearchParams(window.location.search).get("continue_url");
  const validRegex = /^(\/|%2F)(?!(\/|%2F)).*/; // match exactly 1 slash at the start
  if (continueUrlParam && validRegex.test(continueUrlParam)) continueUrl = continueUrlParam;

  if (!continueUrl && fallback) continueUrl = fallback;
  if (continueUrl === "close") return window.close();
  if (continueUrl) goto(continueUrl);
}

export function goto(url: string) {
  // with apologies to Dijkstra
  window.location = url as unknown as Location;
}

export function getSlugFromWindowLocation() {
  return window.location.pathname.split("/").slice(-1).pop();
}

export function shortenBlurb(text: string, maxLength: number = 150) {
  return text.substring(0, maxLength) + (text.length > maxLength ? "..." : "");
}

export const linkified = (text: any) => (
  <Linkify options={{ target: "_blank" }}>{text?.trim()}</Linkify>
);

export const trimWhitespaceAroundStrings = (obj: { [key: string]: any }) => {
  const result = { ...obj };
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === "string") {
      result[key] = value.trim();
    }
  });

  return result;
};

export const formatList = (list: string[], or: boolean = true) => {
  let text = null;
  if (!list?.length) return text;

  const word = or ? "or" : "and";
  if (list.length === 1) {
    [text] = list;
  } else if (list.length === 2) {
    text = list.join(` ${word} `);
  } else {
    text = [...list.slice(0, list.length - 1), `${word} ${list[list.length - 1]}`].join(", ");
  }

  return text;
};

export const isAdminConsole = () => window.location.host === "sus-admin.ycinside.com";

export const YC_ORANGE = "#ff6600";
