import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Dialog, DialogTitle, makeStyles, TextField } from "@material-ui/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify";
import TextareaAutosize from "../../components/statelessForms/TextareaAutosize";
import Button from "../../components/statelessForms/Button";
import { CenteredLoadingDots } from "../../components/statelessForms/LoadingDots";
import { CFM_RECOMMENDATION_MODAL } from "./__generated__/CFM_RECOMMENDATION_MODAL";
import {
  CREATE_RECOMMENDATION,
  CREATE_RECOMMENDATIONVariables,
} from "./__generated__/CREATE_RECOMMENDATION";
import {
  getOptionForPerson,
  getOtherPersonFromMatch,
  getRecommendableMatchOptions,
} from "./recommendationUtil";

type Props = {
  isOpen: boolean;
  onClose: (shouldReload?: boolean) => any;
  slug?: string;
};

const ModalBody = styled.div`
  padding: 30px;
  h2 {
    text-align: center;
  }
`;

const ModalText = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  button {
    margin: 10px;
    flex-grow: 1;
    flex-basis: 50%;
  }
`;

const Label = styled.div`
  margin: 10px 0 5px 0;
  font-weight: bold;
  font-size: 14px;
`;

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
    "& > span > img": {
      height: 60,
      width: 60,
      borderRadius: "50%",
      objectFit: "cover",
    },
  },
});

type MatchOption = {
  name: string;
  avatarUrl: string;
  slug: string;
};

export default ({ isOpen, onClose, slug }: Props) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [text, setText] = useState("");
  const [selectedOption, setSelectedOption] = useState<MatchOption | null>(null);

  const { data, refetch } = useQuery<CFM_RECOMMENDATION_MODAL>(gql`
    query CFM_RECOMMENDATION_MODAL {
      cofounderMatching {
        recommendationsGiven {
          receiverProfile {
            slug
            user {
              slug
            }
          }
        }
        matches {
          sender {
            slug
            user {
              slug
              name
              avatarUrl
            }
          }
          receiver {
            slug
            user {
              slug
              name
              avatarUrl
            }
          }
        }
      }
      currentUser {
        slug
      }
    }
  `);

  const [createRecommendation] = useMutation<
    CREATE_RECOMMENDATION,
    CREATE_RECOMMENDATIONVariables
  >(gql`
    mutation CREATE_RECOMMENDATION($slug: String!, $text: String!) {
      cfmCreateRecommendation(slug: $slug, text: $text) {
        success
      }
    }
  `);

  useEffect(() => {
    if (slug && data?.cofounderMatching.matches) {
      const matchingPerson = data.cofounderMatching.matches
        .map((match) => getOtherPersonFromMatch(data.currentUser!.slug, match))
        .filter((person) => person.slug === slug)[0];
      if (matchingPerson) {
        setSelectedOption(getOptionForPerson(matchingPerson));
      }
    }
  }, [data?.cofounderMatching.matches]);

  const onSubmit = async () => {
    if (!selectedOption) {
      return;
    }

    setIsSubmitting(true);

    await createRecommendation({
      variables: { slug: selectedOption.slug, text },
    });
    await refetch();

    setSelectedOption(null);
    setText("");
    await onClose(true);
    setIsSubmitting(false);
    toast.success("Endorsement created!");
  };

  if (!data) {
    return null;
  }

  const matchOptions = getRecommendableMatchOptions(data);

  return (
    <Dialog open={isOpen} onClose={() => onClose(false)} onClick={(e) => e.stopPropagation()}>
      <ModalBody>
        {isSubmitting ? (
          <CenteredLoadingDots />
        ) : (
          <>
            <DialogTitle>Endorse one of your matches</DialogTitle>

            <ModalText>
              If you've had a positive experience with a match, give them an endorsement!
            </ModalText>

            <ModalText>
              Please only leave positive comments, and only for people you've actually met. This is{" "}
              <b>not</b> a platform to leave negative feedback.{" "}
              <i>(You can report someone for bad behavior on their profile page.)</i>
            </ModalText>

            <Label>Choose a match to endorse.</Label>
            <Autocomplete
              options={matchOptions}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              value={selectedOption}
              onChange={(_, option) => setSelectedOption(option)}
              renderOption={(option) => (
                <>
                  <span>
                    <img src={option.avatarUrl} alt="Avatar" />
                  </span>
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
              getOptionSelected={(option, value) => option.slug === value.slug}
            />

            <Label>Write your endorsement here.</Label>
            <TextareaAutosize
              value={text}
              onChange={(e) => setText(e.target.value)}
              minRows={3}
              fullWidth
            />

            <ButtonRow>
              <Button color="orange" onClick={onSubmit} disabled={!selectedOption || !text.trim()}>
                Submit
              </Button>
              <Button color="gray" onClick={() => onClose(false)}>
                Cancel
              </Button>
            </ButtonRow>
          </>
        )}
      </ModalBody>
    </Dialog>
  );
};
