import React from "react";
import { css } from "@emotion/core";
import {
  bySlugAdminUsersPath,
  candidateCofounderMatchingPath,
  bySlugAdminCofounderMatchingPath,
} from "../../__generated__/routes";

type Props = {
  userSlug: string;
  profileSlug: string | null | undefined;
};

export default ({ userSlug, profileSlug }: Props) => (
  <p css={css({ marginTop: 15 })}>
    <a href={bySlugAdminUsersPath(userSlug)}>admin user</a>
    {profileSlug && (
      <>
        {" "}
        | <a href={bySlugAdminCofounderMatchingPath(profileSlug)}>admin profile</a> |{" "}
        <a href={candidateCofounderMatchingPath(profileSlug)}>profile</a>
      </>
    )}
  </p>
);
