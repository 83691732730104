import { useMutation, gql } from "@apollo/client";
import { COMPLETE_ACTION, COMPLETE_ACTIONVariables } from "./__generated__/COMPLETE_ACTION";
import { CompletableAction } from "../../types/graphqlTypes";

export default function useCompleteAction() {
  const [completeAction] = useMutation<COMPLETE_ACTION, COMPLETE_ACTIONVariables>(gql`
    mutation COMPLETE_ACTION($action: CompletableAction!) {
      completeAction(action: $action) {
        success
      }
    }
  `);

  return (actionName: CompletableAction) => completeAction({ variables: { action: actionName } });
}
