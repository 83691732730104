import React from "react";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/core";

type Props = {
  interval?: number;
};

const COLOR = "#fb651e";

const rowAnimation = keyframes`
  0%,
  20% {
    visibility: visible;
    opacity: 1;
  }
  21%,
  100% {
    visibility: hidden;
    opacity: 0;
  }
`;

const dotAnimation = keyframes`
  0% {
   height: 0px;
   width: 0px;
   margin-left: 0;
  }
  30% {
    height: 20px;
    width: 20px;
    margin-left: -10px;
  }
  60%,
  100% {
    height: 0;
    width: 0;
    margin-left: 0;
  }
`;

type DotProps = {
  delay: number;
};

const Dot = styled.div<DotProps>`
  border-radius: 50%;
  background-color: ${COLOR};
  color: ${COLOR};
  animation: ${dotAnimation} 1.5s infinite linear;
  animation-delay: ${({ delay }) => delay}s;
`;

const DotRow = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  width: fit-content;

  ${({ interval }) => {
    if (interval) {
      return css`
        animation: ${rowAnimation} ${interval}s infinite linear;
      `;
    }
  }}
`;

const DotWrapper = styled.div`
  text-align: center;
  width: 25px;
`;

const LoadingDots = ({ interval }: Props) => (
  <DotRow interval={interval}>
    <DotWrapper>
      <Dot delay={0} />
    </DotWrapper>
    <DotWrapper>
      <Dot delay={0.25} />
    </DotWrapper>
    <DotWrapper>
      <Dot delay={0.5} />
    </DotWrapper>
  </DotRow>
);

export default LoadingDots;

export const CenteredLoadingDots = () => (
  <div css={css({ display: "flex", justifyContent: "center" })}>
    <LoadingDots />
  </div>
);
