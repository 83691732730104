import { get, omit } from "lodash";
import React, { PropsWithChildren } from "react";
import { useFormContext } from "react-hook-form";
import { css } from "@emotion/core";
import ScrollableAnchor from "react-scrollable-anchor";
import { FormControl, FormHelperText, FormLabel, withStyles } from "@material-ui/core";

export type FieldProps = PropsWithChildren<{
  fieldName: string;
  required?: boolean;
  label?: string | React.ReactNode;
  subLabel?: string | React.ReactElement;
  input?: React.ReactElement;
  anchor?: string;
  minLength?: number;
  maxLength?: number;
  validate?: Function;
  noMargin?: boolean | undefined;
}>;

export type CustomFieldProps<T> = Omit<FieldProps, "input"> & T;

export const StyledFormLabel = withStyles({
  root: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    fontWeight: "bold",
    marginBottom: "0.25rem",
    fontSize: "0.9375em",
    lineHeight: 1.5,
  },
  asterisk: {
    color: "#DB2828",
  },
})(FormLabel);

export default React.forwardRef<any, FieldProps>((props, ref) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // @ts-ignore
  const errorMessage: string | undefined = get(errors, `${props.fieldName}.message`);
  const required = props.required ? "This is a required field" : false;
  const minLength = props.minLength
    ? {
        value: props.minLength,
        message: `Please write at least ${props.minLength} characters`,
      }
    : undefined;
  const maxLength = props.maxLength
    ? {
        value: props.maxLength,
        message: `Please limit your response to ${props.maxLength} characters`,
      }
    : undefined;
  const validate = props.validate ?? {};

  return (
    <FormControl fullWidth error={errorMessage != null} margin={props.noMargin ? "none" : "normal"}>
      {props.anchor && (
        <ScrollableAnchor id={props.anchor}>
          <div />
        </ScrollableAnchor>
      )}
      {props.label && (
        // eslint-disable-next-line
        <StyledFormLabel ref={ref} tabIndex={ref ? 0 : undefined}>
          {props.label}
          {required && (
            <span
              css={css({ color: "#db2828", position: "relative", top: "-0.2em", left: "0.2em" })}
            >
              *
            </span>
          )}
          {props.subLabel && (
            <span css={css({ fontWeight: "normal", whiteSpace: "pre-wrap" })}>
              <br />
              {props.subLabel}
            </span>
          )}
        </StyledFormLabel>
      )}
      {props.children}
      {props.input &&
        React.cloneElement(
          props.input,
          omit(register(props.fieldName, { required, minLength, maxLength, validate }), "ref")
        )}
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
});
