import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { omit } from "lodash";

const defaultTextFieldProps: TextFieldProps = {
  variant: "standard",
  fullWidth: true,
};

const defaultTextAreaProps = {
  ...defaultTextFieldProps,
  multiline: true,
  minRows: 3,
};

type CustomInputProps = {
  outlined?: boolean;
  inputComponent?: any;
};
const customInputPropKeys: (keyof Required<CustomInputProps>)[] = ["outlined", "inputComponent"];

type InputProps = TextFieldProps & CustomInputProps;

const renderDefault = (props: InputProps, baseDefaultProps: TextFieldProps) => {
  const defaultProps = baseDefaultProps;
  const inputProps = {};
  if (props.outlined) {
    Object.assign(defaultProps, { variant: "outlined" });
  } else {
    Object.assign(inputProps, { disableUnderline: true });
  }

  if (props.inputComponent) {
    Object.assign(inputProps, { inputComponent: props.inputComponent });
  }
  const InputProps = { ...inputProps, ...(props.InputProps || {}) };
  const allProps = { ...defaultProps, ...omit(props, customInputPropKeys), InputProps };

  return <TextField {...allProps} />;
};

export const Input = (props: InputProps) => renderDefault(props, defaultTextFieldProps);

export const TextArea = (props: InputProps) => renderDefault(props, defaultTextAreaProps);

export default Input;
export { InputProps };
