import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import { Dialog } from "@material-ui/core";
import { ColumnContainer, LeftColumn, RightColumn } from "../../components/Page";
import RespondToInvite from "./CFMRespondToInvite";
import CofounderMatchingNotes from "./CofounderMatchingNotes";
import { CFMProfileFragment } from "./fragments";
import { CFMViewerProfileFragment } from "./__generated__/CFMViewerProfileFragment";
import { CFM_PROFILE_MODAL } from "./__generated__/CFM_PROFILE_MODAL";
import CFMCandidateActionBox, { CurrentContextType } from "./CFMCandidateActionBox";
import CFMBlockProfileForm from "./CFMBlockProfileForm";
import { Pane, PaneItem } from "../../components/Pane";
import CFMManageMatch from "./CFMManageMatch";
import { DesktopOnly, MobileOnly } from "../../styles";
import CFMProfile from "./CFMProfile";

type Props = {
  slug: string;
  viewer: CFMViewerProfileFragment;
  onClose: () => void;
  onArchive?: () => any;
  currentContext: CurrentContextType;
};

export default ({ slug, viewer, onClose, onArchive, currentContext }: Props) => {
  const { data, loading, refetch } = useQuery<CFM_PROFILE_MODAL>(
    gql`
      query CFM_PROFILE_MODAL($slug: ID) {
        cofounderMatching {
          invitesRemaining
          candidate(slug: $slug, currentContext: "profile-modal-web") {
            ...CFMProfileFragment
            trialSlug
            request {
              id
              slug
              status
              message
              sentAt
              matchedAt
              metAt
              committedAt
              sender {
                slug
                user {
                  slug
                  name
                  country
                  region
                }
              }
              receiver {
                slug
              }
              archived
            }
          }
        }
      }
      ${CFMProfileFragment}
    `,
    { variables: { slug } }
  );

  const profile = data?.cofounderMatching?.candidate;
  const request = profile?.request;

  if (!profile) {
    return null;
  }

  const showInviteResponseOptions =
    request?.status === "sent" && request?.sender.slug === profile.slug;

  const notesSection = (
    <Pane>
      <PaneItem>
        <CofounderMatchingNotes slug={slug} />
      </PaneItem>
    </Pane>
  );

  const blockProfileSection = (
    <Pane>
      <PaneItem>
        <p css={css({ fontSize: 15 })}>
          <CFMBlockProfileForm
            slug={profile.slug}
            hasMatched={request?.status === "accepted"}
            onBlock={() => {
              refetch();
              onClose();
            }}
          />
        </p>
      </PaneItem>
    </Pane>
  );

  return (
    <Dialog open onClose={onClose} scroll="body" maxWidth="md">
      <ColumnContainer css={css({ margin: 0, padding: 50 })}>
        <LeftColumn>
          {request && showInviteResponseOptions && (
            <RespondToInvite
              candidate={profile}
              request={request}
              onAccept={refetch}
              onDecline={refetch}
            />
          )}
          <CFMProfile profile={profile} viewer={viewer} showProfileLink />
          <MobileOnly>
            {notesSection}
            {blockProfileSection}
          </MobileOnly>
        </LeftColumn>
        <RightColumn>
          {request?.committedAt && (
            <Pane>
              <PaneItem>
                <div>
                  🎉 Congratulations on becoming official co-founders!! Good luck with your company!
                  🎉
                </div>
              </PaneItem>
            </Pane>
          )}
          {request?.status === "accepted" && (
            <CFMManageMatch
              slug={slug}
              trialSlug={profile.trialSlug}
              match={request}
              reloadData={refetch}
              onArchive={onArchive}
            />
          )}
          {(!request || request?.status === "saved" || request?.status === "dismissed") && (
            <CFMCandidateActionBox
              candidate={profile}
              invitesRemaining={data?.cofounderMatching?.invitesRemaining}
              loading={loading}
              requestStatus={request?.status}
              loadNextCandidate={onClose}
              reloadCandidate={refetch}
              onlyDisplayConnectActions={request?.status === "saved"}
              onlyDisplayInviteAction={request?.status === "dismissed"}
              currentContext={currentContext}
              inviteLimit={viewer?.numInvitesPerWeek}
            />
          )}
          <DesktopOnly>
            {notesSection}
            {blockProfileSection}
          </DesktopOnly>
        </RightColumn>
      </ColumnContainer>
    </Dialog>
  );
};
