import React, { FunctionComponent } from "react";
import { css, CSSObject } from "@emotion/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core";
import { colors } from "../styles";

type ButtonType =
  | "cta"
  | "success"
  | "fail"
  | "inactiveSuccess"
  | "inactiveFail"
  | "minimalSuccess"
  | "minimalInactiveSuccess";

type Props = JSX.IntrinsicElements["a"] & {
  text: string;
  type: ButtonType;
  size?: string;
  disabled?: boolean;
  minimal?: boolean;
  leftAlignCheckbox?: boolean;
  asButton?: boolean;
  onClick?: (e: React.MouseEvent) => void;
};

const successColor = "#7bcf56";
const failColor = "#f7a1a1";
const inactiveColor = "#d2d4d7";
const activeDefaultColor = "rgba(0, 0, 0, 0.6)";

type ButtonConfig = {
  color: string;
  textColor?: string;
  hoverColor: string;
  icon?: React.ComponentType;
  background?: string;
  disabledBackground?: string;
  hoverBackground?: string;
  border?: string;
  boxShadow?: string;
  boxShadowHover?: string;
};

const SmallCheck = withStyles({
  root: {
    fontSize: "1.1rem",
  },
})(CheckIcon);

const SmallClose = withStyles({
  root: {
    fontSize: "1.1rem",
  },
})(CloseIcon);

const typeToConfig: { [key in ButtonType]: ButtonConfig } = {
  success: {
    color: successColor,
    hoverColor: inactiveColor,
    icon: SmallCheck,
  },
  fail: {
    color: failColor,
    hoverColor: inactiveColor,
    icon: SmallClose,
  },
  inactiveSuccess: {
    color: inactiveColor,
    hoverColor: successColor,
    icon: SmallCheck,
  },
  inactiveFail: {
    color: inactiveColor,
    hoverColor: failColor,
    icon: SmallClose,
  },
  minimalSuccess: {
    color: successColor,
    textColor: successColor,
    hoverColor: successColor,
    icon: SmallCheck,
    border: "1px solid transparent",
    boxShadow: "0 0 0 1px rgb(123 207 86) inset",
    boxShadowHover: "0 0 0 1px rgb(123 207 86 / 35%) inset, 0 0 0 0 rgb(123 207 86 / 15%) inset",
  },
  minimalInactiveSuccess: {
    color: inactiveColor,
    textColor: activeDefaultColor,
    hoverColor: successColor,
    icon: SmallCheck,
    border: "1px solid transparent",
    boxShadow: "0 0 0 1px rgb(34 36 38 / 15%) inset",
    boxShadowHover: "0 0 0 1px rgb(123 207 86 / 55%) inset, 0 0 0 0 rgb(123 207 86 / 15%) inset",
  },
  cta: {
    color: "white",
    hoverColor: "white",
    background: colors.brandPrimary,
    disabledBackground: "#fcb28e",
    hoverBackground: "#ff8348",
    border: "none",
  },
};

const sizeToPadding: { [size: string]: string } = {
  large: "16px 50px",
  fullWidth: "16px 50px",
  small: "8px 20px",
};

const iconColors = (color: string) => ({
  "div.iconBorder": {
    borderColor: color,
    "div.iconBackground": {
      backgroundColor: color,
    },
  },
});

const Button: FunctionComponent<Props> = ({
  text,
  type,
  size,
  disabled,
  href,
  minimal,
  leftAlignCheckbox,
  onClick: propsOnClick,
  asButton,
  ...props
}) => {
  const config = typeToConfig[type];
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (propsOnClick) {
      e.preventDefault();
      propsOnClick(e);
    }
  };

  const style: CSSObject = {
    display: "inline-flex",
    border: config.border ?? "1px solid",
    boxShadow: config.boxShadow ?? "none",
    borderRadius: 4,
    padding: size && Object.keys(sizeToPadding).includes(size) ? sizeToPadding[size] : "10px 20px",
    cursor: disabled ? "default" : "pointer",
    whiteSpace: "nowrap",
    color: config.textColor ?? config.color,
    opacity: minimal && disabled ? "0.5" : "1",
    background: ((disabled && config.disabledBackground) || config.background) ?? "white",
    textDecoration: "none",
    fontSize: size === "small" ? "0.8125rem" : "inherit",
    width: size === "fullWidth" ? "100%" : "fit-content",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    ":hover": disabled
      ? { color: config.textColor ?? config.color }
      : {
          color: config.hoverColor,
          ...iconColors(config.hoverColor),
          textDecoration: "none",
          boxShadow: config.boxShadowHover ?? "none",
          background:
            ((disabled && config.disabledBackground) || config.hoverBackground) ?? "white",
        },
    ...iconColors(config.color),
  };

  const icon = config.icon && (
    <div
      className="iconBorder"
      css={css({
        marginLeft: -6,
        marginRight: 9,
        height: 22,
        width: 22,
        borderRadius: 11,
        border: "1px solid #aeaeae",
        padding: 1,
        ...(leftAlignCheckbox
          ? {
              position: "absolute",
              left: 0,
            }
          : {}),
      })}
    >
      <div
        className="iconBackground"
        css={css({
          height: 18,
          width: 18,
          borderRadius: 9,
          backgroundColor: "#aeaeae",
          position: "relative",
        })}
      >
        <div
          css={css({
            color: "white",
            visibility: !minimal || type === "minimalSuccess" ? "visible" : "hidden",
            textAlign: "center",
          })}
        >
          <config.icon />
        </div>
      </div>
    </div>
  );

  const clickHandlers = disabled ? {} : { onClick, href };

  const buttonContents = (
    <div
      css={css({
        width: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto auto",
      })}
    >
      {icon}
      {text}
    </div>
  );

  return asButton ? (
    <button css={css(style)} {...clickHandlers} onClick={onClick} disabled={disabled}>
      {buttonContents}
    </button>
  ) : (
    <a css={css(style)} {...clickHandlers} {...props}>
      {buttonContents}
    </a>
  );
};

export default Button;
