import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { withStyles } from "@material-ui/core";
import { People } from "@material-ui/icons";
import { getSlugFromWindowLocation } from "../../components/forms/util";
import { Container } from "../../components/Page";
import { EVENT_STATES, getEventState, formatDate } from "./speedDatingEventUtils";
import LiveEvent from "./LiveEvent";
import Button from "../../components/statelessForms/Button";
import CompletedEvent from "./CompletedEvent";
import FAQs, { FAQItemType } from "../landingPage/FAQSection";
import ConfirmUserIsAdult from "./ConfirmUserIsAdult";
import SpeedDatingRSVP from "./SpeedDatingRSVP";
import UnsupportedEnvironmentWarning from "./UnsupportedEnvironmentWarning";
import loadSpeedDatingEvent from "./speedDatingEventInfo";
import {
  SPEED_DATING_EVENT_currentUser,
  SPEED_DATING_EVENT_speedDatingEvent,
} from "./__generated__/SPEED_DATING_EVENT";
import { measurements } from "../../components/navigation/shared";
import { mobileStyles } from "../../styles";
import { getClockOffset } from "../../hooks/useRealtime";

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-top: 5px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  margin-top: -10px;
  margin-bottom: 30px;
  font-weight: 300;
`;

const DateTime = styled.div`
  color: gray;
  font-weight: 500;
  letter-spacing: 1px;
`;

const HowItWorks = styled.div`
  margin-top: 50px;
  h1 {
    font-size: 24px !important;
    margin-bottom: 20px !important;
  }
`;

const Announcement = styled.div`
  background-color: #cc0000;
  color: white;
  padding: 10px;
  position: fixed;
  top: 0;
  right: 0;
  left: ${measurements.menuOpen}px;
  text-align: center;
  ${mobileStyles({
    left: 0,
    top: "auto",
    bottom: 0,
  })}
`;

const ExtraLargeButton = withStyles({
  root: {
    width: 300,
    height: 50,
    fontSize: 16,
  },
})(Button);

type Props = {
  customInfoSection?: React.ReactElement;
  faqItems: FAQItemType[];
  minutesToDescriptionFn: (meetingMinutes: number) => string;
  needsApproval?: boolean;
};

export default ({ customInfoSection, faqItems, minutesToDescriptionFn, needsApproval }: Props) => {
  const [hasJoinedEvent, setHasJoinedEvent] = useState(false);
  const [attendance, setAttendance] = useState([-1, -1]);
  const [announcement, setAnnouncement] = useState<string | undefined | null>(undefined);
  const [currentUser, setCurrentUser] = useState<SPEED_DATING_EVENT_currentUser | undefined>(
    undefined
  );
  const [event, setEvent] = useState<SPEED_DATING_EVENT_speedDatingEvent | undefined>(undefined);
  const [eventState, setEventState] = useState<string | undefined>(undefined);
  const [inMeeting, setInMeeting] = useState(false);
  const [clockOffset, setClockOffset] = useState(0);
  const slug = getSlugFromWindowLocation();

  const [refetch, { data }] = loadSpeedDatingEvent(slug, { fetchPolicy: "network-only" });

  const reloadEvent = async () => {
    await refetch({
      variables: { slug },
    });
  };

  const loadClockOffset = async () => {
    const offset = await getClockOffset();
    setClockOffset(offset);
  };

  useEffect(() => {
    reloadEvent();
    loadClockOffset();
  }, []);

  useEffect(() => {
    const speedDatingEvent = data?.speedDatingEvent;
    if (!speedDatingEvent || !data?.currentUser) {
      return;
    }

    setEvent(data?.speedDatingEvent);
    setCurrentUser(data?.currentUser);

    const { eventState: state, startsAt, endsAt } = speedDatingEvent;
    setEventState(state);

    // Bypass "start matching" button if user is already in a meeting
    if (!!speedDatingEvent.currentMeeting && !hasJoinedEvent) {
      setHasJoinedEvent(true);
    }

    const interval =
      state !== EVENT_STATES.finished
        ? setInterval(() => {
            const newState = getEventState(startsAt, endsAt, clockOffset);
            if (newState !== state) {
              reloadEvent();
            }
          }, 5000)
        : null;

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [data?.speedDatingEvent, clockOffset]);

  if (!event) {
    return null;
  }

  const howItWorksSection = hasJoinedEvent ? null : (
    <HowItWorks>
      <FAQs title="How it works" items={faqItems} />
    </HowItWorks>
  );

  const eventIsInProgressSection = () => {
    if (!event.attendeeKey || !event.dailyUserToken) {
      return null;
    }

    const onClick = () => {
      if (currentUser?.adult) {
        setHasJoinedEvent(true);
      }
    };

    const actionSection = needsApproval ? (
      <h5>
        Unfortunately, your profile hasn't been approved yet, so you're not able to join the event.
        Don't worry, though - we hold these co-founder speed dating events every week!
      </h5>
    ) : (
      <ExtraLargeButton color="orange" onClick={onClick}>
        Start matching!
      </ExtraLargeButton>
    );

    return hasJoinedEvent ? (
      <LiveEvent
        attendeeKey={event.attendeeKey}
        dailyUserToken={event.dailyUserToken}
        eventSlug={event.slug}
        clockOffset={clockOffset}
        setAttendance={setAttendance}
        setAnnouncement={setAnnouncement}
        setInMeeting={setInMeeting}
      />
    ) : (
      <div css={css({ textAlign: "center" })}>{actionSection}</div>
    );
  };

  const attendanceItem = (total: number, color: string, userState: string) => (
    <div css={css({ display: "flex", alignItems: "center", color })}>
      <People />
      <div css={css({ marginLeft: 5, fontWeight: "bold" })}>
        {`${total} ${total === 1 ? "founder" : "founders"} ${userState}`}
      </div>
    </div>
  );

  return (
    <Container>
      {announcement && <Announcement>{announcement}</Announcement>}
      <ConfirmUserIsAdult isAdult={currentUser?.adult} />
      {eventState !== EVENT_STATES.finished && <UnsupportedEnvironmentWarning />}
      <TitleRow>
        <div>
          <DateTime>{formatDate(event)}</DateTime>
          <Title>{event.title}</Title>
        </div>
        {eventState === EVENT_STATES.inProgress &&
          attendance[0] >= 0 &&
          attendanceItem(attendance[0], "#fb651e", "here")}
      </TitleRow>
      <Subtitle>
        <p>{minutesToDescriptionFn(event.meetingMinutes)}</p>
        {currentUser?.cfmProfileExists && event.eventType !== "cfm" && (
          <p>
            <i>
              Please note: this is <b>not</b> a co-founder speed dating event. We won't pair you
              with people who fit the filters on your profile, and the goal is not to meet potential
              co-founder candidates. The founder social is an opportunity to meet some other
              founders who we think would be interesting for you to talk to and nice to have in your
              network.
            </i>
          </p>
        )}
      </Subtitle>
      {eventState !== EVENT_STATES.finished && !!event.icebreakers.length && (
        <Subtitle>
          Here are a few topics you can use as conversation starters:
          <ul css={css({ marginTop: 5 })}>
            {event.icebreakers.map((question) => (
              <li key={question}>{question}</li>
            ))}
          </ul>
        </Subtitle>
      )}
      {eventState === EVENT_STATES.upcoming && (
        <>
          <SpeedDatingRSVP
            slug={event.slug}
            isAttending={event.attending}
            reloadEvent={reloadEvent}
          />
          {needsApproval && (
            <div
              css={css({
                fontWeight: "bold",
                fontStyle: "italic",
                textAlign: "center",
                marginTop: 20,
                color: "gray",
              })}
            >
              Please note, you won't be able to participate unless your profile has been approved by
              the time of the event.
            </div>
          )}
        </>
      )}
      {(inMeeting ||
        (eventState === EVENT_STATES.inProgress && event.attendeeKey && event.dailyUserToken)) &&
        eventIsInProgressSection()}
      {eventState !== EVENT_STATES.finished && !hasJoinedEvent && customInfoSection}
      {eventState !== EVENT_STATES.finished && howItWorksSection}
      {!inMeeting && eventState === EVENT_STATES.finished && <CompletedEvent eventSlug={slug} />}
    </Container>
  );
};
