import { css } from "@emotion/core";
import { gql } from "@apollo/client";
import { differenceInDays, format } from "date-fns";
import React, { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import ProfileModal from "./ProfileModal";
import { ProfilePreviewFields as ProfilePreviewFieldsType } from "./__generated__/ProfilePreviewFields";
import { CFMViewerProfileFragment } from "./__generated__/CFMViewerProfileFragment";
import { Item } from "../../components/Section";
import { loadContinueUrl } from "../../components/forms/util";
import { profileCofounderMatchingPath } from "../../__generated__/routes";
import { DesktopOnly, MobileOnly, mobileStyleCss, mobileStyles } from "../../styles";
import BackButton from "../../components/statelessForms/BackButton";
import { CurrentContextType } from "./CFMCandidateActionBox";
import { prefilledCalendarLink, TextList } from "./CFMProfile";
import { Attribute } from "./CFMAttributes";
import { statusPreviewMatchedOrSent } from "./directory/DirectoryProfilePreview";
import { FOUNDERS_YOU_MAY_KNOW_cofounderMatching_foundersYouMayKnow_profiles_request } from "./__generated__/FOUNDERS_YOU_MAY_KNOW";
import CFMSaveToFavorites from "./CFMSaveToFavorites";

type Props = {
  profile: ProfilePreviewFieldsType;
  text?: string | ReactElement;
  message?: string;
  buttonUrl?: string;
  buttonColor?: "orange" | "yellow";
  customHtml?: ReactElement;
  dateTime?: string;
  dateTimePrefix?: string;
  viewer: CFMViewerProfileFragment;
  padding?: number;
  isOpenByDefault?: boolean;
  maxWidth?: string;
  onArchive?: () => any;
  onBackAction?: () => any;
  currentContext: CurrentContextType;
  request?: FOUNDERS_YOU_MAY_KNOW_cofounderMatching_foundersYouMayKnow_profiles_request;
  showSaveButton?: boolean;
};

export const ProfilePreviewFields = gql`
  fragment ProfilePreviewFields on CofounderProfile {
    slug
    calendlyLink

    user {
      avatarUrl
      name
      firstName
      slug
      education
      employment
    }

    request {
      slug
      status
    }
  }
`;

const DateTimeText = styled.div`
  color: #121212;
  opacity: 0.34;
  font-weight: 500;
  font-size: 14px;
  ${mobileStyleCss(`
    font-size: 12px;
  `)}
`;

const CenterColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  flex-grow: 1;
`;

const NameAndRequestSection = styled.div`
  display: flex;
`;

const RequestStatus = styled.div`
  margin-left: auto;
`;

const RequestStatusMobile = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: fit-content;
  margin-left: 10px;

  ${mobileStyleCss(`
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    flex-grow: 1;
    justify-content: flex-end
  `)}
`;

const SaveButton = styled.div`
  margin-left: auto;
  margin-bottom: auto;
`;

const MessageText = styled.div`
  font-size: 14px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
`;

const BottomBannerWrapper = styled.div({
  height: 10,
  ...mobileStyles({ height: 20 }),
});

const BottomBanner = styled.a`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border: none;
  background-color: #fb651e;
  color: white;
  text-align: center;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    color: white;
  }
`;

export default ({
  profile,
  text,
  message,
  buttonUrl,
  customHtml,
  dateTime,
  dateTimePrefix,
  padding,
  isOpenByDefault,
  maxWidth,
  onArchive,
  onBackAction,
  currentContext,
  request,
  showSaveButton,
  ...props
}: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(!!isOpenByDefault);

  let dateTimeStr;
  if (dateTime) {
    const daysAgo = differenceInDays(new Date(), new Date(dateTime));

    let formattedDate;
    if (daysAgo === 0) {
      formattedDate = "today";
    } else if (daysAgo === 1) {
      formattedDate = "yesterday";
    } else if (daysAgo < 7) {
      formattedDate = `${daysAgo} days ago`;
    } else {
      formattedDate = `on ${format(new Date(dateTime), "PPP")}`;
    }

    dateTimeStr = `${dateTimePrefix || ""} ${formattedDate}`.trim();
  }

  const onClick = () => {
    if (props.viewer.slug === profile.slug) {
      loadContinueUrl(profileCofounderMatchingPath());
    } else {
      setModalOpen(true);
    }
  };

  const onBackButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onBackAction) {
      onBackAction();
    }
  };

  const profilePhotoSize = currentContext === "fymk" ? 80 : 62;

  const profilePhoto = (
    <img
      css={css({
        borderRadius: "50%",
        height: profilePhotoSize,
        width: profilePhotoSize,
        ...mobileStyles({ height: 40, width: 40 }),
      })}
      src={profile.user.avatarUrl}
      alt="avatar"
    />
  );

  const profileName = (
    <div css={css({ fontSize: 18, margin: "auto 0 5px", ...mobileStyles({ margin: 0 }) })}>
      {profile.user.name}
    </div>
  );

  const hideCalendly = currentContext === "fymk";

  return (
    <Item padding={padding} onClick={onClick} position="relative">
      {/* DESKTOP PREVIEW LAYOUT */}
      <DesktopOnly
        otherStyles={{
          display: "flex",
          width: "100%",
          maxWidth: maxWidth || 800,
        }}
      >
        {/* Flex column 1: avatar photo */}
        {profilePhoto}

        {/* Flex column 2: name/text/resume */}
        <CenterColumnContainer>
          <NameAndRequestSection>
            <div>
              {profileName}
              {dateTimeStr && <DateTimeText>{dateTimeStr}</DateTimeText>}
            </div>
            {(request?.status === "accepted" || request?.status === "sent") && (
              <RequestStatus>{statusPreviewMatchedOrSent(profile, request)}</RequestStatus>
            )}
          </NameAndRequestSection>

          {text && <MessageText>{text}</MessageText>}

          {currentContext === "fymk" && (
            <div css={css({ display: "flex" })}>
              <div css={css({ width: "50%" })}>
                <Attribute
                  title="Education"
                  block
                  value={<TextList text={profile.user.education} />}
                />
              </div>
              <div css={css({ width: "50%" })}>
                <Attribute
                  title="Employment"
                  block
                  value={<TextList text={profile.user.employment} />}
                />
              </div>
            </div>
          )}
        </CenterColumnContainer>

        {/* Flex column 3: buttons */}
        <ButtonContainer>
          {showSaveButton && (
            <SaveButton>
              <CFMSaveToFavorites
                profile={profile}
                currentContext={currentContext}
                trackSavedStatus
                icon
              />
            </SaveButton>
          )}
          {customHtml && <div css={css({ marginBottom: 15 })}>{customHtml}</div>}
        </ButtonContainer>
      </DesktopOnly>

      {/* MOBILE PREVIEW LAYOUT */}
      <MobileOnly otherStyles={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {/* Flex row 1: back button & other buttons */}
        <div
          css={css({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0 -10px 5px -10px",
          })}
        >
          {onBackAction && <BackButton onClick={onBackButtonClick} mobileOnly />}
          <ButtonContainer>{customHtml}</ButtonContainer>
        </div>

        {/* Flex row 2: avatar photo & name / date */}
        <div css={css({ display: "flex", alignItems: "center" })}>
          {profilePhoto}
          <CenterColumnContainer>
            {profileName}
            {dateTimeStr && <DateTimeText>{dateTimeStr}</DateTimeText>}
          </CenterColumnContainer>
          {showSaveButton && (
            <SaveButton>
              <CFMSaveToFavorites
                profile={profile}
                currentContext={currentContext}
                trackSavedStatus
                icon
              />
            </SaveButton>
          )}
        </div>

        {/* Flex row 3: text */}
        {text && <MessageText>{text}</MessageText>}

        {/* Flex row 4: education / employment */}
        {currentContext === "fymk" && (
          <div>
            <Attribute title="Education" block value={<TextList text={profile.user.education} />} />
            <Attribute
              title="Employment"
              block
              value={<TextList text={profile.user.employment} />}
            />
          </div>
        )}

        {/* Flex row 5: request status */}
        {(request?.status === "accepted" || request?.status === "sent") && (
          <RequestStatusMobile>{statusPreviewMatchedOrSent(profile, request)}</RequestStatusMobile>
        )}
      </MobileOnly>

      {/* Render modal if open */}
      {modalOpen && (
        <ProfileModal
          slug={profile.slug}
          viewer={props.viewer}
          onClose={() => setModalOpen(false)}
          onArchive={onArchive}
          currentContext={currentContext}
        />
      )}

      {profile.calendlyLink && !hideCalendly && (
        <BottomBannerWrapper>
          <BottomBanner
            href={prefilledCalendarLink(profile.calendlyLink, props.viewer)}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            Schedule a call with {profile.user.firstName}
          </BottomBanner>
        </BottomBannerWrapper>
      )}
    </Item>
  );
};
