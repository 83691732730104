import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { mobileStyles } from "../../styles";
import { Pane, PaneItem } from "../Pane";

type MenuListItemProps = { selected: boolean; disabled: boolean };
const MenuListItem = styled.div<MenuListItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  border-radius: 3px;

  span {
    font-size: 18px;
  }

  ${({ selected, disabled }) => {
    if (disabled) {
      return css`
        background-color: white;
        font-weight: 300;
        color: darkgray;
        font-style: italic;

        span:hover {
          cursor: default;
        }
      `;
    }

    const cursorCss = css`
      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    `;
    if (selected) {
      return css`
        background-color: lightblue;
        font-weight: bold;
        color: white;
        ${cursorCss}
      `;
    }

    return css`
      background-color: transparent;
      font-weight: normal;
      color: black;
      ${cursorCss}
    `;
  }}
`;

const NavPanel = styled.div({
  width: "30%",
  maxWidth: 300,
  minWidth: "fit-content",
  ...mobileStyles({
    maxWidth: "100%",
    width: "100%",
  }),
});

const ValidityIcon = styled.div`
  display: flex;
  margin-right: 15px;
`;

type FormPageComponentProps<T, ComponentProps> = ComponentProps & {
  data: T | null;
};

export type FormPage<T, ComponentProps> = {
  Component: (props: FormPageComponentProps<T, ComponentProps>) => JSX.Element;
  title: string;
  fields: string[];
  disabled: (data: T | null | undefined) => boolean;
  renderIf?: (data: T | null | undefined) => boolean;
  isPreviewPage?: boolean;
};

type Props<T, ComponentProps> = {
  formPages: FormPage<T, ComponentProps>[];
  data: T | null;
  currentPage: number;
  goToPage: (page: number) => void;
  pageValidity: (boolean | null)[];
  shouldBeValid?: boolean;
  title: string;
};

export default <T extends any, ComponentProps extends any>({
  formPages,
  data,
  currentPage,
  goToPage,
  pageValidity,
  shouldBeValid,
  title,
}: Props<T, ComponentProps>) => {
  const renderValidityIcon = (isValid: boolean | null, isDisabled: boolean) => {
    let color = "gray";
    let icon = <RadioButtonUncheckedIcon />;
    if (isDisabled) {
      color = "darkgray";
    } else if (isValid) {
      color = "green";
      icon = <CheckCircleIcon />;
    } else if (shouldBeValid && isValid === false) {
      color = "red";
      icon = <ErrorIcon />;
    }
    return <ValidityIcon css={css({ color })}>{icon}</ValidityIcon>;
  };

  return (
    <NavPanel>
      <Pane>
        <PaneItem css={css({ padding: 20 })}>
          <h2>{title}</h2>
          {formPages.map((formPage, idx) => {
            const isDisabled = formPage.disabled(data);
            return (
              <MenuListItem
                key={formPage.title}
                selected={idx === currentPage - 1}
                disabled={isDisabled}
              >
                {renderValidityIcon(pageValidity[idx], isDisabled)}
                <span onClick={() => !isDisabled && goToPage(idx + 1)}>{formPage.title}</span>
              </MenuListItem>
            );
          })}
        </PaneItem>
      </Pane>
    </NavPanel>
  );
};
