export default function isValidUrl(string: string) {
  try {
    const url = new URL(string);

    // Check if the URL's protocol is either http or https.
    // This helps prevent JavaScript code execution through 'javascript:' protocol.
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    // If there's an error in parsing the URL, it's not valid.
    return false;
  }
}
