import { css } from "@emotion/core";
import { isObject, isString } from "lodash";
import React, { useEffect, useState } from "react";
import {
  FieldValues,
  FormProvider,
  UnpackNestedValue,
  useForm as useReactHookForm,
  useFormContext,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Dropdown from "./Dropdown";
import Field from "./Field";
import Input from "./Input";
import LocationPicker from "./LocationPicker";
import MultiSelect from "./MultiSelect";
import RadioGroup from "./RadioGroup";
import Submit from "./Submit";
import Textarea from "./Textarea";
import Autocomplete from "./Autocomplete";
import { loadContinueUrl } from "./util";
import InfoCard from "../InfoCard";

// @ts-ignore
type ConnectedFormProps<T> = {
  // @ts-ignore
  formMethods: UseFormReturn<T>;
  // @ts-ignore
  children: React.ReactNode;
};
function ConnectedForm<T extends FieldValues>({
  formMethods,
  children,
  ...props
}: ConnectedFormProps<T>) {
  return (
    // @ts-ignore
    <FormProvider {...formMethods}>
      <form css={css({ "&& .field": { marginBottom: "2em" } })} {...props}>
        <FormErrors />
        {children}
      </form>
    </FormProvider>
  );
}

const FormErrors = () => {
  const {
    formState: { errors },
  } = useFormContext();

  if (Object.keys(errors).length > 0) {
    console.error(
      "Please right-click, click on 'Copy object', and send this error message to the YC team ->",
      errors
    );
    return <InfoCard type="negative">Please fix the errors below before continuing.</InfoCard>;
  }

  return null;
};

type NestedObjectWithErrors = {
  [key: string]: NestedObjectWithErrors;
} & {
  errors?: { field: string; error: string }[];
};

type OnSubmitType<FieldValuesT> = (
  fields: UnpackNestedValue<FieldValuesT>
) => Promise<Object | void | undefined> | void;

// @ts-ignore
export function useForm<FieldValuesT extends FieldValues>(
  formData: FieldValuesT | undefined,
  onSubmit: OnSubmitType<FieldValuesT>,
  options?: {
    // @ts-ignore
    formProps?: UseFormProps<FieldValuesT>;
    fallbackContinueUrl?: string;
    noRedirectOnSubmit?: boolean;
  }
) {
  const formMethods = useReactHookForm<FieldValuesT>({
    shouldFocusError: true,
    ...options?.formProps,
  });

  const setServerErrors = (data: NestedObjectWithErrors, currentPath = ""): boolean => {
    // This function recursively sets errors on any fields with an associated error array.
    // The error arrays are generated by the `Types::ArModel GraphQL concern`.
    if (data == null || !isObject(data) || isString(data)) return false;
    let errorsFound = false;

    data?.errors?.forEach((err) => {
      errorsFound = true;
      formMethods.setError(
        // @ts-ignore
        `${currentPath}${err.field}`,
        { type: "server", message: err.error }
      );
    });
    const errorsFoundInChildren = Object.entries(data).some(([key, value]) =>
      // @ts-ignore
      setServerErrors(value, `${currentPath}${key}.`)
    );
    return errorsFound || errorsFoundInChildren;
  };

  const submitHandler = async (fields: UnpackNestedValue<FieldValuesT>) => {
    const resp = await onSubmit(fields);
    // @ts-ignore
    const errorsFound = setServerErrors(resp);
    if (!errorsFound) {
      // @ts-ignore
      formMethods.reset(fields);
      if (!options?.noRedirectOnSubmit) {
        loadContinueUrl(options?.fallbackContinueUrl);
      }
    }
  };

  const handledOnSubmit = (fields: any) => {
    // @ts-ignore
    formMethods.handleSubmit(submitHandler)(fields);
  };

  const [formInitialized, setFormInitialized] = useState(false);
  useEffect(() => {
    if (formData == null || formInitialized) return;
    formMethods.reset(formData);

    setServerErrors(formData);

    if (!formMethods.formState.isValid) {
      formMethods.handleSubmit(() => {});
    }

    setFormInitialized(true);
  }, [formData]);

  return {
    formMethods,
    ConnectedForm,
    connectedFormProps: { formMethods, onSubmit: handledOnSubmit },
  };
}

export {
  Field,
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  LocationPicker,
  MultiSelect,
  RadioGroup,
  Submit,
  Textarea,
  Autocomplete,
};
