import { gql, useMutation } from "@apollo/client";
import { css } from "@emotion/core";
import React from "react";
import Button from "../../components/statelessForms/Button";
import { UserAdminActionFragment } from "../cofounderMatching/fragments";
import {
  CFM_SNOOZE_PROFILE,
  CFM_SNOOZE_PROFILEVariables,
} from "./__generated__/CFM_SNOOZE_PROFILE";

type Props = {
  slug: string;
  reason: string;
  setReason?: (reason: string) => void;
  refetch?: () => any;
};

export default ({ slug, reason, setReason, refetch }: Props) => {
  const [snoozeProfile] = useMutation<CFM_SNOOZE_PROFILE, CFM_SNOOZE_PROFILEVariables>(gql`
    mutation CFM_SNOOZE_PROFILE($slug: ID!, $reason: String!) {
      cfmSnoozeProfile(slug: $slug, reason: $reason) {
        slug
        adminRating
        adminApproved
        needsAdminReview
        votes {
          ...UserAdminActionFragment
        }
      }
    }
    ${UserAdminActionFragment}
  `);

  const onClick = async () => {
    await snoozeProfile({
      variables: { slug, reason },
    });
    if (refetch) refetch();
    if (setReason) setReason("");
  };

  return (
    <div css={css({ marginBottom: 15 })}>
      <Button onClick={onClick}>Snooze profile for later review</Button>
    </div>
  );
};
