import { gql, useMutation } from "@apollo/client";
import { css } from "@emotion/core";
import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import styled from "@emotion/styled";
import { BadActorFragment } from "../cofounderMatching/fragments";
import Button from "../../components/statelessForms/Button";
import TextareaAutosize from "../../components/statelessForms/TextareaAutosize";
import Select from "../../components/statelessForms/Select";
import { StyledFormLabel } from "../../components/forms/Field";
import LoadingDots from "../../components/statelessForms/LoadingDots";

type Props = {
  slug: string;
  open?: boolean;
  onClose?: () => any;
  onSuccess?: (() => Promise<any>) | (() => any);
};

const FormContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 500px;
`;

const LabelWrapper = styled.div`
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export default ({ slug, open, onClose, onSuccess }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reason, setReason] = useState<string | undefined>();
  const [comment, setComment] = useState<string>("");

  const [createBadActorReport] = useMutation(gql`
    mutation CREATE_BAD_ACTOR_REPORT($slug: ID!, $reason: String!, $comment: String!) {
      adminReportBadActor(slug: $slug, reason: $reason, comment: $comment) {
        slug
        shadowbanned
        badActorReports {
          ...BadActorFragment
        }
      }
    }
    ${BadActorFragment}
  `);

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const variables = { reason, comment, slug };
    setReason(undefined);
    setComment("");
    await createBadActorReport({ variables });
    if (onSuccess) {
      await onSuccess();
    }
    handleClose();
    setIsSubmitting(false);
  };

  const formSection = (
    <>
      <LabelWrapper>
        <StyledFormLabel>Reason</StyledFormLabel>
      </LabelWrapper>
      <Select
        css={css({ marginTop: 10 })}
        value={reason}
        onChange={(r: any) => setReason(r)}
        options={[
          ["rude", "Rude"],
          ["spammy", "Spammy"],
          ["low_quality_applicant", "Low quality applicant"],
          ["bad_yc_history", "Bad YC history"],
          ["not_interested_in_yc", "Not interested in doing YC"],
          ["scraping", "Scraping"],
          ["fake_account", "Fake account"],
          ["devshop", "Dev Shop"],
          ["investor", "Investor"],
          ["other", "Other"],
        ]}
      />
      <LabelWrapper>
        <StyledFormLabel>Please include any relevant details.</StyledFormLabel>
      </LabelWrapper>
      <TextareaAutosize value={comment} minRows={3} onChange={(e) => setComment(e.target.value)} />
      <ButtonWrapper>
        <Button disabled={!reason || !comment} color="orange" onClick={onSubmit}>
          Submit
        </Button>
      </ButtonWrapper>
    </>
  );

  const loadingSection = (
    <div css={css({ width: "100%", display: "flex", justifyContent: "center", padding: 20 })}>
      <LoadingDots />
    </div>
  );

  return (
    <div css={css({ marginBottom: 15 })}>
      <Dialog open={open || isOpen} onClose={handleClose} scroll="body" maxWidth="md">
        <FormContainer>
          <h3>Blacklist report</h3>
          {isSubmitting ? loadingSection : formSection}
        </FormContainer>
      </Dialog>
      {open === undefined && (
        <Button onClick={() => setIsOpen(true)}>Create blacklist report</Button>
      )}
    </div>
  );
};
