import React, { useEffect, useState } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { List, Map } from "immutable";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { format, isThisYear, isToday, startOfDay, sub } from "date-fns";
import {
  CONVERSATIONS_TYPE_CONVERSATION_MESSAGES,
  CONVERSATIONS_TYPE_CONVERSATION_MESSAGES_conversations_conversationMessages,
} from "./__generated__/CONVERSATIONS_TYPE_CONVERSATION_MESSAGES";
import {
  CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews_match,
  CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews_profile,
} from "./__generated__/CONVERSATIONS_TYPE_CONVERSATION_PREVIEW";
import { COFOUNDER_MATCHING_VIEWER_cofounderMatching_profile } from "./__generated__/COFOUNDER_MATCHING_VIEWER";
import MarkMatchMeet from "../CFMMarkMatchMeet";
import ProfilePreview from "../ProfilePreview";
import useRealtime from "../../../hooks/useRealtime";
import CFMInboxInviteActions from "./CFMInboxInviteActions";
import CFMInboxNewMessageInput from "./CFMInboxNewMessageInput";
import { CFMProfileFragment } from "../fragments";
import { DesktopOnly, MobileOnly, mobileStyleCss } from "../../../styles";
import BackButton from "../../../components/statelessForms/BackButton";
import { linkified } from "../../../components/forms/util";
import CompactCFMProfile from "../CompactCFMProfile";
import CFMEndorseButton from "../CFMEndorseButton";

type Props = {
  slug: string | undefined;
  profile:
    | CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews_profile
    | undefined;
  match:
    | CONVERSATIONS_TYPE_CONVERSATION_PREVIEW_conversations_conversationPreviews_match
    | null
    | undefined;
  viewer: COFOUNDER_MATCHING_VIEWER_cofounderMatching_profile | null | undefined;
  earliestMessageSentAt: any | null;
  firebaseRealtimeKey: string;
  reloadPreviews: () => any;
  onDecline: () => any;
  deselectConversation: () => any;
};

const UpdateBox = styled.div({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const ErrorMessage = styled.div({
  marginTop: 5,
  textAlign: "center",
  color: "maroon",
  fontWeight: 500,
});

const MessagesContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - max(60px, 20%))",
  overflowY: "scroll",
});

type MessageProps = {
  isOutgoing: boolean;
};

const MessageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props: MessageProps) => (props.isOutgoing ? "flex-end" : "flex-start")};
  align-items: flex-start;
  width: 100%;
  &:first-of-type {
    margin-top: 10px;
  }
  &:last-of-type {
    margin-bottom: 10px;
  }
`;

const MobileColumnContainer = styled.div`
  ${mobileStyleCss(`
    display: flex;
    flex-direction: column;
  `)}
`;

type BaseMessageProps = {
  isOutgoing: boolean;
};

const timeDotCss = css`
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-bottom: 2px;
  background-color: gray;
  border-radius: 50%;

  ${mobileStyleCss(`
    display: none;
  `)}
`;

const BaseMessage = styled.div`
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${(props: MessageProps) => (props.isOutgoing ? "#E9EEF4" : "#D8DFE7")};
  margin: 10px 30px;
  margin-left: ${(props: MessageProps) => (props.isOutgoing ? "auto" : "30px")};
  max-width: 70%;
  align-self: ${(props: MessageProps) => (props.isOutgoing ? "flex-end" : "flex-start")};
  position: relative;
  white-space: pre-wrap;
  overflow-wrap: anywhere;

  ${mobileStyleCss(`
    font-size: 14px;
    line-height: 1.3;
  `)}
`;

const DesktopMessageTime = styled.span`
  position: absolute;
  padding: 0 5px;
  background-color: white;
  border-radius: 3px;
  width: fit-content;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: gray;
  font-weight: 100;

  ${(props: BaseMessageProps) => {
    if (props.isOutgoing) {
      return css`
        right: calc(100% + 5px);
        ::after {
          margin-left: 7px;
          ${timeDotCss}
        }
      `;
    }
    return css`
      left: calc(100% + 5px);
      ::before {
        margin-right: 7px;
        ${timeDotCss}
      }
    `;
  }}
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  a {
    flex-grow: 1;
  }
`;

const MobileMessageTime = styled.div<BaseMessageProps>`
  text-align: ${({ isOutgoing }) => (isOutgoing ? "right" : "left")};
  margin-right: ${({ isOutgoing }) => (isOutgoing ? "31px" : "0")};
  margin-left: ${({ isOutgoing }) => (isOutgoing ? "0" : "31px")};
  ${mobileStyleCss(`
    font-size: 12px;
    color: gray;
    font-weight: 100;
    margin-bottom: -8px;
  `)}
`;

const InviteProfileWrapper = styled.div`
  padding: 15px 30px 30px 30px;
  margin-top: 15px;
  border-top: 1px solid #e0e0e0;
`;

const AvatarImage = styled.img({
  borderRadius: "50%",
  backgroundColor: "#ccc",
  height: 30,
  width: 30,
  margin: "10px -20px 0 15px",
});

export default ({
  slug,
  profile,
  match,
  viewer,
  earliestMessageSentAt,
  firebaseRealtimeKey,
  reloadPreviews,
  onDecline,
  deselectConversation,
}: Props) => {
  const [hasError, setHasError] = useState(false);
  const [messagesByConvoId, setMessagesByConvoId] = useState<
    Map<string, List<CONVERSATIONS_TYPE_CONVERSATION_MESSAGES_conversations_conversationMessages>>
  >(
    Map<string, List<CONVERSATIONS_TYPE_CONVERSATION_MESSAGES_conversations_conversationMessages>>()
  );
  const messagesByConvoIdRef = React.useRef(messagesByConvoId);

  const { refetch } = useQuery<CONVERSATIONS_TYPE_CONVERSATION_MESSAGES>(
    gql`
      query CONVERSATIONS_TYPE_CONVERSATION_MESSAGES($slug: ID!, $sentBefore: ISO8601DateTime) {
        conversations {
          conversationMessages(slug: $slug, sentBefore: $sentBefore) {
            createdAt
            message
            outgoing
          }
        }
      }
    `,
    { skip: !slug, variables: { slug } }
  );

  const [loadProfileData, { data: profileData }] = useLazyQuery(gql`
    query CFM_INBOX_PROFILE_REQUEST($slug: ID) {
      cofounderMatching {
        invitesRemaining
        candidate(slug: $slug, currentContext: "inbox-web") {
          ...CFMProfileFragment
          trialSlug
          fymkAffinity
          request {
            id
            slug
            status
            message
            sentAt
            matchedAt
            metAt
            committedAt
            recommendationSubmitted
            sender {
              slug
              user {
                slug
                name
              }
            }
            receiver {
              slug
            }
            archived
          }
        }
      }
    }
    ${CFMProfileFragment}
  `);

  const matched = match?.status === "accepted";

  const awaitLoadDataForProfileSlug = async (slugOverride?: string) => {
    if (!profile && !slugOverride) {
      return;
    }

    const profileSlug = slugOverride || profile?.slug;

    await loadProfileData({ variables: { slug: profileSlug } });
  };

  const loadDataForProfileSlug = (slugOverride?: string) =>
    awaitLoadDataForProfileSlug(slugOverride);

  useEffect(() => {
    if (profile) {
      loadDataForProfileSlug(profile.slug);
    }
  }, [profile]);

  const messagesContainerRef = React.useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesContainerRef?.current?.scrollTo(0, messagesContainerRef.current.scrollHeight);
  };

  const scrollDownFromTop = () => {
    messagesContainerRef?.current?.scrollTo(0, 10);
  };

  const updateMessageThread = async (
    id: string,
    messages: List<CONVERSATIONS_TYPE_CONVERSATION_MESSAGES_conversations_conversationMessages>
  ) => {
    const updatedMessages = messagesByConvoId.set(id, messages);
    setMessagesByConvoId(updatedMessages);
    messagesByConvoIdRef.current = updatedMessages;
  };

  const loadNextPage = async (selectedSlug: string) => {
    if (!selectedSlug) {
      return;
    }

    const existingMessages = messagesByConvoIdRef?.current?.get(selectedSlug) || List();
    const earliestMessageTime = existingMessages.get(0)?.createdAt;

    const response = await refetch({ slug: selectedSlug, sentBefore: earliestMessageTime });
    const newMessages = response?.data?.conversations?.conversationMessages;

    if (!newMessages || !newMessages.length) {
      return;
    }

    const latestNewMessageTime = newMessages[newMessages.length - 1].createdAt;
    if (earliestMessageTime && earliestMessageTime > latestNewMessageTime) {
      updateMessageThread(selectedSlug, List(newMessages).concat(existingMessages));
      scrollDownFromTop();
    }
  };

  const checkForNewMessages = async (selectedSlug: string) => {
    if (!selectedSlug) {
      return;
    }
    const response = await refetch({ slug: selectedSlug, sentBefore: undefined });
    const newMessages = response?.data?.conversations?.conversationMessages;

    if (!newMessages || !newMessages.length) {
      return;
    }

    const existingMessages = messagesByConvoIdRef?.current?.get(selectedSlug) || List();
    if (!existingMessages.size) {
      updateMessageThread(selectedSlug, List(newMessages));
      if (match?.status === "accepted") {
        scrollToBottom();
      }
      return;
    }

    const latestMessage = existingMessages?.last()?.createdAt;
    if (slug === selectedSlug && latestMessage < newMessages[newMessages.length - 1].createdAt) {
      const messagesToAppend = newMessages.filter(({ createdAt }) => latestMessage < createdAt);
      updateMessageThread(selectedSlug, existingMessages.concat(List(messagesToAppend)));
      scrollToBottom();
    }
  };

  const checkIfFullyLoaded = () => {
    if (!slug) {
      return true;
    }
    const earliestLoadedCreatedAt = messagesByConvoId?.get(slug)?.get(0)?.createdAt;
    return earliestLoadedCreatedAt && earliestLoadedCreatedAt <= earliestMessageSentAt;
  };

  const getScrollHandler = (selectedSlug: string) => () => {
    if (!messagesContainerRef || !messagesContainerRef?.current || checkIfFullyLoaded()) {
      return;
    }
    if (messagesContainerRef?.current?.scrollTop === 0) {
      loadNextPage(selectedSlug);
    }
  };

  const formatDatetime = (dt: string) => {
    const date = new Date(dt);

    // Always show time message was sent (e.g. 8:45 AM, 3:10 PM, etc)
    const formattedTime = format(date, "p");

    // Only show time (no date prefix) for messages sent today
    if (isToday(date)) {
      return formattedTime;
    }

    let formattedDate;

    // If sent in the last week, show day of week (e.g. Mon)
    if (date > startOfDay(sub(new Date(), { days: 6 }))) {
      formattedDate = format(date, "E");
    }
    // If sent in the current year, only show month+day (e.g. Jan 1, Aug 4, etc)
    else if (isThisYear(date)) {
      formattedDate = format(date, "MMM d");
    }
    // If sent before this year, show full date with year (e.g. 01/01/2019, etc)
    else {
      formattedDate = format(date, "P");
    }

    return `${formattedDate}, ${formattedTime}`;
  };

  const latestMessageTime: string | null = useRealtime<string | null>(
    `inbox/${firebaseRealtimeKey}`,
    null
  );

  useEffect(() => {
    if (slug) {
      checkForNewMessages(slug);

      const scrollListener = getScrollHandler(slug);
      messagesContainerRef?.current?.addEventListener("scroll", scrollListener);

      if (match?.status === "accepted") {
        scrollToBottom();
      }

      return () => {
        messagesContainerRef?.current?.removeEventListener("scroll", scrollListener);
      };
    }
  }, [slug, latestMessageTime]);

  if (!slug || !profile || !viewer) {
    return null;
  }

  const matchFromProfileData = profileData?.cofounderMatching?.candidate?.request;

  return (
    <>
      <UpdateBox>
        {matched ? (
          <ProfilePreview
            padding={20}
            profile={profile}
            viewer={viewer}
            text={profile ? profile.intro : ""}
            dateTime={matched ? match?.matchedAt : match?.sentAt}
            dateTimePrefix={matched ? "Matched" : "Invited"}
            customHtml={
              matched && matchFromProfileData ? (
                <ActionButtons>
                  <MarkMatchMeet
                    match={matchFromProfileData}
                    onClick={loadDataForProfileSlug}
                    fullWidth
                  />
                  {match.metAt && (
                    <CFMEndorseButton
                      slug={profile.slug}
                      onSubmit={awaitLoadDataForProfileSlug}
                      isCreated={matchFromProfileData.recommendationSubmitted}
                    />
                  )}
                </ActionButtons>
              ) : (
                <div />
              )
            }
            onBackAction={deselectConversation}
            currentContext="inbox"
          />
        ) : (
          <MobileOnly otherStyles={{ display: "block", paddingTop: 15, paddingLeft: 10 }}>
            <BackButton onClick={() => deselectConversation()} mobileOnly />
          </MobileOnly>
        )}
        <MessagesContainer ref={messagesContainerRef}>
          {messagesByConvoId
            ?.get(slug)
            ?.toArray()
            ?.map((message) => (
              <MessageRow isOutgoing={message.outgoing} key={message.createdAt}>
                {!message.outgoing && (
                  <AvatarImage src={profile?.user.avatarUrl} alt="candidate avatar" />
                )}
                <MobileColumnContainer>
                  <MobileOnly>
                    <MobileMessageTime isOutgoing={message.outgoing}>
                      {formatDatetime(message.createdAt)}
                    </MobileMessageTime>
                  </MobileOnly>
                  <BaseMessage isOutgoing={message.outgoing}>
                    {linkified(message.message)}
                    <DesktopOnly>
                      <DesktopMessageTime isOutgoing={message.outgoing}>
                        {formatDatetime(message.createdAt)}
                      </DesktopMessageTime>
                    </DesktopOnly>
                  </BaseMessage>
                </MobileColumnContainer>
              </MessageRow>
            ))}
          {!matched && profileData?.cofounderMatching?.candidate && (
            <MessageRow isOutgoing={false}>
              <InviteProfileWrapper>
                <CompactCFMProfile
                  profile={profileData?.cofounderMatching?.candidate}
                  viewer={viewer}
                />
              </InviteProfileWrapper>
            </MessageRow>
          )}
        </MessagesContainer>
        {matched ? (
          <CFMInboxNewMessageInput
            slug={slug}
            checkForNewMessages={checkForNewMessages}
            setHasError={setHasError}
          />
        ) : (
          <CFMInboxInviteActions
            profileSlug={profile.slug}
            reloadPreviews={reloadPreviews}
            onDecline={onDecline}
          />
        )}
      </UpdateBox>
      {hasError && (
        <ErrorMessage>Oops! Something went wrong, and your message has not been sent.</ErrorMessage>
      )}
    </>
  );
};
