import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { CFM_PROFILE_MODAL_cofounderMatching_candidate_request } from "./__generated__/CFM_PROFILE_MODAL";
import MarkCommitted from "./CFMMarkMatchCommitted";
import MarkMatchMeet from "./CFMMarkMatchMeet";
import { BasicButton } from "../../components/statelessForms/Button";
import ArchiveMatch from "./CFMArchiveMatch";
import { Pane, PaneItem } from "../../components/Pane";
import { getConversationUrl } from "./inbox/CFMInboxFrame";

type Props = {
  slug: string;
  match: CFM_PROFILE_MODAL_cofounderMatching_candidate_request;
  trialSlug: string | null;
  reloadData: () => {};
  onArchive?: () => any;
};

const Header = styled.p`
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 14px;
`;

const Divider = styled.div`
  height: 1px;
  border-top: 1px solid #c0c0c0;
  margin: 15px 30%;
`;

export default ({ slug, match, trialSlug, reloadData, onArchive }: Props) => {
  if (match.status !== "accepted") {
    return null;
  }

  const toggleArchive = () => {
    reloadData();
    if (onArchive) {
      onArchive();
    }
  };

  return (
    <Pane>
      <PaneItem>
        <Header>Co-Founder Actions</Header>
        <BasicButton fullWidth href={getConversationUrl(match.slug)}>
          Send message
        </BasicButton>
        <Divider />
        <div css={css({ marginBottom: 10 })}>
          <MarkMatchMeet fullWidth leftAlignCheckbox match={match} onClick={reloadData} />
        </div>
        <div css={css({ marginBottom: 10 })}>
          <MarkCommitted match={match} onClick={reloadData} disabled={!match.metAt} />
        </div>
        <Divider />
        <div>
          <ArchiveMatch match={match} onToggle={toggleArchive} />
        </div>
      </PaneItem>
    </Pane>
  );
};
