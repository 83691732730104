import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import Button from "../../components/statelessForms/Button";
import { RadioGroup, Submit, Textarea, useForm } from "../../components/forms";
import { CFM_BLOCK_PROFILE, CFM_BLOCK_PROFILEVariables } from "./__generated__/CFM_BLOCK_PROFILE";
import { CfmBlockProfileInput } from "../../types/graphqlTypes";

type Props = {
  slug: string;
  hasMatched: boolean;
  onBlock: () => void;
  defaultOpen?: boolean;
};

type REASON = "external_contact" | "hiring" | "selling" | "rude" | "unresponsive" | "other";

const REPORTING_OPTIONS = new Map<REASON, string>([
  [
    "external_contact",
    "They reached out to me on a different platform (e.g. LinkedIn) without my permission",
  ],
  ["hiring", "They're trying to hire rather than find a co-founder"],
  ["selling", "They're trying to sell something"],
  ["rude", "They were rude or harassed me"],
  ["unresponsive", "We matched but they were unresponsive when I tried to schedule a time to meet"],
  ["other", "Other"],
]);

const MATCHED_ONLY_REASONS: REASON[] = ["unresponsive"];

const UNMATCHED_INFO_TEXT =
  " if you believe they are selling services, hiring, or doing anything other than actively seeking a co-founder.";
const MATCHED_INFO_TEXT =
  " if they harassed you, tried to sell you a service, or were rudely unresponsive after matching.";

export const ExpandFormButton = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

export default ({ slug, hasMatched, onBlock, defaultOpen }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cfmBlockProfile] = useMutation<CFM_BLOCK_PROFILE, CFM_BLOCK_PROFILEVariables>(gql`
    mutation CFM_BLOCK_PROFILE($input: CfmBlockProfileInput!) {
      cfmBlockProfile(input: $input) {
        slug
      }
    }
  `);

  const onSubmit = async (input: any) => {
    await cfmBlockProfile({
      variables: { input: input as CfmBlockProfileInput },
    });
    onBlock();
  };

  const initialFormValues = {
    slug,
    reason: undefined,
    other: undefined,
  };
  const { formMethods, ConnectedForm, connectedFormProps } = useForm(initialFormValues, onSubmit);

  const { isSubmitting } = formMethods.formState;
  const reason = formMethods.watch("reason");

  useEffect(() => {
    if (reason !== "other") {
      formMethods.setValue("other", undefined);
    }
  }, [reason]);

  useEffect(() => {
    formMethods.reset(initialFormValues);
    setIsOpen(false);
  }, [slug]);

  const options = REPORTING_OPTIONS;
  if (!hasMatched) {
    MATCHED_ONLY_REASONS.forEach((r) => {
      options.delete(r);
    });
  }

  if (!isOpen && !defaultOpen) {
    return (
      <div>
        <ExpandFormButton onClick={() => setIsOpen(true)}>Report this profile</ExpandFormButton>
        {hasMatched ? MATCHED_INFO_TEXT : UNMATCHED_INFO_TEXT}
      </div>
    );
  }

  return (
    <div>
      <ConnectedForm {...connectedFormProps}>
        <RadioGroup
          fieldName="reason"
          label="Why are you reporting this user?"
          required
          options={options}
          alignTop
          margin="5px 0"
        />
        {reason !== "unresponsive" && (
          <Textarea
            label="Please provide some details about what happened."
            fieldName="other"
            required
            minRows={3}
            minLength={50}
            showCharCount
          />
        )}
        <Submit hideStatusText>
          <Button type="submit" disabled={isSubmitting}>
            {reason === "unresponsive" ? "Archive" : "Report"}
          </Button>
        </Submit>
      </ConnectedForm>
    </div>
  );
};
