import React, { useEffect } from "react";
import { useIntersectionObserver } from "react-intersection-observer-hook";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { PAGE_BOTTOM_PADDING } from "../../components/Page";

const StickyBottomBar = styled.div`
  z-index: 10;
  position: fixed;
  bottom: 0;
  width: 100vw;
  margin-left: -24px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button,
  a {
    margin-bottom: 0 !important;
    min-width: 50%;
    flex-grow: 1;
    border-radius: 0;
    height: 50px;
    width: 100%;
  }
`;

type MarkerProps = {
  setIsAtBottom: (isAtBottom: boolean) => void;
};
export const StickyPageFooterMarker = ({ setIsAtBottom }: MarkerProps) => {
  const [ref, { entry }] = useIntersectionObserver();

  useEffect(() => {
    setIsAtBottom(!!entry?.isIntersecting);
  }, [entry?.isIntersecting]);

  return <div ref={ref} css={css({ position: "absolute", bottom: -PAGE_BOTTOM_PADDING })} />;
};

type Props = {
  isAtBottom: boolean | undefined;
};

export const StickyPageFooter: React.FC<Props> = ({ isAtBottom, children }) => (
  <StickyBottomBar
    css={css({
      position: isAtBottom ? "relative" : "fixed",
      marginBottom: isAtBottom ? -PAGE_BOTTOM_PADDING : 0,
    })}
  >
    {children}
  </StickyBottomBar>
);

export default StickyPageFooter;
