import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteProps as MuiAutocompleteProps } from "@material-ui/lab";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
    "& > span > img": {
      height: 60,
      width: 60,
      borderRadius: "50%",
      objectFit: "cover",
    },
  },
});

export type AutocompleteProps = Omit<MuiAutocompleteProps<any, any, any, any>, "renderInput">;

export default (props: AutocompleteProps) => {
  const classes = useStyles();
  return (
    <Autocomplete
      classes={{
        option: classes.option,
      }}
      autoHighlight
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
