import React from "react";
import InfoCard from "../../components/InfoCard";

export default () => {
  const agent = navigator.userAgent.toLowerCase();

  const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(agent);
  const isChrome = /chrome/.test(agent);

  if (!isMobile && isChrome) {
    return <div />;
  }

  const unsupported = [];
  if (isMobile) {
    unsupported.push("device");
  }
  if (!isChrome) {
    unsupported.push("browser");
  }
  const unsupportedHeaderText = `You are using an unsupported ${unsupported.join(" and ")}`;

  return (
    <InfoCard noTopMargin type="negative" header={unsupportedHeaderText}>
      <p>Please join from a laptop or desktop computer, and use the Chrome browser.</p>
    </InfoCard>
  );
};
