import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { Radio, FormControlLabel, makeStyles } from "@material-ui/core";
import Field, { CustomFieldProps } from "./Field";
import { splitProps } from "./util";

type Options<T> = [T, string | React.ReactElement][] | Map<T, string>;

type RadioGroupProps<OT> = CustomFieldProps<{
  options: Options<OT>;
  alignTop?: boolean;
  margin?: string;
}>;

type StyleProps = {
  alignTop?: boolean;
  margin?: string;
};
const useStyles = makeStyles({
  root: {
    alignItems: ({ alignTop }: StyleProps) => (alignTop ? "flex-start" : "center"),
    margin: ({ margin }: StyleProps) => margin || "default",
  },
});

export default function RadioGroup<OT extends {}>(props: RadioGroupProps<OT>) {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);
  const { field } = useController({
    control,
    name: props.fieldName,
    rules: { validate: (val) => !fieldProps.required || val != null },
  });

  const styles = useStyles(otherProps);

  return (
    <Field {...fieldProps} ref={field.ref}>
      {Array.from(otherProps.options).map(([key, label]) => {
        let normalizedLabel = label;

        // It's a React node, and Semantic expects it to be wrapped in <label>
        if (typeof normalizedLabel !== "string") normalizedLabel = <label>{normalizedLabel}</label>;

        return (
          <div key={key.toString()}>
            <FormControlLabel
              className={styles.root}
              control={<Radio size="small" color="default" />}
              label={normalizedLabel}
              value={key.toString()}
              onChange={() => field.onChange(key)}
              checked={field.value === key}
            />
          </div>
        );
      })}
    </Field>
  );
}
