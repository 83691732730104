import { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCk40RqzKPRVxQHhPHy4F-r3i12kieAoss",
  authDomain: "yc-sus.firebaseapp.com",
  databaseURL: "https://yc-sus-default-rtdb.firebaseio.com",
  projectId: "yc-sus",
  storageBucket: "yc-sus.appspot.com",
  messagingSenderId: "858411866971",
  appId: "1:858411866971:web:dfd0af6c7d6e5e8db7187e",
  measurementId: "G-4LD6JCZL2D",
};

export default function useRealtime<T>(key: string, initial: T) {
  const [payload, setPayload] = useState<T>(initial);

  useEffect(() => {
    const app = firebase.apps.length ? firebase.app() : firebase.initializeApp(firebaseConfig);
    const postsRef = app.database().ref(`startupschool/${window.RAILS_ENV}/${key}`);
    postsRef.on("value", (snapshot) => {
      setPayload(snapshot.val() as T);
    });
  }, []);

  return payload;
}

export function getClockOffset(): Promise<number> {
  const app = firebase.apps.length ? firebase.app() : firebase.initializeApp(firebaseConfig);

  return app
    .database()
    .ref("/.info/serverTimeOffset")
    .once("value")
    .then(
      (data) => data.val(),
      () => 0
    );
}
