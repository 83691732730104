import { css } from "@emotion/core";
import styled from "@emotion/styled";
import React, { ReactElement, useState } from "react";
import Helmet from "react-helmet";
import { ToastContainer } from "react-toastify";
import { mobileStyles } from "../styles";

type PageHeaderProps = {
  compact?: boolean | undefined;
};

export const Container = styled.div(
  {
    width: "100%",
    maxWidth: "1300px",
    margin: "0 auto",
    paddingLeft: 63,
    paddingRight: 63,
    ...mobileStyles({ paddingLeft: 24, paddingRight: 24 }),
  },
  (props: PageHeaderProps) =>
    props.compact
      ? {
          display: "flex",
          alignItems: "center",
        }
      : {}
);

export const ColumnContainer = styled(Container)({
  display: "flex",
  ...mobileStyles({ flexDirection: "column-reverse" }),
});

export const Header = styled.div(
  {
    width: "100%",
    backgroundColor: "#EEEEE5",
    marginBottom: 25,
    display: "flex",
    alignItems: "center",
    ...mobileStyles({
      minHeight: 60,
    }),
    h1: {
      fontSize: 34,
      fontWeight: "bold",
      color: "#1a1a1a",
      lineHeight: "140%",
      marginTop: 0,
      marginBottom: 0,
      ...mobileStyles({ fontSize: 24 }),
    },
  },
  (props: PageHeaderProps) =>
    props.compact
      ? {
          minHeight: "fit-content",
          paddingTop: 14,
          paddingBottom: 14,
          span: {
            width: 6,
            height: 6,
            marginTop: 5,
            backgroundColor: "#666",
            borderRadius: "50%",
            margin: "5px 10px 0 10px",
          },
          a: {
            marginTop: 5,
          },
        }
      : {
          minHeight: 80,
          paddingTop: 20,
          paddingBottom: 20,
        }
);

export const Subtitle = styled.div({
  fontSize: 16,
  fontWeight: 400,
  color: "#1a1a1a",
  marginTop: 3,
});

export const HeaderNav = styled.a({
  fontWeight: "bold",
  color: "#666",
  "&:hover": {
    color: "#333",
  },
});

export const LeftColumn = styled.div({
  flex: 1.6,
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  minWidth: 0,
  maxWidth: 650,
});

export const RightColumn = styled.div({
  flex: 1,
  minWidth: 0,
  maxWidth: 400,
  marginLeft: 50,
  ...mobileStyles({ marginLeft: 0, maxWidth: "100%" }),
});

type PageProps = {
  title?: string;
  subtitle?: string;
  nav?: { text: string; href: string };
  compact?: boolean;
  whiteBackground?: boolean;
  menuOptions?: ReactElement;
};

export const PAGE_BOTTOM_PADDING = 50;

export const Page = (props: PageProps & JSX.IntrinsicElements["div"]) => {
  const { title, subtitle, compact, whiteBackground, ...divProps } = props;

  const [baseTitle] = useState(document.title.split(" | ").slice(-1)[0]);

  const headerNav = props.nav && <HeaderNav href={props.nav.href}>{props.nav.text}</HeaderNav>;

  return (
    <div
      {...divProps}
      css={css({
        backgroundColor: whiteBackground ? "#ffffff" : "#fafafa",
        minHeight: "100vh",
        width: "100%",
        paddingBottom: PAGE_BOTTOM_PADDING,
      })}
    >
      {title != null && (
        <Helmet defer={false}>
          <title>
            {title} | {baseTitle}
          </title>
        </Helmet>
      )}
      {title != null && (
        <Header compact={compact}>
          <Container compact={compact}>
            <div
              css={css({
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              })}
            >
              <div>
                {!compact && headerNav}
                <h1>{title}</h1>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
                {compact && <span />}
                {compact && headerNav}
              </div>
              <div>{props.menuOptions && props.menuOptions}</div>
            </div>
          </Container>
        </Header>
      )}
      {props.children}
      <ToastContainer position="top-right" />
    </div>
  );
};
