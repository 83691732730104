import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import DailyIframe from "@daily-co/daily-js";
import { gql, useMutation } from "@apollo/client";
import { differenceInSeconds, isBefore } from "date-fns";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { SPEED_DATING_MEETING_speedDatingEvent_currentMeeting } from "./__generated__/SPEED_DATING_MEETING";

import {
  LEAVE_SPEED_DATING_MEETING,
  LEAVE_SPEED_DATING_MEETINGVariables,
} from "./__generated__/LEAVE_SPEED_DATING_MEETING";
import Button from "../../components/statelessForms/Button";
import { Pane, PaneItem } from "../../components/Pane";
import UserPreview from "../kickoffCall/UserPreview";
import {
  JOINED_SPEED_DATING_MEETING,
  JOINED_SPEED_DATING_MEETINGVariables,
} from "./__generated__/JOINED_SPEED_DATING_MEETING";
import { LIVE_EVENT_PROFILE_cofounderMatching_profile } from "./__generated__/LIVE_EVENT_PROFILE";
import PostMeetingInvite from "./PostMeetingInvite";
import CompactCFMProfile from "../cofounderMatching/CompactCFMProfile";

type TimeToEndCountdownProps = {
  focused: boolean;
};
const TimeToEndCountdown = styled.div<TimeToEndCountdownProps>`
  color: #fb651e;
  font-weight: ${({ focused }) => (focused ? "bold" : 300)};
  border: 1px solid #fb651e;
  padding: 2px 8px;
  border-radius: 5px;
  margin-left: 10px;
`;

const MeetingIsOverSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 18px;
    font-weight: normal;
    margin: 15px 0;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const MainColumn = styled.div`
  flex-grow: 1;
`;

const ProfileColumn = styled.div`
  width: 30%;
  min-width: 30%;
  margin-left: 20px;
  height: 100%;
`;

const TroubleshootingHelper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const DAILY_TROUBLESHOOTING_ARTICLE =
  "https://help.daily.co/en/articles/2303117-top-troubleshooting-5-tips-that-solve-99-of-issues";

const TopBanner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 15px;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  color: #fb651e;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const VideoCallScrollTarget = styled.div`
  position: absolute;
  margin-top: -80px;
`;

type Props = {
  meeting: SPEED_DATING_MEETING_speedDatingEvent_currentMeeting;
  viewer: LIVE_EVENT_PROFILE_cofounderMatching_profile | null;
  meetingHasEnded: boolean;
  endedByOtherUser: boolean;
  eventSlug: string;
  dailyUserToken: string;
  checkIfMeetingEnded: () => void;
  returnToLobby: () => void;
  eventType: string | undefined;
  clockOffset: number;
  inputSettings: any | undefined;
  setInputSettings: (inputSettings: any) => void;
};

let callFrame: any = null;

const SpeedDatingMeeting = ({
  eventSlug,
  dailyUserToken,
  clockOffset,
  meeting,
  meetingHasEnded,
  endedByOtherUser,
  viewer,
  checkIfMeetingEnded,
  returnToLobby,
  eventType,
  inputSettings,
  setInputSettings,
}: Props) => {
  if (!viewer && eventType === "cfm") {
    return null;
  }

  const [leaveMeeting] = useMutation<
    LEAVE_SPEED_DATING_MEETING,
    LEAVE_SPEED_DATING_MEETINGVariables
  >(gql`
    mutation LEAVE_SPEED_DATING_MEETING($slug: ID!, $leftEarly: Boolean!) {
      cfmLeaveSpeedDatingMeeting(slug: $slug, leftEarly: $leftEarly) {
        slug
      }
    }
  `);

  const [joinedSpeedDatingMeeting] = useMutation<
    JOINED_SPEED_DATING_MEETING,
    JOINED_SPEED_DATING_MEETINGVariables
  >(gql`
    mutation JOINED_SPEED_DATING_MEETING($eventSlug: ID!) {
      joinedSpeedDatingMeeting(eventSlug: $eventSlug) {
        endsAt
      }
    }
  `);

  const getSecsToTime = (targetTime: Date) => {
    const now = new Date(new Date().getTime() + clockOffset);
    if (isBefore(targetTime, now)) {
      return 0;
    }

    return differenceInSeconds(targetTime, now);
  };

  const { startsAt, endsAt, dailyCoRoom, tookPlace } = meeting;

  const endDatetime = new Date(endsAt);
  const videoCallScrollTarget = useRef(null);
  const iframeContainer = useRef(null);

  const [timeToEnd, setTimeToEnd] = useState(getSecsToTime(endDatetime));
  const [isInDailyMeeting, setIsInDailyMeeting] = useState(false);
  const [hasJoinedVideoCall, setHasJoinedVideoCall] = useState(false);

  const joinDailyMeeting = async () => {
    // @ts-ignore
    callFrame = DailyIframe.createFrame(iframeContainer.current);

    callFrame
      .on("joined-meeting", () => {
        setHasJoinedVideoCall(true);
        joinedSpeedDatingMeeting({
          variables: { eventSlug },
        });
      })
      .on("input-settings-updated", (dailyEvent: any) => {
        setInputSettings(dailyEvent.inputSettings);
      })
      .join({
        url: `https://daily-api.daily.co/${dailyCoRoom}`,
        token: dailyUserToken,
        ...(inputSettings ? { inputSettings } : {}),
      });

    setIsInDailyMeeting(true);
  };

  const leaveDailyMeeting = () => {
    setIsInDailyMeeting(false);
    if (callFrame) {
      callFrame.leave();
      callFrame.destroy();
    }
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const newTimeToEnd = meetingHasEnded ? 0 : getSecsToTime(endDatetime);

      // Show toast time warning at 10 and 30 seconds left if the users are in the meeting
      if (meeting.tookPlace && newTimeToEnd > 0) {
        if (newTimeToEnd === 30) {
          toast.warning("30 seconds left!");
        } else if (newTimeToEnd === 10) {
          toast.error("10 seconds left!");
        }
      }
      setTimeToEnd(newTimeToEnd);

      if (newTimeToEnd > 0 && !isInDailyMeeting) {
        joinDailyMeeting();
        // @ts-ignore
        // Typescript thinks this videoCallScrollTarget.current is type "never", which it's not
        videoCallScrollTarget.current?.scrollIntoView({ behavior: "smooth" });
      }
      if (newTimeToEnd === 0 && isInDailyMeeting) {
        if (meetingHasEnded) {
          leaveDailyMeeting();
        } else {
          checkIfMeetingEnded();
        }
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [tookPlace, startsAt, endsAt, meetingHasEnded, isInDailyMeeting]);

  const lookForNextMatch = () => {
    onLeaveMeeting(false);
    returnToLobby();
  };

  const confirmLeaveMeetingEarly = () => {
    if (confirm("Are you sure you want to leave this meeting early?")) {
      onLeaveMeeting(true);
    }
  };

  const onLeaveMeeting = async (leftEarly: boolean) => {
    if (isInDailyMeeting) {
      leaveDailyMeeting();
    }
    await leaveMeeting({ variables: { slug: eventSlug, leftEarly } });
  };

  const profilePreview = (
    <Pane maxHeight={700}>
      {eventType === "cfm" && meeting.candidate && viewer ? (
        <div>
          <CompactCFMProfile profile={meeting.candidate} viewer={viewer} hideLinkedin smallAvatar />
        </div>
      ) : (
        <UserPreview user={meeting.otherUser} />
      )}
    </Pane>
  );

  const leaveMeetingButton = () => (
    <div css={css({ textAlign: "center", marginTop: 15 })}>
      <Button onClick={confirmLeaveMeetingEarly}>Leave meeting now</Button>
    </div>
  );

  const postMeetRecap = () => (
    <ColumnContainer>
      <MainColumn>
        <MeetingIsOverSection>
          {endedByOtherUser && (
            <Pane>
              <PaneItem css={css({ textAlign: "center" })}>
                <h5>Your meeting was ended by {meeting.candidate?.user.firstName}.</h5>
              </PaneItem>
            </Pane>
          )}
          <PostMeetingInvite
            cfm={eventType === "cfm"}
            eventSlug={eventSlug}
            meeting={meeting}
            lookForNextMatch={lookForNextMatch}
          />
        </MeetingIsOverSection>
      </MainColumn>
      <ProfileColumn>{profilePreview}</ProfileColumn>
    </ColumnContainer>
  );

  const postFailedMeet = (
    <MeetingIsOverSection>
      <Pane>
        <PaneItem css={css({ textAlign: "center" })}>
          <h2>
            {endedByOtherUser
              ? `Your meeting was canceled by ${meeting.candidate?.user.firstName}.`
              : `It looks like ${
                  hasJoinedVideoCall ? "your match" : "you"
                } never joined the meeting.`}
          </h2>
          <div>{`${
            hasJoinedVideoCall ? "Sorry about that! " : ""
          }If you'd like to speak with someone else, click the button below.`}</div>
        </PaneItem>
      </Pane>
      <Button color="orange" onClick={lookForNextMatch}>
        Find my next match
      </Button>
    </MeetingIsOverSection>
  );

  const timerBanner = () => {
    let text = "Time remaining in this meeting:";
    if (!meeting.tookPlace) {
      if (hasJoinedVideoCall && meeting.otherUserJoined) {
        return <TopBanner>Starting meeting</TopBanner>;
      }

      const otherUserName = meeting.otherUser.firstName;
      if (hasJoinedVideoCall) {
        text = `Time remaining for ${otherUserName} to join:`;
      } else {
        const prefix = meeting.otherUserJoined ? `${otherUserName} has joined! ` : "";
        text = `${prefix}Time remaining for you to join the meeting:`;
      }
    }

    const minutesToEnd = Math.floor(timeToEnd / 60);
    const secondsToEnd = timeToEnd % 60;
    const formattedSecondsToEnd = secondsToEnd < 10 ? `0${secondsToEnd}` : secondsToEnd;
    return (
      <TopBanner>
        {text}
        <TimeToEndCountdown focused={timeToEnd <= 10}>
          {`${minutesToEnd}:${formattedSecondsToEnd}`}
        </TimeToEndCountdown>
      </TopBanner>
    );
  };

  const meetingIsOver = () => (meeting.tookPlace ? postMeetRecap() : postFailedMeet);

  const iframeHeight = () => {
    if (meetingHasEnded) {
      return 0;
    }

    return hasJoinedVideoCall ? 550 : 700;
  };

  const meetingIsActiveSection = (
    <>
      {timerBanner()}
      <TroubleshootingHelper>
        Having trouble setting up your audio or video? Check out{" "}
        <a target="_blank" href={DAILY_TROUBLESHOOTING_ARTICLE}>
          this article
        </a>
        , which should help you get set up.
      </TroubleshootingHelper>
      <ColumnContainer>
        <MainColumn>
          <VideoCallScrollTarget ref={videoCallScrollTarget} />
          <div
            ref={iframeContainer}
            css={css({
              height: iframeHeight(),
              visibility: meetingHasEnded ? "hidden" : "visible",
            })}
          />
          {leaveMeetingButton()}
        </MainColumn>
        <ProfileColumn>{profilePreview}</ProfileColumn>
      </ColumnContainer>
    </>
  );

  return <div>{meetingHasEnded ? meetingIsOver() : meetingIsActiveSection}</div>;
};

export default SpeedDatingMeeting;
