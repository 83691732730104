import { css } from "@emotion/core";
import React, { ReactElement } from "react";
import styled from "@emotion/styled";
import { Item } from "../../components/Section";
import { SPEED_DATING_MEETING_speedDatingEvent_currentMeeting_otherUser } from "../speedDating/__generated__/SPEED_DATING_MEETING";

type Props = {
  user: SPEED_DATING_MEETING_speedDatingEvent_currentMeeting_otherUser;
  padding?: number;
  maxWidth?: string;
  customHtml?: ReactElement;
};

const Divider = styled.span`
  margin: 0 10px;
`;

const DateTimeText = styled.div`
  color: #121212;
  opacity: 0.34;
  font-weight: 500;
  font-size: 14px;
`;

const CenterColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  flex-grow: 1;
`;

const CustomHtmlContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: fit-content;
  margin-left: 10px;
`;

const BackgroundSection = styled.div`
  margin: 10px 0;

  div {
    font-size: 14px;
    white-space: pre-wrap;
  }
`;

export default ({ user, padding, maxWidth, customHtml }: Props) => (
  <Item padding={padding}>
    <div
      css={css({
        display: "flex",
        width: "100%",
        maxWidth: maxWidth || 800,
      })}
    >
      {/* Flex column 1: avatar photo */}
      <img
        css={css({ borderRadius: "50%" })}
        src={user.avatarUrl}
        height={62}
        width={62}
        alt="avatar"
      />

      {/* Flex column 2: name/email/text */}
      <CenterColumnContainer>
        <div>
          <div css={css({ fontSize: 18, margin: "auto 0 5px" })}>
            <span>{user.name}</span>
            {user.linkedin && (
              <>
                <Divider>|</Divider>
                <span>
                  <a href={user.linkedin} target="_blank">
                    LinkedIn
                  </a>
                </span>
              </>
            )}
          </div>
          {user.location && <DateTimeText>{user.location}</DateTimeText>}
          {user.education && (
            <BackgroundSection>
              <b>Education:</b>
              <div>{user.education}</div>
            </BackgroundSection>
          )}
          {user.employment && (
            <BackgroundSection>
              <b>Work history:</b>
              <div>{user.employment}</div>
            </BackgroundSection>
          )}
        </div>
      </CenterColumnContainer>

      {/* Flex column 3: buttons */}
      <CustomHtmlContainer>{customHtml}</CustomHtmlContainer>
    </div>
  </Item>
);
