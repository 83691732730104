import { isBefore } from "date-fns";
import { CFM_SPEED_DATING_cofounderMatching_eligibleSpeedDatingEvents } from "../cofounderMatching/speedDating/__generated__/CFM_SPEED_DATING";
import { SPEED_DATING_EVENT_speedDatingEvent } from "./__generated__/SPEED_DATING_EVENT";

export const EVENT_STATES = {
  upcoming: "upcoming",
  inProgress: "inProgress",
  finished: "finished",
};

export const getEventState = (startDate: string, endDate: string, clockOffset: number = 0) => {
  const start = new Date(startDate);
  const now = new Date(new Date().getTime() + clockOffset);
  const end = new Date(endDate);

  if (isBefore(now, start)) {
    return EVENT_STATES.upcoming;
  }

  if (isBefore(now, end)) {
    return EVENT_STATES.inProgress;
  }

  return EVENT_STATES.finished;
};

export const formatDate = (
  event:
    | CFM_SPEED_DATING_cofounderMatching_eligibleSpeedDatingEvents
    | SPEED_DATING_EVENT_speedDatingEvent
) => {
  const start = new Date(event.startsAt);
  const end = new Date(event.endsAt);

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  const endTimeOptions: Intl.DateTimeFormatOptions = { ...timeOptions, timeZoneName: "short" };

  const day = start.toLocaleDateString(undefined, dateOptions);
  const startTime = start.toLocaleTimeString(undefined, timeOptions);
  const endTime = end.toLocaleTimeString(undefined, endTimeOptions);
  return `${day}, ${startTime} - ${endTime}`;
};

export const formatStartDate = (
  event:
    | CFM_SPEED_DATING_cofounderMatching_eligibleSpeedDatingEvents
    | SPEED_DATING_EVENT_speedDatingEvent
) => {
  const start = new Date(event.startsAt);

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };

  const day = start.toLocaleDateString(undefined, dateOptions);
  const startTime = start.toLocaleTimeString(undefined, timeOptions);
  return `${day} at ${startTime}`;
};
