import React from "react";
import { useController, useFormContext } from "react-hook-form";
import Select, { Props as SelectProps } from "../statelessForms/Select";
import Field, { CustomFieldProps } from "./Field";
import { splitProps } from "./util";

type Props = CustomFieldProps<Omit<SelectProps, "onChange">>;

export default function Dropdown(props: Props) {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);
  const { field } = useController({
    control,
    name: props.fieldName,
    rules: { required: fieldProps.required },
  });

  return (
    <Field {...fieldProps}>
      <Select {...otherProps} value={field.value} onChange={field.onChange} />
    </Field>
  );
}
