import React from "react";
import styled from "@emotion/styled";
import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/core";
import ProfilePreview, { ProfilePreviewFields } from "../cofounderMatching/ProfilePreview";
import { BasicUserFragment, CFMViewerProfileFragment } from "../cofounderMatching/fragments";
import Section from "../../components/Section";
import UserPreview from "../kickoffCall/UserPreview";
import { COMPLETED_SPEED_DATING_EVENT } from "./__generated__/COMPLETED_SPEED_DATING_EVENT";
import Button from "../../components/statelessForms/Button";
import {
  REQUEST_FOLLOWUP_COMPLETED,
  REQUEST_FOLLOWUP_COMPLETEDVariables,
} from "./__generated__/REQUEST_FOLLOWUP_COMPLETED";

type Props = {
  eventSlug: string | undefined;
};

type NumberOfMeetingsTextProps = {
  noMeets?: boolean;
};
const NumberOfMeetingsText = styled.div<NumberOfMeetingsTextProps>`
  text-align: center;
  margin: 20px;
  font-size: 20px;
  font-weight: bold;
  color: ${({ noMeets }) => (noMeets ? "gray" : "black")};
`;

const CompletedSpeedDatingEvent = ({ eventSlug }: Props) => {
  if (!eventSlug) {
    return null;
  }

  const { data, refetch } = useQuery<COMPLETED_SPEED_DATING_EVENT>(
    gql`
      query COMPLETED_SPEED_DATING_EVENT($slug: ID!) {
        speedDatingEvent(slug: $slug) {
          slug
          attended
          feedbackFormUrl
          eventType
          usersMet {
            ...BasicUserFragment
            firstName
          }
          profilesMet {
            intro
            ...ProfilePreviewFields
          }
          userSlugsRequestedToContact
        }
        cofounderMatching {
          profile {
            ...ProfilePreviewFields
            ...CFMViewerProfileFragment
          }
        }
      }
      ${BasicUserFragment}
      ${ProfilePreviewFields}
      ${CFMViewerProfileFragment}
    `,
    { variables: { slug: eventSlug } }
  );

  const [requestFollowup] = useMutation<
    REQUEST_FOLLOWUP_COMPLETED,
    REQUEST_FOLLOWUP_COMPLETEDVariables
  >(gql`
    mutation REQUEST_FOLLOWUP_COMPLETED($eventSlug: ID!, $userSlug: ID!) {
      speedDatingRequestFollowup(eventSlug: $eventSlug, userSlug: $userSlug) {
        success
      }
    }
  `);

  const event = data?.speedDatingEvent;
  if (!event) {
    return null;
  }

  if (event.eventType === "cfm" && !data?.cofounderMatching?.profile) {
    return null;
  }

  const optInToConnect = async (userSlug: string) => {
    await requestFollowup({
      variables: {
        eventSlug: event.slug,
        userSlug,
      },
    });

    refetch();
  };

  const { attended, feedbackFormUrl } = event;

  const isCfm = event.eventType === "cfm";
  const userOrProfileList = isCfm ? event.profilesMet : event.usersMet;
  const label = isCfm ? "potential co-founder" : "founder";

  return (
    <div>
      <div css={css({ textAlign: "center", color: "gray", fontWeight: "bold" })}>
        This event has ended.
      </div>

      {userOrProfileList && userOrProfileList.length ? (
        <>
          <NumberOfMeetingsText>
            {`You met with ${userOrProfileList.length} ${label}${
              userOrProfileList.length > 1 ? "s" : ""
            } during this event!`}
          </NumberOfMeetingsText>
          <Section>
            {isCfm &&
              event.profilesMet.map((profile) => (
                <ProfilePreview
                  key={profile.slug}
                  profile={profile}
                  viewer={data?.cofounderMatching.profile!}
                  text={profile.intro}
                  currentContext="speedDating"
                />
              ))}
            {!isCfm &&
              event.usersMet.map((user) => {
                const hasRequestedContact = event.userSlugsRequestedToContact.includes(user.slug);
                const onClick = () => optInToConnect(user.slug);
                const connectButton = (
                  <Button color="yellow" disabled={hasRequestedContact} onClick={onClick}>
                    {hasRequestedContact ? "You have opted in to connect" : "Opt in to connect"}
                  </Button>
                );
                return (
                  <UserPreview
                    key={user.slug}
                    user={user}
                    customHtml={connectButton}
                    maxWidth="100%"
                  />
                );
              })}
          </Section>
        </>
      ) : (
        <NumberOfMeetingsText noMeets>
          You did not meet with any founders during this event.
        </NumberOfMeetingsText>
      )}
      {attended && feedbackFormUrl && (
        <div css={css({ textAlign: "center" })}>
          <a href={feedbackFormUrl} target="_blank">
            Give us your feedback on the event!
          </a>
        </div>
      )}
    </div>
  );
};

export default CompletedSpeedDatingEvent;
