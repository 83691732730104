import styled from "@emotion/styled";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import {
  Checkbox,
  CheckboxProps,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Field, { CustomFieldProps } from "./Field";
import { splitProps } from "./util";

type MultiSelectProps<OT> = CustomFieldProps<
  {
    options: [OT, string][];
    disabledValues?: OT[];
    customOption?: React.ReactElement;
    small?: boolean;
    noneOption?: string;
  } & CheckboxProps
>;

export const useSmallStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 14,
    },
  })
);

const OptionWrapper = styled.div`
  margin-bottom: 4px;
`;

export default function MultiSelect<OT extends {}>(props: MultiSelectProps<OT>) {
  const { control } = useFormContext();
  const { fieldProps, otherProps } = splitProps(props);

  const { field } = useController({
    control,
    name: fieldProps.fieldName,
    rules: { required: fieldProps.required },
  });

  const smallStyles = useSmallStyles();
  const classes = otherProps.small ? { label: smallStyles.label } : {};

  return (
    <Field {...fieldProps} ref={field.ref}>
      {otherProps.noneOption && (
        <OptionWrapper>
          <FormControlLabel
            control={<Checkbox color="default" />}
            label={otherProps.noneOption}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                field.onChange([]);
              }
            }}
            checked={!field.value?.length}
            classes={classes}
          />
        </OptionWrapper>
      )}
      {Array.from(otherProps.options).map(([optionValue, label]) => (
        <OptionWrapper key={optionValue.toString()}>
          <FormControlLabel
            control={<Checkbox color="default" />}
            label={label}
            value={optionValue.toString()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const vals = new Set(field.value);
              if (e.target.checked) vals.add(optionValue);
              else vals.delete(optionValue);

              field.onChange(Array.from(vals));
            }}
            checked={!!field.value && field.value.includes(optionValue)}
            disabled={otherProps.disabledValues && otherProps.disabledValues.includes(optionValue)}
            classes={classes}
          />
        </OptionWrapper>
      ))}
      {otherProps.customOption}
    </Field>
  );
}
