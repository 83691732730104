import React from "react";
import styled from "@emotion/styled";
import { formatDistance } from "date-fns";
import { css } from "@emotion/core";
import { COFOUNDER_ADMIN_PROFILE_admin_cofounderMatching_adminProfile_recentDirectorySearches } from "./__generated__/COFOUNDER_ADMIN_PROFILE";
import {
  AnyCFMProfile,
  HasIdeaText,
  InterestsText,
  ResponsibilitiesText,
  TechnicalText,
  TimingText,
} from "../cofounderMatching/profileFields";
import { REGION_OPTIONS } from "../cofounderMatching/directory/DirectoryFilters";

type Props = {
  searches: COFOUNDER_ADMIN_PROFILE_admin_cofounderMatching_adminProfile_recentDirectorySearches[];
  profile: AnyCFMProfile;
};

const SearchesContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f0fafd;
  max-height: 400px;
  overflow: scroll;
  margin-top: 20px;

  h4 {
    font-size: 18px;
  }

  ul {
    font-size: 14px;
    padding-left: 15px;
  }

  p {
    margin-bottom: 0;
  }
`;

const Keyword = styled.span`
  font-weight: bold;
`;

const NoValue = styled.span`
  font-style: italic;
  color: gray;
`;

const FilterKey = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
`;

const IDEAS = {
  committed: "committed",
  open: "open",
  none: "none",
};

const TIMINGS = {
  active: "active",
  ready: "ready",
  year: "year",
  passive: "passive",
};

const DEFAULT_VALUES: any = {
  has_idea: {
    true: [IDEAS.committed, IDEAS.open],
    false: [IDEAS.open, IDEAS.none],
  },
  timing: {
    active: [TIMINGS.active, TIMINGS.ready],
    ready: [TIMINGS.active, TIMINGS.ready],
    year: [TIMINGS.year, TIMINGS.passive],
    passive: [TIMINGS.year, TIMINGS.passive],
  },
  ageMin: 12,
  ageMax: 100,
};

export default ({ searches, profile }: Props) => {
  const arraysEq = (arr1: any[] | null | undefined, arr2: any[]) =>
    [...(arr1 || [])].sort().join(",") === [...arr2].sort().join(",");

  const filterNotPresent = (arr: any[] | null | undefined, allValuesCount: number = 0) =>
    !arr?.length || arr.length === allValuesCount;

  const isReq = (importance: keyof AnyCFMProfile) => profile[importance] === "require";

  const formatFilterValue = (arr: any[] | undefined | null) =>
    arr?.length ? arr.join(" or ") : undefined;

  const getRelevantFilters = (filterStr: string) => {
    const result = [];
    const filters = JSON.parse(filterStr);

    const {
      technical,
      has_idea,
      timing,
      locations,
      regions,
      interests,
      responsibilities,
      women_only,
      yc_alum_only,
      fellow_alumni_only,
      exclude_skipped,
      age_min,
      age_max,
    } = filters;

    const technicalFilterUnchanged = isReq("cfIsTechnicalImportance")
      ? arraysEq(technical, [profile.cfIsTechnical])
      : filterNotPresent(technical, TechnicalText.size);
    if (!technicalFilterUnchanged) {
      result.push(["technical", formatFilterValue(technical)]);
    }

    const hasIdeaFilterUnchanged = isReq("cfHasIdeaImportance")
      ? arraysEq(has_idea, DEFAULT_VALUES.has_idea[`${profile.cfHasIdea}`])
      : filterNotPresent(has_idea, HasIdeaText.size);
    if (!hasIdeaFilterUnchanged) {
      result.push(["idea", formatFilterValue(has_idea)]);
    }

    const timingFilterUnchanged = isReq("cfTimingImportance")
      ? arraysEq(timing, DEFAULT_VALUES.timing[profile.timing])
      : filterNotPresent(timing, TimingText.size);
    if (!timingFilterUnchanged) {
      result.push(["timing", formatFilterValue(timing)]);
    }

    const locationsFilterUnchanged =
      isReq("cfLocationImportance") && profile.cfLocation === "geographic"
        ? arraysEq(locations, [profile.user.location])
        : filterNotPresent(locations);
    if (!locationsFilterUnchanged) {
      result.push(["city", formatFilterValue(locations)]);
    }

    const regionsFilterUnchanged =
      isReq("cfLocationImportance") && profile.cfLocation === "region"
        ? arraysEq(regions, [profile.user.region])
        : filterNotPresent(regions, REGION_OPTIONS.length);
    if (!regionsFilterUnchanged) {
      result.push(["region", formatFilterValue(regions)]);
    }

    const interestsFilterUnchanged = isReq("cfInterestsImportance")
      ? arraysEq(interests, profile.interests)
      : filterNotPresent(interests, InterestsText.size);
    if (!interestsFilterUnchanged) {
      result.push(["interests", formatFilterValue(interests)]);
    }

    const responsibilitiesFilterUnchanged = isReq("cfResponsibilitiesImportance")
      ? arraysEq(responsibilities, profile.cfResponsibilities)
      : filterNotPresent(responsibilities, ResponsibilitiesText.size);
    if (!responsibilitiesFilterUnchanged) {
      result.push(["responsibilities", formatFilterValue(responsibilities)]);
    }

    const womenOnlyFilterUnchanged =
      "cfIsWomanImportance" in profile && profile.cfIsWomanImportance === "require"
        ? women_only
        : !women_only;
    if (!womenOnlyFilterUnchanged) {
      result.push(["women only", women_only ? "Yes" : undefined]);
    }

    const fellowAlumniOnlyFilterUnchanged =
      "cfIsFellowAlumni" in profile && profile.cfIsFellowAlumni === "require"
        ? fellow_alumni_only
        : !fellow_alumni_only;
    if (!fellowAlumniOnlyFilterUnchanged) {
      result.push(["fellow alumni only", fellow_alumni_only ? "Yes" : undefined]);
    }

    if (exclude_skipped) {
      result.push(["exclude skipped profiles", "Yes"]);
    }

    if (yc_alum_only) {
      result.push(["YC founders only", "Yes"]);
    }

    if (age_min && age_min !== DEFAULT_VALUES.ageMin) {
      result.push(["Min age", age_min]);
    }

    if (age_max && age_max !== DEFAULT_VALUES.ageMax) {
      result.push(["Max age", age_max]);
    }

    return result;
  };

  return (
    <SearchesContainer>
      <h4>Most recent {searches.length} directory searches</h4>
      <ul>
        {searches.map((search) => (
          <li>
            <p>
              {search.keyword ? <Keyword>{search.keyword}</Keyword> : <NoValue>No keyword</NoValue>}

              <span>
                {" "}
                (page <b>{(search.page || 0) + 1}</b>, with <b>{search.numResults}</b> results)
              </span>

              <span css={css({ color: "gray" })}>
                <i>
                  {" "}
                  {formatDistance(new Date(search.createdAt), new Date(), { addSuffix: true })}
                </i>
              </span>
              {getRelevantFilters(search.filters).map(([key, value]) => (
                <p key={key}>
                  <FilterKey>{key}</FilterKey>:{" "}
                  {value === undefined ? <NoValue>filter removed</NoValue> : <b>{value}</b>}
                </p>
              ))}
            </p>
            <br />
          </li>
        ))}
      </ul>
    </SearchesContainer>
  );
};
