import React from "react";
import { omit } from "lodash";
import { Button, makeStyles, withStyles } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button/Button";

const defaultProps: ButtonProps = {
  variant: "contained",
  disableElevation: true,
};

const BaseButton = withStyles({
  root: {
    textTransform: "none",
    padding: "6px 17px",
  },
  sizeSmall: {
    padding: "5px 15px",
  },
})(Button);

export type Color = "green" | "yellow" | "orange" | "red" | "gray" | "blue";
export const mainHoverActionColors = new Map<Color, string[]>([
  ["green", ["#21ba45", "#16ab39", "#198f35"]],
  ["yellow", ["#fbbd08", "#eaae00", "#cd9903"]],
  ["orange", ["#fb651e", "#ff5200", "#e24b04"]],
  ["red", ["#db2828", "#d01919", "#b21e1e"]],
  ["gray", ["#767676", "#838383", "#909090"]],
  ["blue", ["#627da6", "#516c95", "#405b84"]],
]);

type StyleProps = {
  colors: string[] | undefined;
  size: "small" | "medium" | "large" | undefined;
};
const useStyles = makeStyles({
  root: {
    backgroundColor: ({ colors }: StyleProps) => (colors ? colors[0] : "inherit"),
    color: "white",
    fontWeight: "bold",
    "&:hover:not(:disabled)": {
      backgroundColor: ({ colors }: StyleProps) => (colors ? colors[1] : "inherit"),
      color: "white",
    },
    "&:active:not(:disabled)": {
      backgroundColor: ({ colors }: StyleProps) => (colors ? colors[2] : "inherit"),
    },
    "&:disabled": {
      backgroundColor: ({ colors }: StyleProps) => (colors ? colors[0] : "inherit"),
      color: "white",
      opacity: "0.45 !important",
    },
    fontSize: ({ size }: StyleProps) => (size === "small" ? 12 : "inherit"),
  },
});

type CustomButtonProps = {
  color?: Color;
  size?: "small" | "medium" | "large";
};
const customButtonPropKeys: (keyof Required<CustomButtonProps>)[] = ["color"];

export default (props: Omit<ButtonProps, "color"> & CustomButtonProps) => {
  const styles = useStyles({
    colors: props.color && mainHoverActionColors.get(props.color),
    size: props.size,
  });
  const styleProps = props.color ? { className: styles.root } : {};
  const passedProps = omit(props, customButtonPropKeys);
  return <BaseButton {...{ ...styleProps, ...defaultProps, ...passedProps }} />;
};

const StyledBasicButton = withStyles({
  root: {
    background: "transparent none",
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: "normal",
    borderRadius: 4,
    boxShadow: "0 0 0 1px rgb(34 36 38 / 15%) inset",
    "&:hover:not(:disabled)": {
      color: "rgba(0, 0, 0, 0.8)",
      background: "white",
      boxShadow: "0 0 0 1px rgb(34 36 38 / 35%) inset, 0 0 0 0 rgb(34 36 38 / 15%) inset",
    },
    "&:active:not(:disabled)": {
      background: "#F8F8F8",
      color: "rgba(0, 0, 0, 0.9)",
      boxShadow: "0 0 0 1px rgb(0 0 0 / 15%) inset, 0 1px 4px 0 rgb(34 36 38 / 15%) inset",
    },
    "&:disabled": {
      background: "transparent none",
      opacity: "0.45 !important",
      boxShadow: "0 0 0 1px rgb(34 36 38 / 15%) inset",
      borderRadius: 4,
    },
  },
})(BaseButton);

export const BasicButton = (props: ButtonProps) => (
  <StyledBasicButton disableRipple {...{ ...defaultProps, ...props }} />
);
