import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { gql, useMutation } from "@apollo/client";
import { withStyles } from "@material-ui/core";
import { REQUEST_FOLLOWUP, REQUEST_FOLLOWUPVariables } from "./__generated__/REQUEST_FOLLOWUP";
import { SPEED_DATING_MEETING_speedDatingEvent_currentMeeting } from "./__generated__/SPEED_DATING_MEETING";
import Button from "../../components/statelessForms/Button";
import {
  SPEED_DATING_CFM_REQUEST_MATCH,
  SPEED_DATING_CFM_REQUEST_MATCHVariables,
} from "./__generated__/SPEED_DATING_CFM_REQUEST_MATCH";
import TextareaAutosize from "../../components/statelessForms/TextareaAutosize";
import {
  SPEED_DATING_CFM_ACCEPT_REQUEST,
  SPEED_DATING_CFM_ACCEPT_REQUESTVariables,
} from "./__generated__/SPEED_DATING_CFM_ACCEPT_REQUEST";
import {
  SPEED_DATING_CFM_DECLINE_REQUEST,
  SPEED_DATING_CFM_DECLINE_REQUESTVariables,
} from "./__generated__/SPEED_DATING_CFM_DECLINE_REQUEST";
import { getConversationUrl } from "../cofounderMatching/inbox/CFMInboxFrame";
import {
  SPEED_DATING_CFM_DISMISS_PROFILE,
  SPEED_DATING_CFM_DISMISS_PROFILEVariables,
} from "./__generated__/SPEED_DATING_CFM_DISMISS_PROFILE";
import { Pane, PaneItem } from "../../components/Pane";

type Props = {
  eventSlug: string;
  cfm: boolean;
  meeting: SPEED_DATING_MEETING_speedDatingEvent_currentMeeting;
  lookForNextMatch: () => any;
};

const InviteContainer = styled.div`
  width: 100%;
  text-align: center;
  p {
    margin-bottom: 10px;
  }
`;

const Or = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
  letter-spacing: 1px;

  div {
    color: gray;
    font-weight: 300;
    margin: 0 7px;
  }

  span {
    height: 2px;
    width: 30px;
    background-color: #e0e0e0;
  }
`;

const TakeActionPrompt = styled.span`
  font-size: 14px;
  margin-top: 10px;
  font-style: italic;
`;

const ExtraLargeButton = withStyles({
  root: {
    width: 300,
    height: 50,
    fontSize: 16,
  },
})(Button);

export default ({ eventSlug, cfm, meeting, lookForNextMatch }: Props) => {
  const [invited, setInvited] = useState(false);
  const [dismissed, setDismissed] = useState(false);
  const [acceptedInvite, setAcceptedInvite] = useState<boolean | null>(null);
  const [message, setMessage] = useState("");

  const [requestFollowup] = useMutation<REQUEST_FOLLOWUP, REQUEST_FOLLOWUPVariables>(gql`
    mutation REQUEST_FOLLOWUP($eventSlug: ID!, $userSlug: ID!) {
      speedDatingRequestFollowup(eventSlug: $eventSlug, userSlug: $userSlug) {
        success
      }
    }
  `);

  const [cfmRequestMatch] = useMutation<
    SPEED_DATING_CFM_REQUEST_MATCH,
    SPEED_DATING_CFM_REQUEST_MATCHVariables
  >(gql`
    mutation SPEED_DATING_CFM_REQUEST_MATCH($slug: ID!, $requestMessage: String!) {
      cfmRequestMatch(
        slug: $slug
        requestMessage: $requestMessage
        currentContext: "speed-dating"
      ) {
        slug
      }
    }
  `);

  const [cfmAcceptRequest] = useMutation<
    SPEED_DATING_CFM_ACCEPT_REQUEST,
    SPEED_DATING_CFM_ACCEPT_REQUESTVariables
  >(gql`
    mutation SPEED_DATING_CFM_ACCEPT_REQUEST($slug: ID!) {
      cfmAcceptRequest(slug: $slug, currentContext: "speed-dating") {
        slug
      }
    }
  `);

  const [cfmDeclineRequest] = useMutation<
    SPEED_DATING_CFM_DECLINE_REQUEST,
    SPEED_DATING_CFM_DECLINE_REQUESTVariables
  >(gql`
    mutation SPEED_DATING_CFM_DECLINE_REQUEST($slug: ID!) {
      cfmDeclineRequest(slug: $slug, currentContext: "speed-dating") {
        slug
      }
    }
  `);

  const [cfmDismissProfile] = useMutation<
    SPEED_DATING_CFM_DISMISS_PROFILE,
    SPEED_DATING_CFM_DISMISS_PROFILEVariables
  >(gql`
    mutation SPEED_DATING_CFM_DISMISS_PROFILE($slug: ID!) {
      cfmHideProfile(slug: $slug, currentContext: "speed-dating") {
        slug
      }
    }
  `);

  const sendInvite = () => {
    if (cfm) {
      if (!meeting.candidate) {
        return;
      }

      cfmRequestMatch({
        variables: {
          slug: meeting.candidate.slug,
          requestMessage: message,
        },
      });
    } else {
      requestFollowup({
        variables: {
          eventSlug,
          userSlug: meeting.otherUser.slug,
        },
      });
    }
    setInvited(true);
  };

  const dismissProfile = () => {
    if (!cfm || !meeting.candidate) {
      return;
    }

    cfmDismissProfile({
      variables: {
        slug: meeting.candidate.slug,
      },
    });

    setDismissed(true);
  };

  const resolvePendingInvite = (accepted: boolean) => {
    const slug = meeting.candidate?.request?.slug;
    if (!slug) {
      return;
    }

    const variables = { slug };
    if (accepted) {
      cfmAcceptRequest({ variables });
    } else {
      cfmDeclineRequest({ variables });
    }

    setAcceptedInvite(accepted);
  };

  const nextMatchButton = (disabled?: boolean) => (
    <ExtraLargeButton color="orange" onClick={lookForNextMatch} disabled={disabled}>
      Find my next match
    </ExtraLargeButton>
  );

  const userInvite = (
    <>
      <InviteContainer>
        <Pane>
          <PaneItem>
            <h2>Would you like to continue the conversation?</h2>
            <span css={css({ marginBottom: 10 })}>
              If you both opt in, we'll send you both a follow-up email so that you can stay
              connected.
            </span>
            <div css={css({ marginTop: 20 })}>
              <Button color="yellow" disabled={invited} onClick={sendInvite}>
                {invited ? "You've opted in to connect!" : "Opt in to connect"}
              </Button>
            </div>
          </PaneItem>
        </Pane>
      </InviteContainer>
      {nextMatchButton()}
    </>
  );

  const sendMessageLink = (
    <span>
      To continue the conversation,{" "}
      <a href={getConversationUrl(meeting.candidate?.request?.slug || null)}>send a message.</a>
    </span>
  );

  const existingMatch = (
    <InviteContainer>
      <h2>{`You have already matched with ${meeting.otherUser.firstName}!`}</h2>
      {sendMessageLink}
    </InviteContainer>
  );

  const pendingCfmInvite = () => {
    if (meeting.candidate?.request?.outbound) {
      return (
        <InviteContainer>
          <h2>{`You have already invited ${meeting.otherUser.firstName} to connect!`}</h2>
        </InviteContainer>
      );
    }

    if (acceptedInvite === null) {
      return (
        <InviteContainer>
          <h2>{`${meeting.otherUser.firstName} has invited you to connect!`}</h2>
          <div css={css({ lineHeight: 1.3 })}>
            <blockquote
              css={css({
                background: "#f1f1f1",
                borderLeft: "7px solid #ccc",
                marginLeft: 10,
                padding: "10px 15px",
                borderRadius: 5,
                textAlign: "left",
              })}
            >
              {meeting.candidate?.request?.message}
            </blockquote>
            <div
              css={css({
                display: "flex",
                "& > button": {
                  flex: 1,
                  "&:first-of-type": { marginRight: 3 },
                  "&:last-of-type": { marginLeft: 3 },
                },
              })}
            >
              <Button color="green" onClick={() => resolvePendingInvite(true)}>
                Accept
              </Button>
              <Button color="red" onClick={() => resolvePendingInvite(false)}>
                Ignore
              </Button>
            </div>
          </div>
        </InviteContainer>
      );
    }

    return (
      <InviteContainer>
        <h2>
          {acceptedInvite
            ? `Congratulations - ${meeting.otherUser.firstName} is now a match!`
            : `You have declined ${meeting.otherUser.firstName}'s invitation.`}
        </h2>
        {acceptedInvite && sendMessageLink}
      </InviteContainer>
    );
  };

  const cfmInvite = () => {
    if (meeting.candidate?.request?.status === "accepted") {
      return (
        <>
          <Pane>
            <PaneItem>{existingMatch}</PaneItem>
          </Pane>
          {nextMatchButton()}
        </>
      );
    }

    if (meeting.candidate?.request?.status === "sent") {
      const needsToRespondToRequest =
        !meeting.candidate?.request?.outbound && acceptedInvite === null && !invited && !dismissed;
      return (
        <>
          <Pane>
            <PaneItem>{pendingCfmInvite()}</PaneItem>
          </Pane>
          {nextMatchButton(needsToRespondToRequest)}
          {needsToRespondToRequest && (
            <TakeActionPrompt>
              Before moving on, please either accept or decline the invitation.
            </TakeActionPrompt>
          )}
        </>
      );
    }

    const invitationSent = (
      <InviteContainer>
        <h2>Your invitation has been sent!</h2>
        <span>{`We'll let you know if ${meeting.otherUser.firstName} accepts your invitation.`}</span>
      </InviteContainer>
    );

    const dismissalSent = (
      <InviteContainer>
        <h2>Thanks for letting us know it wasn't a good fit.</h2>
        <span>We won't show you this founder again.</span>
      </InviteContainer>
    );

    const inviteOrDismissSection = (
      <>
        <InviteContainer>
          <h2>Would you like to continue the conversation?</h2>
          <p>
            Invites sent within 24 hours of meeting don't count against your 15-invite-per-week
            limit!
          </p>
          <TextareaAutosize
            value={message}
            minRows={5}
            onChange={({ target }) => setMessage(target.value)}
            placeholder="If you'd like to invite this founder to match, enter your message here."
            fullWidth
          />
          <Button color="green" onClick={sendInvite} disabled={!message} fullWidth>
            Send invitation
          </Button>
          <Or>
            <span />
            <div>OR</div>
            <span />
          </Or>
          <Button color="gray" onClick={dismissProfile} fullWidth>
            This wasn't a good match for me.
          </Button>
        </InviteContainer>
      </>
    );

    const noActionTaken = !invited && !dismissed;

    return (
      <>
        <Pane>
          <PaneItem>
            {noActionTaken && inviteOrDismissSection}
            {invited && invitationSent}
            {dismissed && dismissalSent}
          </PaneItem>
        </Pane>
        {nextMatchButton(noActionTaken)}
        {noActionTaken && (
          <TakeActionPrompt>
            Before moving on, please either invite this founder to connect or let us know it wasn't
            a good fit.
          </TakeActionPrompt>
        )}
      </>
    );
  };

  return <>{cfm ? cfmInvite() : userInvite}</>;
};
