import { gql, useMutation } from "@apollo/client";
import React from "react";
import { css } from "@emotion/core";
import CheckmarkButton from "../../components/Button";
import { MARK_MATCH_MEET, MARK_MATCH_MEETVariables } from "./__generated__/MARK_MATCH_MEET";
import { MarkMatchMeetFields as MarkMatchMeetFieldsType } from "./__generated__/MarkMatchMeetFields";
import {
  UNDO_MARK_MATCH_MEET,
  UNDO_MARK_MATCH_MEETVariables,
} from "./__generated__/UNDO_MARK_MATCH_MEET";

type Props = {
  match: MarkMatchMeetFieldsType;
  fullWidth?: boolean;
  leftAlignCheckbox?: boolean;
  onClick?: () => void;
};

export const MarkMatchMeetFields = gql`
  fragment MarkMatchMeetFields on CofounderRequest {
    id
    metAt
  }
`;

export default ({ match, fullWidth, leftAlignCheckbox, onClick }: Props) => {
  const [markMatchMeet] = useMutation<MARK_MATCH_MEET, MARK_MATCH_MEETVariables>(gql`
    mutation MARK_MATCH_MEET($matchId: ID!) {
      markCofounderMatchMeet(matchId: $matchId) {
        id
        metAt
      }
    }
  `);

  const [undoMarkMatchMeet] = useMutation<UNDO_MARK_MATCH_MEET, UNDO_MARK_MATCH_MEETVariables>(gql`
    mutation UNDO_MARK_MATCH_MEET($matchId: ID!) {
      undoMarkCofounderMatchMeet(matchId: $matchId) {
        id
        metAt
      }
    }
  `);

  return (
    <CheckmarkButton
      minimal
      leftAlignCheckbox={leftAlignCheckbox}
      type={match.metAt ? "minimalSuccess" : "minimalInactiveSuccess"}
      size="small"
      {...(fullWidth ? { css: css({ width: "100%" }) } : {})}
      onClick={async () => {
        const variables = { matchId: match.id };
        if (match.metAt) {
          await undoMarkMatchMeet({ variables });
        } else {
          await markMatchMeet({ variables });
        }

        if (onClick) {
          onClick();
        }
      }}
      text="We met!"
    />
  );
};
