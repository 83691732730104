import { CFM_RECOMMENDATIONS } from "./__generated__/CFM_RECOMMENDATIONS";
import {
  CFM_RECOMMENDATION_MODAL,
  CFM_RECOMMENDATION_MODAL_cofounderMatching_matches,
  CFM_RECOMMENDATION_MODAL_cofounderMatching_matches_receiver,
  CFM_RECOMMENDATION_MODAL_cofounderMatching_matches_sender,
} from "./__generated__/CFM_RECOMMENDATION_MODAL";

export const getOptionForPerson = (
  person:
    | CFM_RECOMMENDATION_MODAL_cofounderMatching_matches_receiver
    | CFM_RECOMMENDATION_MODAL_cofounderMatching_matches_sender
) => ({ name: person.user.name, avatarUrl: person.user.avatarUrl, slug: person.slug });

export const getOtherPersonFromMatch = (
  selfSlug: string,
  match: CFM_RECOMMENDATION_MODAL_cofounderMatching_matches
) => (match.receiver.user.slug === selfSlug ? match.sender : match.receiver);

export const getRecommendedSlugs = (
  data: CFM_RECOMMENDATIONS | CFM_RECOMMENDATION_MODAL | undefined
) => {
  if (!data) {
    return new Set([]);
  }

  return new Set(
    data.cofounderMatching.recommendationsGiven
      .map((rec) => rec.receiverProfile?.slug)
      .filter((slug) => !!slug)
  );
};

export const getRecommendableMatchOptions = (
  data: CFM_RECOMMENDATIONS | CFM_RECOMMENDATION_MODAL | undefined
) => {
  if (!data) {
    return [];
  }

  const selfSlug = data.currentUser!.slug;
  const recommendedSlugs = getRecommendedSlugs(data);

  return data.cofounderMatching.matches
    .map((match) => getOptionForPerson(getOtherPersonFromMatch(selfSlug, match)))
    .filter((option) => !recommendedSlugs.has(option.slug));
};
