import React from "react";
import styled from "@emotion/styled";

type Props = {
  href: string;
  onClick?: () => any;
  disabled?: boolean;
  openInNew?: boolean;
  externalUrl?: boolean;
};

type ButtonWrapperProps = {
  disabled?: boolean;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  width: 100%;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }
`;

const isSafari = () => {
  const { userAgent } = navigator;
  return /Safari/.test(userAgent) && !/Chrome/.test(userAgent) && !/CriOS/.test(userAgent);
};

const LinkButton: React.FC<Props> = ({
  href,
  onClick,
  disabled,
  children,
  openInNew,
  externalUrl,
}) => {
  const handleClick = async () => {
    if (onClick) {
      await onClick();
    }
    if (openInNew && !isSafari()) {
      window.open(href);
    } else {
      window.location.href = href;
    }
  };

  return (
    <ButtonWrapper
      disabled={disabled}
      onClick={disabled ? undefined : handleClick}
      title={`${externalUrl ? href : `${window.location.origin}${href}`}`}
    >
      {children}
    </ButtonWrapper>
  );
};

export default LinkButton;
